import React from "react";
import '../../util/config'

export default class MostraImmagineLink extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
    		link: this.props.link
    	}

	   
	  }

	render() {

	    return (
            this.props.link?(
                this.props.link.toLowerCase().endsWith("gif")
                ||this.props.link.toLowerCase().endsWith("png")
                ||this.props.link.toLowerCase().endsWith("jpg")
                ||this.props.link.toLowerCase().endsWith("jpeg")?
                (
                    <img alt={this.props.descrizione} src={this.props.link} width="200"/>
                )
                :
                (
                    this.props.link.toLowerCase().includes("mp4")?
                    (
                        <video width="320" height="240" autoplay loop>
                            <source src={this.props.link} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    )
                    :
                    (
                        <iframe title="esercizio" width="100%" height="100%" 
                            src={this.props.link+"?autoplay=1&loop=1&controls=0"}></iframe>
                    )
                )
            ):null
        )
	  }
	}