import React from 'react';
import { Card, CardContent, Typography, Button, Grid, Select, MenuItem as MuiMenuItem, FormControl, InputLabel, Switch, TextField, Box } from '@mui/material';

function MenuItem({ item, onCodeChange, onEnabledChange, onSave, onDelete, onFieldChange }) {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    onFieldChange(item.id, name, value);
  };

  const handleCodeSelect = (event) => {
    onCodeChange(item.id, event.target.value);
  };

  const handleEnabledToggle = (event) => {
    onEnabledChange(item.id, event.target.checked ? 1 : 0);
  };

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
    <CardContent>
      <Typography variant="h6">ID: {item.id}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Nome"
            name="nome"
            value={item.nome}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Code</InputLabel>
            <Select name="code" value={item.code || ''} onChange={handleCodeSelect}>
              <MuiMenuItem value="admin">admin</MuiMenuItem>
              <MuiMenuItem value="adminlaterale">adminlaterale</MuiMenuItem>
              <MuiMenuItem value="collaboratore">collaboratore</MuiMenuItem>
              <MuiMenuItem value="main">main</MuiMenuItem>
              <MuiMenuItem value="mainlaterale">mainlaterale</MuiMenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Ordine"
            type="number"
            name="ordine"
            value={item.ordine}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Faicon"
            name="faicon"
            value={item.faicon}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Viewname"
            name="viewname"
            value={item.viewname}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Font Color"
            name="fontcolor"
            value={item.fontcolor}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Icon Color"
            name="iconcolor"
            value={item.iconcolor}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Link"
            name="link"
            value={item.link}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Faicon Class"
            name="faiconclass"
            value={item.faiconclass}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center">
            <Typography>Enabled</Typography>
            <Switch
              checked={item.enabled === 1}
              onChange={handleEnabledToggle}
              color="primary"
            />
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={() => onSave(item.id)}>
          Save
        </Button>
        <Button variant="contained" color="secondary" onClick={() => onDelete(item.id)} sx={{ ml: 2 }}>
          Delete
        </Button>
      </Box>
    </CardContent>
  </Card>
);
}

export default MenuItem;
