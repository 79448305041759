import React from "react";
import '../../util/config'
import axios from 'axios';
import Swal from "sweetalert2";
import ListaScadenzaRate from "../scadenzeRate/ListaScadenzaRate";
import { connect } from "react-redux";
import { Button, TableRow, TableCell,  Typography, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from "@mui/material";
import InputText from "../../util/UI/InputText";
import Save from "@mui/icons-material/Save";
import Delete from "@mui/icons-material/Delete";

class PeriodoInput extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: this.props.selected.id,
	    		descrizione: this.props.selected.descrizione,
	    		numGiorniScadenzaPagamento: this.props.selected.numGiorniScadenzaPagamento?this.props.selected.numGiorniScadenzaPagamento:0,
	    		numeroMesi: this.props.selected.numeroMesi,
				visible: false,
				listaScadenze:this.props.selected.listaScadenze,
	    		messaggio:null
	    	}

	    this.update = this.update.bind(this);
	    this.elimina = this.elimina.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	  }

	update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }

	  handleSubmit(event) {
		event.preventDefault();
	    const { id,descrizione, numGiorniScadenzaPagamento, numeroMesi} = this.state;

	    const formData = new FormData();
	    formData.append('id', id);
		formData.append('descrizione', descrizione);
		formData.append('numGiorniScadenzaPagamento', numGiorniScadenzaPagamento);
		formData.append('numeroMesi', numeroMesi);
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
		  axios.post(global.config.server+'/aggiornaPeriodoRest/'+id,formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'bottom-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          this.props.refresh();
		          this.setState({ visible: false });
	      }).catch(err => {
			if (err.response){
			  alert(err.response.data.message);
			}
			else{
			  alert(err.response);
			}
	  });
	  }

	  elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare il periodo selezionato?')){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
		  axios.post(global.config.server+'/eliminaPeriodoRest/'+this.state.id, formData)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})
			        this.props.refresh();
			          this.setState({ visible: false });
			        }).catch(err => {
						if (err.response){
						  alert(err.response.data.message);
						}
						else{
						  alert(err.response);
						}
				  });
			}
	  }

	render() {


	    return (
	<Grid item container xs={12}>
		<Button onClick={() => this.setState({visible:true})}>{this.state.descrizione}</Button>
		<Dialog open={this.state.visible}
		onClose={()=> this.setState({visible:false})	}>
            <DialogTitle>{this.state.descrizione}</DialogTitle>
			<DialogContent>
					<input type="hidden" name="id" value={this.state.id} />
					<Typography>{this.state.messaggio}</Typography>
						<InputText type="text" label="Descrizione" onChange={this.update} name="descrizione" value={this.state.descrizione} required={true} />
						<InputText type="number" label="Mesi" onChange={this.update} name="numeroMesi" value={this.state.numeroMesi} required={true} />
							<ListaScadenzaRate periodo={this.state.id} 
							listaScadenze={this.props.selected.scadenze}
							email={this.props.email} />
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleSubmit} ><Save /></Button>
						<Button onClick={this.elimina} color="error"><Delete /></Button>
						<Button onClick={() => this.setState({visible:false})} >Close</Button>
					</DialogActions>
				</Dialog>
				</Grid>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato,
			idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	}
	
	export default connect(mapStateToProps, null)(PeriodoInput)