import { useRoutes, navigate} from "hookrouter";
import { useEffect } from "react";
import Intestazione from "./toolbar/Intestazione";
import LoadMainThings from "./util/LoadMainThings";
import { routes } from "./util/routes";
import {useSelector} from 'react-redux';
import { Grid } from "@mui/material";
import ToolbarAnnotatioAdmin from "./toolbar/Toolbar";
import BottomMenu from "./toolbar/BottomMenu";

const showDebugInfo=localStorage.getItem('debug')?true:false

function AppRoute(props) {

  const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);

    const routeResult = useRoutes(routes);

    function recuperaemail(){
      if (props.email){
        return props.email
      }
      if (emailloggato){
        return emailloggato;
      }
      if (localStorage.getItem("email")){
        return localStorage.getItem("email")
      }
    }

    useEffect(() =>{
      if(props.initialpage){
        navigate(props.initialpage);
      }
      
    })

  return (
    <>
    <Grid container sx={{paddingBottom:10}}>
      {props.showheader&&<Intestazione />}
      {props.showtoolbar&&<ToolbarAnnotatioAdmin />}
      <LoadMainThings toolbar={showDebugInfo} 
      showDebugInfo={showDebugInfo} email={recuperaemail()} 
      nome={props.nome} cognome={props.cognome} 
      telefono={props.telefono} idselezionata={props.id} idpalestraselezionata={props.idpalestraselezionata}
      t={props.t}/>
      {routeResult}
      </Grid>
      <BottomMenu />
      </>
  );
}

export default AppRoute;
