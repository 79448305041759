import axios from "axios";
import serverInfo from "../util/globals";

export async function getMenuByCode(code, idpalestra) {
    const formData = new FormData();
    formData.append('idpalestra',idpalestra);
    formData.append('code', code);
    
    const response = await axios.post(
        serverInfo.namesec+"/wp/getMenuByCode", 
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+window.$t
        },
      },
    );
    return response.data;
  }

  export async function getMenuByPalestra(idpalestra, token) {
    const formData = new FormData();
    formData.append('idpalestra',idpalestra);
    
    const response = await axios.post(
        serverInfo.namesec+"/menu/getMenuByPalestra", 
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+token
        },
      },
    );
    return response.data;
  }