import React from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import { Form, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Button, Divider, FormControl, Grid, InputAdornment, InputLabel, Select } from "@mui/material";
import InputText from "../util/UI/InputText";
import Save from "@mui/icons-material/Save";
import Delete from "@mui/icons-material/Delete";

class PagamentoEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: this.props.selected.id,
			dataPagamento: this.props.selected.dataPagamento,
			importo: this.props.selected.importo,
			tipo: this.props.selected.tipo,
			visible: false,
			idfattura: this.props.selected.idfattura,
			idricevuta: this.props.selected.idricevuta,
			totRicevute: 0,
			showModalPagamenti: false,
			messaggio: null
		}

		this.update = this.update.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.elimina = this.elimina.bind(this);
		this.togglePopup = this.togglePopup.bind(this)
	}

	componentDidMount() {
		this.setState({
			id: this.props.selected.id,
			dataPagamento: this.props.selected.dataPagamento,
			importo: this.props.selected.importo,
			tipo: this.props.selected.tipo,
			visible: false,
			idfattura: this.props.selected.idfattura,
			idricevuta: this.props.selected.idricevuta,
			totRicevute: 0,
			messaggio: null
		})
	}

	update(e) {
		this.setState({ [e.target.name]: e.target.value, visible: true });
	}

	handleSubmit(event) {
		event.preventDefault();
		const { id, dataPagamento, importo, tipo, idfattura, idricevuta } = this.state;

		const formData = new FormData();
		formData.append('datapagamento', dataPagamento);
		formData.append('importo', importo);
		formData.append('tipo', tipo);
		formData.append('id', id);
		formData.append('palestra', this.props.palestra.id);
		if (idfattura) {
			formData.append('idfattura', idfattura);
		}
		if (idricevuta) {
			formData.append('idricevuta', idricevuta);
		}
		formData.append('website', global.config.domain);
		formData.append('email', this.props.email);
		formData.append('lic', '000');
		axios.post(global.config.server + '/aggiornaPagamento', formData)
			.then((result) => {
				this.setState({ messaggio: result.data });
				const Toast = Swal.mixin({
					toast: true,
					position: 'top-end',
					showConfirmButton: false,
					timer: 3000
				});
				Toast.fire({
					type: 'success',
					title: result.data
				})
				this.setState({ visible: false });
				this.props.refresh();
			}).catch(err => {
				if (err.response) {
					alert(err.response.data.message);
				}
				else {
					alert(err)
				}
			});
	}

	elimina() {
		const { id } = this.state;
		const formData = new FormData();
		formData.append('id', id);
		formData.append('website', global.config.domain);
		formData.append('email', this.props.email);
		formData.append('palestra', this.props.palestra.id);
		formData.append('lic', '000');

		if (window.confirm('Sei sicuro di voler cancellare il pagamento selezionato?')) {
			axios.post(global.config.server + '/eliminaPagamento', formData)
				.then((result) => {
					const Toast = Swal.mixin({
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 3000
					});
					Toast.fire({
						type: 'success',
						title: result.data
					})
					this.setState({ visible: false });
					this.props.refresh();
				});
		}
	}

	togglePopup() {
		this.setState({ showModalPagamenti: !this.state.showModalPagamenti })
	}

	render() {
		return (
			<Grid item container xs={12}>
				<Grid item container xs={6} md={3}>
					<InputText label="Data"
						placeholder="Data pagamento"
						value={this.state.dataPagamento}
						name="dataPagamento"
						type="date"
						onChange={this.update}
					/>
				</Grid>
				<Grid item container xs={6} md={3}>
					<InputText label="Importo"
						placeholder="Importo"
						value={this.state.importo}
						name="importo"
						type="number"
						onChange={this.update}
						InputProps={{
							startAdornment: <InputAdornment position="start">&euro;</InputAdornment>,
								}}
					/>
				</Grid>
				<Grid item container xs={6} md={3}>
				<FormControl required fullWidth variant="standard">
					<InputLabel>Tipo</InputLabel>
					<Select
						placeholder="Tipologia pagamento"
						value={this.state.tipo}
						name="tipo"

						onChange={this.update}
					>
						<option value="contanti">Contanti</option>
						<option value="carta">Bancomat/Carta</option>
						<option value="bonifico">Bonifico</option>
						<option value="assegno">Assegno</option>
						<option value="paypal">Paypal</option>
						<option value="alma">Alma</option>
					</Select>
				</FormControl>
				</Grid>
				<Grid item container xs={6} md={3}>
				<Button onClick={this.handleSubmit}>
					<Save />
				</Button>

				<Button onClick={this.elimina} color="error">
					<Delete />
				</Button>
				{this.props.selected.idricevuta ?
								<label className="control-label">Ricevuta
									{this.props.selected.idricevuta} </label>
								: null
							}
				</Grid>
				<Divider />
			</Grid>
		);
	}
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
	}
}

export default connect(mapStateToProps, null)(PagamentoEdit)