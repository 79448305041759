import React from "react";
import axios from 'axios';
import ModificaFattura from './ModificaFattura'
import NuovoPagamento from '../pagamenti/NuovoPagamento'
import PagamentiSelect from '../pagamenti/PagamentiSelect'
import Swal from "sweetalert2";
import serverInfo from "../util/globals";
import { connect } from "react-redux";
import { Button, Divider, Grid, IconButton, Menu, MenuItem, Paper } from "@mui/material";
import InputText from "../util/UI/InputText";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Save from "@mui/icons-material/Save";

class FatturaEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: this.props.selected.id,
			anno: this.props.selected.anno,
			data: this.props.selected.data,
			importo: this.props.selected.importolordototale,
			numero: this.props.selected.numero,
			email: this.props.selected.email,
			iva: this.props.selected.iva,
			idutentedestinatario: this.props.selected.idutentedestinatario,
			idpalestradestinaria: this.props.selected.idpalestradestinataria,
			visible: false,
			idpalestra: this.props.selected.idpalestra,
			pagamenti: [],
			messaggio: null,
			showModalPagamenti: false,
			reversecharge: this.props.selected.reversecharge,
			open: false
		}

		this.update = this.update.bind(this);
		this.elimina = this.elimina.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.sendEmailFattura = this.sendEmailFattura.bind(this);
		this.nuovoPagamento = this.nuovoPagamento.bind(this);
		this.stampaFattura = this.stampaFattura.bind(this);
	}

	componentDidMount() {
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email', this.props.email);
		formData.append('lic', '000');
		formData.append('palestra', this.props.palestra.id);
		axios.post(global.config.server + "/getPagamentiFatt/" + this.props.selected.id, formData)
			.then(response => {
				this.setState({ pagamenti: response.data });
				//console.log(response);
			});
	}

	update(e) {
		this.setState({ [e.target.name]: e.target.value, visible: true });
	}

	handleSubmit(event) {
		event.preventDefault();
		const { id, idpagamento } = this.state;

		const formData = new FormData();
		formData.append('idpagamento', idpagamento);
		formData.append('website', global.config.domain);
		formData.append('email', this.props.email);
		formData.append('lic', '000');
		formData.append('palestra', this.props.palestra.id);
		axios.post(global.config.server + '/aggiornaPagamentoFatturaRest/' + id, formData)
			.then((result) => {
				this.setState({ messaggio: result.data });
				const Toast = Swal.mixin({
					toast: true,
					position: 'top-end',
					showConfirmButton: false,
					timer: 3000
				});
				Toast.fire({
					type: 'success',
					title: result.data
				})
				this.setState({ visible: false });
			});
	}

	stampaFattura(event) {

		var win = window.open(serverInfo.name + '/stampaFattura?id=' + this.state.id, '_blank');
		win.focus();
	}


	sendEmailFattura(event) {
		event.preventDefault();
		const { id } = this.state;

		const formData = new FormData();
		formData.append('id', id);
		formData.append('website', global.config.domain);
		formData.append('email', this.props.email);
		formData.append('lic', '000');
		formData.append('palestra', this.props.palestra.id);
		axios.post(global.config.server + '/inviaEmailPdfFatturaRest', formData)
			.then((result) => {
				console.log(result)
				this.setState({ messaggio: result.data });
				const Toast = Swal.mixin({
					toast: true,
					position: 'top-end',
					showConfirmButton: false,
					timer: 3000
				});
				Toast.fire({
					type: result.data.message ? 'success' : 'error',
					title: result.data.message ? result.data.message : result.data.errore
				})
				this.setState({ visible: false });
			}).
			catch((err) => {
				alert("Fattura: " + err.response.data.message);
				this.setState({ visible: false });
			});
	}

	nuovoPagamento() {
		this.setState({ showModalPagamenti: !this.state.showModalPagamenti })
	}

	elimina() {
		if (window.confirm('Sei sicuro di voler cancellare la fattura selezionata?')) {
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email', this.props.email);
			formData.append('lic', '000');
			formData.append('palestra', this.props.palestra.id);
			axios.post(global.config.server + '/eliminaFatturaRest/' + this.state.id, formData)
				.then((result) => {
					const Toast = Swal.mixin({
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 3000
					});
					Toast.fire({
						type: 'success',
						title: result.data
					})
					this.setState({ visible: false });
				});
		}
	}

	render() {
		var style1 = {
			color: "white"
		}

		return (
			<Grid item container xs={12} margin={3}>
				<Paper elevation={3} sx={{width:'100%', margin:2}} >
				<ModificaFattura possibiliIntestatari={this.props.possibiliIntestatari} fattura={this.state} email={this.props.email} />
				{this.state.showModalPagamenti && this.state.showModalPagamenti === true ?
					<NuovoPagamento id={this.state.id} fattura={this.state} idabbonamento={this.props.idabbonamento}
						idprenotazione={this.props.idprenotazione} importo={this.state.importo}
					/>
					: null}
				<Grid item container xs={12}>
				<Grid item xs={1} md={1} sx={{width:'1%', padding:0, margin:0}}>
				<IconButton
					aria-controls={this.state.open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={this.state.open ? 'true' : undefined}
					onClick={(event) => { this.setState({ anchorEl: event.currentTarget }); this.setState({ open: !this.state.open }) }}
				>
					<MoreVertIcon />
					
					<Menu
						id="basic-menu"
						open={this.state.open}
						anchorEl={this.state.anchorEl}
						onClose={() => this.setState({ open: false })}>
						<MenuItem key="mail" onClick={() => { this.sendEmailFattura(); this.setState({ open: !this.state.open }) }} >Invia per email</MenuItem>
						<MenuItem key="Stampa" onClick={() => { this.stampaFattura(); this.setState({ open: !this.state.open }) }}>Stampa</MenuItem>
						<MenuItem key="elimina" onClick={() => { this.elimina(); this.setState({ open: !this.state.open }) }}>Elimina</MenuItem>
					</Menu>
					</IconButton>
					</Grid>
					<Grid item  xs={4} md={1}>
						<InputText label="Data" value={this.state.data} disabled={true} />
					</Grid>
					<Grid item  xs={4} md={1}>
						<InputText label="Anno" value={this.state.anno} disabled={true} />
					</Grid>

					<Grid item  xs={4} md={1}>
						<InputText label="Numero" value={this.state.numero} disabled={true} />
					</Grid>
					<Grid item  xs={4} md={2}>
						<InputText label="Lordo" value={this.state.importo} disabled={true} />
					</Grid>
					<Grid item  xs={4} md={2}>
						<InputText label="Iva" value={(this.state.importo - this.props.selected.importonettototale).toFixed(2)} disabled={true} />
					</Grid>
					<Grid item  xs={4} md={2}>
						<InputText label="Netto" value={this.props.selected.importonettototale} disabled={true} />
					</Grid>
					
				</Grid>
				<Grid item container xs={12}>
				
						<Grid item container xs={12} md={12}>
						<h5>Pagamenti</h5>
						</Grid>
						<Grid item container xs={12} md={3}>
							{this.state.pagamenti && this.state.pagamenti.length > 0 ?
								<span>
									Pagamenti
									<ul>
										{
											this.state.pagamenti.map(pagamento => (
												<li>{pagamento.dataPagamento}, {pagamento.importo} - {pagamento.tipo}</li>
											))
										}
									</ul>
								</span>
								: "nessun pagamento per questa fattura"}
						</Grid>
						{/* <Grid item container xs={6} md={3}>

							<PagamentiSelect idabbonamento={this.props.idabbonamento} update={this.update} email={this.props.email} palestra={this.props.selected.idpalestra} />
							<Button onClick={this.handleSubmit}>
								<Save />
							</Button>
						</Grid> */}
						
					</Grid>
				
					</Paper>
			</Grid>
		);
	}
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
	}
}

export default connect(mapStateToProps, null)(FatturaEdit)