import React from "react";
import axios from 'axios';
import '../util/config'
import { connect } from "react-redux";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";

class AccessiGiornalieri extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
                accessi:null
        };
        this.update=this.update.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
      }

      componentDidMount(){
        const formData = new FormData();
		formData.append('website', global.config.domain);
    formData.append('email',this.props.email);
    formData.append('lic','000');
    formData.append('palestra',this.props.palestra.id);
    axios.post(global.config.server+"/visualizzaAccessiOdierni",formData)
		  .then(response=>{
			console.log("Accessi aodierni: ")
			  console.log(response.data)
			  this.setState({accessi: response.data});
		  })
		  .catch(err => {
              if (err.response){
                alert("accessi: "+err.response.data.message);
              }
              else{
                alert("accessi: "+err.response);
              }
		});
      }

    update(e) {
        this.setState({ [e.target.name]: e.target.value, visible: true });
    }

    handleSubmit() {
       
        const formData = new FormData();
		formData.append('website', global.config.domain);
    formData.append('email',this.props.email);
    formData.append('lic','000');
    formData.append('palestra',this.props.palestra.id);
        formData.append('data',this.state.data);
      
        axios.post(global.config.server+"/visualizzaAccessiOdierni",formData)
        .then(response=>{
          console.log("Accessi aodierni: ")
            console.log(response.data)
            this.setState({accessi: response.data});
        })
        .catch(err => {
            if (err.response){
              alert("accessi: "+err.response.data.message);
            }
            else{
              alert("accessi: "+err.response);
            }
      });
  }

render() {
    return (
        <Grid item container>
          <Grid item container>
                <TextField type="date" label="Data" name="data" onChange={this.update} />
                <Button variant="contained" onClick={this.handleSubmit}>Cerca accessi</Button>
            </Grid>
            <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Codice</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Descrizione</TableCell>
                <TableCell>Utente</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    this.state.accessi&&
                        this.state.accessi.map(accesso=>(
                            <TableRow hover key={accesso.id}>
                                <TableCell>{accesso.code}</TableCell>
                                <TableCell>{accesso.data}</TableCell>
                                <TableCell>{accesso.descrizioneAccesso}</TableCell>
                                <TableCell>{accesso.nomeCognome}</TableCell>
                            </TableRow>
                        ))
                   
                }
            </TableBody>
        </Table>
        </TableContainer>
        {!this.state.accessi&&<div>Nessun accesso trovato</div>}
        </Grid>
);
  }
}

const mapStateToProps = state => {
  return {
    palestra: state.gestoreUtente.palestraassociata.palestra,
    email: state.gestoreUtente.emailloggato,
    idanagselezionata: state.gestoreUtente.idanagselezionata
  }
}

export default connect(mapStateToProps, null)(AccessiGiornalieri)