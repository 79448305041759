export function controllodatiobbl (telefono, anagraficapa,coddestinatario,piva,codicefiscale, nazione) {
    if (telefono&&(telefono.length<5||telefono.length>12)){
        alert("telefono non valido: deve essere tra 5 e 12 caratteri")
        return false
    }
    if (piva&&(piva!==''
        &&piva.length!==11&&nazione==='IT')
    ){
        alert("Partita IVA non valida. Deve essere di 11 caratteri")
        return false
    }
    if (codicefiscale!==''){
        if(!piva||piva===""){
            if (codicefiscale.length!==16)
            {
                alert("Codice fiscale non valido. Deve essere di 16 caratteri")
                return false
            }
        }
        else if (codicefiscale.length!==16&&codicefiscale!==piva)
            {
                alert("Codice fiscale non valido ("+codicefiscale+"). Deve essere di 16 caratteri oppure deve essere uguale alla partita IVA ("+piva+")")
                return false
            }
    }

    if (anagraficapa&&anagraficapa===1){
        if (coddestinatario===''
            ||coddestinatario.length!==6){
                alert("Codice destinatario non valdo. deve essere di 6 caratteri")
                return false
            }
        if(!piva||piva===''){
            alert("Partiva IVA obbligatoria per le PA");
            return false;
        }
    }
    if(anagraficapa===0){
        if (coddestinatario!==''
            &&coddestinatario.length!==7){
                alert("Codice destinatario non valdo. deve essere di 7 caratteri")
                return false
        }
        if (coddestinatario===''){
            coddestinatario="000000"
        }
    }
    return true;
}