import React from "react";
import { useSelector } from "react-redux";

export default function Intestazione(){ 
  const palestrasocio = useSelector(state => state.gestoreUtente.palestraassociata);

    return (
        <span>
            <a href="/">
        {palestrasocio&& palestrasocio.palestra&&palestrasocio.palestra.id?
        <img src={palestrasocio.palestra.logo} 
        title={palestrasocio.palestra.palestra} alt={palestrasocio.palestra.palestra} style={{height:'4em', float:'left', marginRight:'15px'}}
         />
         :
         <img src={"https://annotatio.online/wp-content/uploads/2022/02/logoannotatio.png"} 
        title={"Annotatio.online"} alt={"Annotatio.online"} style={{height:'4em', float:'left', marginRight:'15px'}}
         />}
         </a>
              {/* {palestrasocio&&palestrasocio.palestra&&palestrasocio.palestra.id&&
               <span>
                <h3>{palestrasocio.palestra.palestra}</h3>
              </span>} */}
        </span>
    );
}