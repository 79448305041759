import React from "react";
import axios from 'axios';
import { connect } from "react-redux";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

class NuovoMovimentoCassa extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
       
        };
        this.update = this.update.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        console.log("NuovoMovimentoCassa")
	  }

  
    componentDidCatch(error, info) {
          console.log(error, info);
        }
  
    update(e) {
        this.setState({ [e.target.name]: e.target.value, visible: true });
    }

      handleSubmit(e){
          e.preventDefault();
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
        formData.append('data',this.state.dataOperazione);
        formData.append('descrizione',this.state.descrizione);
        formData.append('entrate',this.state.entrate?this.state.entrate:0);
        formData.append('uscite',this.state.uscite?this.state.uscite:0);
        axios.post(global.config.server+'/salvaCassa', formData)
        .then((result) => {
			console.log(result.data)
            if (result.data
                &&!isNaN(result.data)){
                    localStorage.setItem('idcassa',result.data)
                    if (this.props.link){
                        window.location.href='/'+this.props.link
                    }
                    else{
                        alert('Movimento inserito con successo')
                    }
                }
                else{
        	        alert(result.data)
                }
        });
	  }

    render() {
      return (
        <Box p={2}>
            <Typography variant="h4" component="h1" gutterBottom>
          Nuovo movimento
        </Typography>
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                type="date"
                label="Data operazione"
                name="dataOperazione"
                value={this.state.dataOperazione}
                onChange={this.update}
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                label="Descrizione"
                name="descrizione"
                value={this.state.descrizione}
                onChange={this.update}
                fullWidth
                required
                placeholder="Descrizione del movimento"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                type="number"
                label="Entrate"
                name="entrate"
                value={this.state.entrate}
                onChange={this.update}
                fullWidth
                placeholder="Entrate"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                type="number"
                label="Uscite"
                name="uscite"
                value={this.state.uscite}
                onChange={this.update}
                fullWidth
                placeholder="Uscite"
              />
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Button type="submit" variant="contained" color="primary">
                  Salva
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
        );
    }
  }

  const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraassociata.palestra,
        email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
    }
}

export default connect(mapStateToProps, null)(NuovoMovimentoCassa)