import React from "react";
import '../../util/config'
import axios from 'axios';
import Swal from "sweetalert2";
import TipologieAbbonamentoSelect from "../tipologiaAbbonamento/TipologieAbbonamentoSelect";
import PeriodoSelect from "../periodi/PeriodoSelect";
import { connect } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import InputText from "../../util/UI/InputText";
import Save from "@mui/icons-material/Save";
import Delete from "@mui/icons-material/Delete";

class ListinoInput extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: this.props.selected.id,
	    		pagaIscrizione: this.props.selected.pagaIscrizione,
	    		prezzo: this.props.selected.prezzo,
	    		periodo: this.props.selected.periodo,
	    		tipoAbbonamento: this.props.selected.tipoAbbonamento,
				pubblico: this.props.selected.pubblico,
				visible: false,
				listaTipologie:this.props.listaTipologie,
	    		messaggio:null
	    	}

	    this.update = this.update.bind(this);
	    this.elimina = this.elimina.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.updateTipo = this.updateTipo.bind(this);
	    this.updatePeriodo = this.updatePeriodo.bind(this);
	  }

	update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }

	updateTipo(tipoSelezionato) {
		var tipoAbbonamento = {...this.state.tipoAbbonamento}
		tipoAbbonamento = tipoSelezionato;
		this.setState({tipoAbbonamento, visible: true})
	  }
	
	updatePeriodo(periodoSelezionato) {
		var periodo = {...this.state.periodo}
		periodo.id = periodoSelezionato;
		this.setState({periodo, visible: true})
	  }

	  handleSubmit(event) {
		event.preventDefault();
	    const { id,pagaIscrizione, prezzo, periodo, tipoAbbonamento, pubblico} = this.state;
	    const formData = new FormData();
	    formData.append('id', id);
		formData.append('pagaIscrizione', pagaIscrizione);
		formData.append('prezzo', prezzo);
		formData.append('periodo', periodo.id);
		formData.append('tipoAbbonamento', tipoAbbonamento.id);
		formData.append('website', global.config.domain);
		formData.append('pubblico', pubblico);
        formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
		  axios.post(global.config.server+'/aggiornaListinoRest/'+id,formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          this.props.refresh();
		          this.setState({ visible: false });
	      }).catch(err => {
			console.log(err)
			if (err.response){
			  alert(err.response.data.message);
			}
		  });
	  }

	  elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare il listino selezionato?')){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
		  axios.post(global.config.server+'/eliminaListinoRest/'+this.state.id, formData)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})
			        this.props.refresh();
			          this.setState({ visible: false });
			        }).catch(err => {
						if (err.response){
						  alert(err.response.data.message);
						}
						else{
						  alert(err.response);
						}
					  });
			}
	  }

	render() {
		var style1={
				color:"white"
		}

	    return (
			<Grid item container xs={12}>
				<Button onClick={() => this.setState({visible:true})}>
					{this.state.tipoAbbonamento.descrizione} - {this.state.periodo.descrizione} - {this.state.prezzo}
				</Button>
			<Dialog open={this.state.visible} onClose={() => this.setState({visible:false})}>
				<DialogTitle>Listino</DialogTitle>
				<DialogContent>
					<span>{this.state.messaggio}</span>
					<div className="col">
						<label className="control-label">SKU &nbsp; </label>
						{this.state.id}
					</div>
					<Grid item container xs={12}>
						<TipologieAbbonamentoSelect listaTipologie={this.props.listaTipologie} 
						selected={this.state.tipoAbbonamento.id}
						updateTipo={this.updateTipo} 
						email={this.props.email} />
					</Grid>
					<Grid item container xs={12}>
						<PeriodoSelect listaPeriodi={this.props.listaPeriodi} 
						selected={this.state.periodo?this.state.periodo.id:null} 
						updatePeriodo={this.updatePeriodo} 
						email={this.props.email} />
					</Grid>

					<Grid item container xs={12}>
						<Grid item container xs={4}>
							<InputText label="Prezzo" type="number" onChange={this.update} name="prezzo" step="0.5" value={this.state.prezzo} />
						</Grid>
						<Grid item container xs={4}>
						<FormControl fullWidth={true} variant="standard">
							<InputLabel>Pag.Iscrizione</InputLabel>
							<Select variant="standard" name="pagaIscrizione" value={this.state.pagaIscrizione&&(this.state.pagaIscrizione===1||this.state.pagaIscrizione=="1")?"1":"0"} onChange={this.update}>
								<MenuItem value="1">Si</MenuItem>
								<MenuItem value="0">No</MenuItem>
							</Select>
						</FormControl>
						</Grid>
						<Grid item container xs={4}>
							<FormControl fullWidth={true} variant="standard">
							<InputLabel>Pubblico</InputLabel>
							<Select variant="standard" name="pubblico" value={this.state.pubblico&&(this.state.pubblico===1||this.state.pubblico==="1"||this.state.pubblico===true)?"1":"0"} onChange={this.update}>
								<MenuItem value="1">Si</MenuItem>
								<MenuItem value="0">No</MenuItem>
							</Select>
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleSubmit} ><Save /></Button>
					<Button onClick={this.elimina} color="error"><Delete /></Button>
					<Button onClick={() => this.setState({visible:false})} >Close</Button>
				</DialogActions>
			</Dialog>
			</Grid>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato,
			idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	}
	
	export default connect(mapStateToProps, null)(ListinoInput)