import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import {  Alert, Button } from 'react-bootstrap';
import { getMessaggiOrdine, inviaMessaggioOrdine } from "../DAO/messaggiDAO";
import { aggiornastatusordine } from "../DAO/ordiniDAO";
import { useSelector } from "react-redux";

export default function Ordine({ordine, refresh}) {
    const [messaggio, setmessaggio] =useState('');
    const [messaggi, setmessaggi] = useState([]);
    const [status, setstatus] = useState(ordine.status);
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
  const email = useSelector(state => state.gestoreUtente.emailloggato);

    function getMsgOrdine(){
        async function getMessaggi(){
            const result=await getMessaggiOrdine(email, palestra.palestra.id, ordine.id)
            setmessaggi(result);
        }
        getMessaggi();
    }

    useEffect(() => {
        getMsgOrdine();
    }, [])

    function inviaMessaggio(){
        async function inviamsg(){
            inviaMessaggioOrdine( email, palestra.palestra.id, ordine.anagrafica.id, ordine.id,messaggio)
        }
        inviamsg();
      }

      function handleSubmit(nuovostatus){
        async function aggiornaordine(){
            try{
                const result = await aggiornastatusordine(email,palestra.palestra.id,ordine.id, nuovostatus)
                console.log(result)
                refresh()
            }
            catch(error){
                console.log(error);
                if (error && error.response && error.response.data){
                  alert('Errore',error.response.data.message)
                }
                else{
                  console.log(error.message);
                }
              }

        }
        aggiornaordine();
    }

        return (
            ordine.id?
        <div className="card-text" id={ordine.id}>
            <div className="row">
                <div className="col-md-6">
                    <div>{ordine.id}, Stato:  {status}
                    <select name="status" value={status} onChange={(e) => {setstatus(e.target.value); handleSubmit(e.target.value)}}>
                        <option value="--">--</option>
                        <option selected={status==='A_NUOVO'} value="A_NUOVO">NUOVO</option>
                        <option selected={status==='B_IN_LAVORAZIONE'} value="B_IN_LAVORAZIONE">IN LAVORAZIONE</option>
                        <option selected={status==='D_PRONTO'} value="D_PRONTO">PRONTO</option>
                        <option selected={status==='X_ANNULLATO'} value="X_ANNULLATO">ANNULLATO</option>
                        <option selected={status==='Z_CONSEGNATO'} value="Z_CONSEGNATO">CONSEGNATO</option>
                    </select>
                    </div>
                    <div>
                    Ora ritiro <strong>{ordine.oraconsegna}</strong>
                    </div>
                    Data e ora di prenotazione: {ordine&&ordine.prenotazioni&&Array.isArray(ordine.prenotazioni)&&ordine.prenotazioni&&ordine.prenotazioni[0]?ordine.prenotazioni[0].dataOraPrenotazione:''}
                    {
                        ordine.prenotazioni&&Array.isArray(ordine.prenotazioni)&&ordine.prenotazioni.length>0?
                        
                        ordine.prenotazioni.map(prenotazione=>(
                            <span key={prenotazione.id}>
                            <p>
                                Quantita: {prenotazione.quantita} - {prenotazione.corso}
                            </p>
                            {
                                prenotazione.ingredienti && JSON.parse(prenotazione.ingredienti).length>0?
                                JSON.parse(prenotazione.ingredienti).map((ingtemp, index) => (
                                    <p key={index}> - {ingtemp.quantita} {ingtemp.categoria} {ingtemp.nome}</p>
                                ))
                                
                                :null
                            }
                            </span>
                        )

                        )
                        :
                        <div>Nessuna prenotazione trovata per questo ordine</div>
                    }
                    <div>Totale {ordine.totaleEuro}</div>
                    <div>Pagato {ordine.totalePagato}</div>
                    {ordine.sconto&&ordine.sconto>0&&<div>Sconto {ordine.descrSconto}</div>}
                    <div>Da Pagare {ordine.totaleEuro-ordine.totalePagato}</div>
                </div>
                <div className="col-md-6">
                {ordine&&ordine.anagrafica && 
                <>
                <div>{ordine.anagrafica.id} {ordine.anagrafica.nome} {ordine.anagrafica.cognome} </div>
                <div>{ordine.anagrafica.email} {ordine.anagrafica.telefono}</div>
                </>
                }
                
                <div>Consegna a domicilio: {ordine.consegnaDomicilio} -  {ordine.consegnaDomicilio?"SI":"NO"}</div>
                {ordine.consegnaDomicilio?
                    <div>
                        <div>DA CONSEGNARE A </div>
                        {ordine.anagrafica&&
                        <>
                        <div>{ordine.anagrafica.nome} {ordine.anagrafica.cognome} </div>
                        <div>{ordine.anagrafica.indirizzo}</div>
                        <div> {ordine.anagrafica.citta}</div>
                        </>
                        }
                    </div>
                :
                    <div>Take away</div>
                }
                <div>Note: <strong>{ordine.note}</strong></div>
                </div>
            </div>
            <div>
                <label>Invia un messaggio al cliente</label>
                <textarea name="messaggio" onChange={setmessaggio}>
                    {ordine.messaggio}
                </textarea>
                <Button onClick={inviaMessaggio}>Invia messaggio</Button>
            </div>
            <div>
                {ordine.messaggi?
                ordine.messaggi.map(messaggio=>(

                    <p style={{textAlign:messaggio.mittente?'left':'right'}}>{messaggio.messaggio}
                    <br />
                    <span style={{fontSize:'xx-small'}}>{messaggio.data}</span>
                    </p>
                ))
                
                :null}
            </div>
            <hr style={{borderTop: '8px solid #bbb', borderRadius: '5px', marginBottom:'25px'}} />
        </div>
        :"NIENTE"
    );
}