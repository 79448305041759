import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export default function SelectText({label, placeholder,name, value, onChange, lista, required}){

    return (
        <FormControl fullWidth={true} variant="standard">
             <InputLabel>{label}</InputLabel>
            <Select 
            fullWidth={true}
            required={required?required:false}
            size="small"
            name={name} value={value} onChange={onChange}>
                    {placeholder&&<MenuItem key={placeholder} value={""}>{placeholder}</MenuItem>}
                    {lista&&Array.isArray(lista)&&lista.length>0&&
                    lista.map(datatemp => (
                        <MenuItem key={datatemp.value} value={datatemp.value}>{datatemp.name}</MenuItem>
                    ))}
                </Select>
        </FormControl>
    );
}