import React from "react";
import axios from 'axios';

import '../util/config'
import { connect } from "react-redux";
import { Alert, Button, Divider, Grid, Snackbar } from "@mui/material";
import InputText from "../util/UI/InputText";
import Save from "@mui/icons-material/Save";
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';
import Delete from "@mui/icons-material/Delete";

class RicevutaEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: this.props.selected.id,
			anno: this.props.selected.anno,
			data: this.props.selected.data,
			importo: this.props.selected.importo,
			numero: this.props.selected.numero,
			idpagamento: this.props.selected.idpagamento,
			idabbonamento: this.props.selected.idabbonamento,
			email: this.props.selected.email,
			
			snackvisible:false,
			snackmessaggio:'',
			snacktype:''
		}

		this.update = this.update.bind(this);
		this.elimina = this.elimina.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.sendEmailRicevuta = this.sendEmailRicevuta.bind(this);
		this.openRicevuta = this.openRicevuta.bind(this);
	}

	update(e) {
		this.setState({ [e.target.name]: e.target.value, visible: true });
	}

	handleSubmit(event) {
		event.preventDefault();
		const { id, anno, data, importo, numero, idpagamento, idabbonamento } = this.state;

		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email', this.props.email);
		formData.append('lic', '000');
		formData.append('palestra', this.props.palestra.id);
		formData.append('id', id);
		formData.append('anno', anno);
		formData.append('data', data);
		formData.append('importo', importo);
		formData.append('numero', numero);
		formData.append('idpagamento', idpagamento ? idpagamento : '');
		formData.append('idabbonamento', idabbonamento ? idabbonamento : '');

		axios.post(global.config.server + '/aggiornaRicevutaRest', formData)
			.then((result) => {
				this.setState({ snackvisible: true, snackmessaggio:result.data ? result.data : result.data.errore, snacktype: result.data ? 'success' : 'error'});
				
			});
	}

	openRicevuta(){
        var win = window.open(global.config.server+'/stampaRicevuta?id='+this.state.id, '_blank');
		win.focus();
    }

	sendEmailRicevuta(event) {
		event.preventDefault();
		const { id } = this.state;

		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email', this.props.email);
		formData.append('lic', '000');
		formData.append('id', id);
		formData.append('idanagrafica', this.props.idanagselezionata);
		formData.append('palestra', this.props.palestra.id);

		axios.post(global.config.server + '/inviaEmailPdfRicevutaRest', formData)
			.then((result) => {
				console.log(result)
				this.setState({ snackvisible: true, snackmessaggio:result.data.message ? result.data.message : result.data.errore, snacktype: result.data.message ? 'success' : 'error'});
				
			})
			.catch(err => {
				alert("Modifica ricevuta: " + err.response.data.message);
			});
	}

	elimina() {
		if (window.confirm('Sei sicuro di voler cancellare la ricevuta selezionata?')) {
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email', this.props.email);
			formData.append('lic', '000');
			formData.append('palestra', this.props.palestra.id);
			formData.append('id', this.state.id);


			axios.post(global.config.server + '/eliminaRicevutaRest', formData)
				.then((result) => {
					this.setState({ snackvisible: true, snackmessaggio:result.data.message ? result.data.message : result.data.errore, snacktype: result.data.message ? 'success' : 'error'});

					this.setState({ visible: false });
					this.props.refresh();
				}).catch(err => {
					alert("Elimina Ricevute: " + err.response.data.message);
				});
		}
	}

	render() {
		return (
			<Grid item container columns={24} sx={{display:'flex'}}>
				<Snackbar
				anchorOrigin={{ vertical: 'bottom',
				horizontal: 'center',}}
					color={this.state.snacktype}
					open={this.state.snackvisible}
					autoHideDuration={6000}
					onClose={() => this.setState({ snackvisible: false, snackmessaggio: "" })}
					>
					<Alert
						onClose={() => this.setState({ snackvisible: false, snackmessaggio: "" })}
						severity={this.state.snacktype}
						variant="filled"
						sx={{ width: '100%' }}
					>
						{this.state.snackmessaggio}
					</Alert>
				</Snackbar>
				<Grid item container xs={2} md={2}>
					<InputText label="Anno" type="numer" maxLength="4" readOnly={true} value={this.state.anno} />
				</Grid>
				<Grid item container xs={4}>

					<InputText label="Data" type="date" maxLength="50" name="data" value={this.state.data} onChange={this.update} />
				</Grid>
				<Grid item container xs={3}>

					<InputText label="Importo" type="number" name="importo" value={this.state.importo} onChange={this.update} />
				</Grid>
				<Grid item container xs={3}>

					<InputText label="Numero" type="text" name="numero" value={this.state.numero} onChange={this.update} />
				</Grid>
				<Grid item container xs={2}>
					<Button onClick={this.handleSubmit}>
						<Save />
					</Button>
				</Grid>
				<Grid item container xs={2}>
					<Button onClick={this.sendEmailRicevuta}>
						< EmailIcon />
					</Button>
				</Grid>
				<Grid item container xs={2}>
					<Button onClick={this.openRicevuta}><DownloadIcon /></Button>
				</Grid>
				<Grid item container xs={2}>
					<Button color="error" onClick={this.elimina}><Delete /></Button>
				</Grid>
				<Divider />
			</Grid>
		);
	}
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
	}
}

export default connect(mapStateToProps, null)(RicevutaEdit)