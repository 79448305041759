import React from "react";
import axios from 'axios';
import '../util/config'
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import serverInfo from "../util/globals";

export default function TipoAbbonamentoSelect(props) {
	const palestraanagrafica = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
	const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [tipi, settipi] = useState(props.tipi);
	 
	useEffect(() =>{
		if (!props.tipi){
			const formData = new FormData();
			formData.append('email',email);
			formData.append('website', palestraanagrafica.website);
			formData.append('palestra',palestraanagrafica.id);
			formData.append('lic','000');
			axios.defaults.withCredentials = true;
			axios.post(serverInfo.namesec+"/arch/getTipiAbbonamento",formData,
			{
				withCredentials: true,
				credentials:'',
				headers: {
				  Accept: 'application/json',
				  'Content-Type': 'multipart/form-data',
				  'Access-Control-Allow-Origin': '*',
				  'Access-Control-Allow-Methods': 'POST',
				  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				  'Access-Control-Allow-Credentials': 'true',
				  'Authorization' :'Bearer '+window.$t
				},
			  })
			.then(response=>{
				console.log("tipologie: ")
				console.log(response.data)
				settipi(response.data)
			})
			.catch(err => {
				alert("tipi abbonamento: "+(err&&err.response?err.response.data.message:err));
			});
	  }
	},[palestraanagrafica, email]
	);

	    return (
			<select className="form-control" onChange={props.update} name="tipoabbonamento" value={props.selected?props.selected:""}>
				<option key={-1} value="">-- nessun tipo --</option>
				{
					tipi&&Array.isArray(tipi)&&tipi.length>0?
					tipi.map((item,index) =>(
					<option key={index} value={item.id} >{item.descrizione}</option>
					)
					)
					:null
	    		}
			</select>
	    );
	}