import React from "react";
import {useSelector} from 'react-redux'
import { useEffect } from "react";
import { useState } from "react";

export default function AnnotatioModules(props){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime());

    useEffect(() => {
setkeytorefresh(new Date().getTime())
    },[palestra, email])

    return(
        <div key={keytorefresh} className="container-fluid">
            {palestra&&
            <div className="row">

            
            </div>}
        </div>
    )
}