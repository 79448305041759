import React, { useState, useEffect } from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import CorsiSelect from "../archivi/corsi/CorsiSelect";
import UtentiFilterSelect from "../anagrafica/UtentiFilterSelect";
import { useDispatch, useSelector } from 'react-redux'
import serverInfo from "../util/globals";
import { navigate } from 'hookrouter'
import SelectTipologiaCorso from "../archivi/tipologiaCorso/SelectTipologiaCorso";
import { Button, Container, Dialog, DialogActions, DialogContent, Divider, FormControl, FormControlLabel, Grid, InputAdornment, 
	InputLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField, Checkbox } from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimerIcon from '@mui/icons-material/Timer';
import Save from "@mui/icons-material/Save";
import Delete from '@mui/icons-material/Delete';
import { getcorsi } from "../DAO/corsiDAO";
import { settipiprodotto } from "../STORE/reducers/gestoreTipiProdotto";
import { useMediaQuery, useTheme } from '@mui/material';

export default function DettaglioSchedulazione() {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
	
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
	const email = useSelector(state => state.gestoreUtente.emailloggato);
	const calendario = useSelector(state => state.gestoreSchedulazione.calendario);
	const schedulazione = useSelector(state => state.gestoreSchedulazione.schedulazione);

	const [messaggio, setmessaggio] = useState('')
	const [id, setid] = useState(schedulazione.id)
	const [anagrafica, setanagrafica] = useState()
	const [nomeLibero, setnomeLibero] = useState()
	const [fisso, setfisso] = useState()
	const [prenotazioni, setprenotazioni] = useState([])
	const [showInsPreno, setshowInsPreno] = useState(false)

	const [durata, setdurata] = useState(schedulazione.durata)
	const [numMaxPrenotazioni, setnumMaxPrenotazioni] = useState(schedulazione.nummaxprenotazioni)
	const [corso, setcorso] = useState(schedulazione.corso)
	const [data, setdata] = useState(schedulazione.data)
	const [ora, setora] = useState(schedulazione.ora)

	const [tipoPrenotazione, settipoPrenotazione] = useState(schedulazione.tipoPrenotazione)
	const [duratagiornaliera, setduratagiornaliera] = useState(0)
	const [listatipi, setlistatipi] = useState([])
	const [tipocorso, settipocorso] = useState({ id: schedulazione.idtipocorso })
	const [listaCorsi, setlistaCorsi] = useState([])
	const [, setvisible] = useState(false)

	const dispatch = useDispatch()

	function inviaMessaggio() {
		if (messaggio.trim() === '') {
			Swal.fire('Errore', 'Messaggio obbligatorio', 'error');
			return;
		}
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email', email);
		formData.append('lic', '000');
		formData.append('palestra', palestra.id);
		formData.append('msg', messaggio);
		formData.append('idschedulazione', id);
		axios.post(global.config.server + '/inviaMessaggioSchedulazione', formData)
			.then((result) => {
				Swal.fire('Successo', result.data, 'success');
			});
	}

	function annullaPrenotazione(e) {
		if (window.confirm("Vuoi annullare la prenotazione selezionata?")) {
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email', email);
			formData.append('lic', '000');
			formData.append('palestra', palestra.id);
			formData.append('idPrenotazione', e.target.id);
			formData.append('calendario', calendario);
			axios.post(serverInfo.namesec + '/abb/annullaPrenotazione', formData,
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'multipart/form-data',
						'Access-Control-Allow-Origin': '*',
						'Access-Control-Allow-Methods': 'POST',
						'Access-Control-Allow-Headers': 'Content-Type, Authorization',
						'Access-Control-Allow-Credentials': 'true',
						'Authorization': 'Bearer ' + window.$t
					},
				},
			)
				.then((result) => {
					Swal.fire('Successo', result.data, 'success');
					setvisible(false)
					refreshPrenotazioni()
				})
				.catch(err => {
					if (err.response) {
						Swal.fire('Errore', err.response.data.message, 'error');
					}
					else {
						Swal.fire('Errore', err.message, 'error');
					}
				})
		}
	}

	function annullaDefinitivamente(e) {
		if (window.confirm("Vuoi annullare la prenotazione fissa selezionata?")) {
			const formData = new FormData();
			formData.append('idPrenotazione', e.target.id);
			axios.post(global.config.server + '/annullaDefinitivamente', formData)
				.then((result) => {
					Swal.fire('Successo', result.data, 'success');
					setvisible(false)
					refreshPrenotazioni()
				})
				.catch(err => {
					if (err.response) {
						Swal.fire('Errore', err.response.data.message, 'error');
					}
					else {
						Swal.fire('Errore', err.message, 'error');
					}
				})
		}
	}

	function eliminaSchedulazione(e) {
		Swal.fire({
			title: 'Sei sicuro?',
			text: "Vuoi eliminare la schedulazione selezionata?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Sì, elimina'
		}).then(result => {
			if (result.isConfirmed) {
				const formData = new FormData();
				formData.append('website', global.config.domain);
				formData.append('email', email);
				formData.append('lic', '000');
				formData.append('palestra', palestra.id);
				formData.append('idSchedulazione', id);
				formData.append('calendario', calendario);
				axios.post(global.config.server + '/eliminaSchedulazione', formData)
					.then((result) => {
						Swal.fire('Eliminata!', result.data, 'success');
						navigate('/annotatio-prods/', true)
					})
					.catch(err => {
						if (err.response) {
							Swal.fire('Errore', err.response.data.message, 'error');
						}
						else {
							Swal.fire('Errore', err.message, 'error');
						}
					})
			}
		});
	}

	function aggiungiPrenotazione() {
		if (!anagrafica && !nomeLibero) {
			Swal.fire('Errore', 'Specificare una anagrafica oppure un nome libero', 'error');
			return;
		}
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email', email);
		formData.append('lic', '000');
		formData.append('palestra', palestra.id);
		formData.append('idUtente', anagrafica ? anagrafica : '');
		formData.append('idAnagrafica', anagrafica ? anagrafica : '');
		formData.append('nomelibero', nomeLibero ? nomeLibero : '');
		formData.append('idSchedulazione', id);
		formData.append('fisso', fisso && (fisso === true || fisso === 1 || fisso === 'on') ? 1 : 0);
		formData.append('calendario', calendario);
		axios.post(global.config.server + '/inserisciPrenotazione', formData)
			.then((result) => {
				setshowInsPreno(false)
				if (result.data.conferma) {
					Swal.fire({
						title: 'Conferma',
						text: result.data.message,
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: 'Continua'
					}).then(r => {
						if (r.isConfirmed) {
							axios.post(global.config.server + '/confermaPrenotazione', formData)
								.then((result2) => {
									Swal.fire('Successo', result2.data.message, 'success');
									refreshPrenotazioni()
								}).catch(err => {
									if (err.response) {
										Swal.fire('Errore', err.response.data.message, 'error');
									}
									else {
										Swal.fire('Errore', err.message, 'error');
									}
								})
						}
					});
				}
				else {
					Swal.fire('Successo', result.data.message, 'success');
					refreshPrenotazioni()
				}
			}).catch(err => {
				if (err.response) {
					Swal.fire('Errore', err.response.data.message, 'error');
				}
				else {
					Swal.fire('Errore', err.message, 'error');
				}
			});

	}

	function refreshPrenotazioni() {
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email', email);
		formData.append('lic', '000');
		formData.append('palestra', palestra.id);
		axios.post(global.config.server + '/getPrenotazioni/' + schedulazione.id, formData)
			.then((result) => {
				setprenotazioni(result.data)
			})
			.catch(err => {
				if (err.response) {
					Swal.fire('Errore', err.response.data.message, 'error');
				}
				else {
					Swal.fire('Errore', err.message, 'error');
				}
			})
	}

	function aggiornatipo(idtipo) {
		settipocorso(idtipo)
		setcorso('')
	}

	function handleSubmit(event) {
		event.preventDefault();

		const formData = new FormData();
		formData.append('duratagiornaliera', duratagiornaliera ? 1 : 0);
		formData.append('durata', duratagiornaliera ? 900 : durata); // 900 minuti = 15 ore
		formData.append('numMaxPrenotazioni', numMaxPrenotazioni);
		formData.append('corso', corso);
		formData.append('data', data);
		formData.append('ora', duratagiornaliera ? '06:00' : ora);
		formData.append('id', id);
		formData.append('tipoPrenotazione', tipoPrenotazione);
		formData.append('website', global.config.domain);
		formData.append('email', email);
		formData.append('lic', '000');
		formData.append('palestra', palestra.id);
		formData.append('calendario', calendario);
		formData.append('idtipocorso', tipocorso && tipocorso.id ? tipocorso : '');
		axios.post(global.config.server + '/aggiornaSchedulazione', formData)
			.then((result) => {
				setmessaggio(result.data);
				Swal.fire('Successo', result.data, 'success');
			}).catch(err => {
				if (err.response) {
					Swal.fire('Errore', err.response.data.message, 'error');
				}
				else {
					Swal.fire('Errore', err.message, 'error');
				}
			});
	}

	function caricacorsi() {
		async function caricac() {
			const result = await getcorsi(email, palestra.id, '')
			setlistatipi(result);
			dispatch(settipiprodotto(result))
			setlistaCorsi(result);
		}
		caricac()
	}

	useEffect(() => {
		caricacorsi()
		refreshPrenotazioni()
	}, [])


	return (
		<Container maxWidth={isMediumScreen ? "lg" : "md"} sx={{ padding: isSmallScreen ? 2 : 4 }}>
			<Typography variant="h4" gutterBottom>
				Schedulazione corso: {schedulazione.descrizione}
				<Button variant="outlined" onClick={() => navigate('/annotatio-prods/', true)}>
					Torna al calendario
				</Button>
			</Typography>
			
			
			<Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
				<form onSubmit={handleSubmit}>
					<Grid container spacing={2}>
					<Grid item  xs={12} sm={5} >
							<SelectTipologiaCorso updateTipo={aggiornatipo} corsi={listatipi} selected={tipocorso} />
						</Grid>
						<Grid item container xs={1}>
							
							oppure
						</Grid>
						<Grid item  xs={12} sm={5}>

							<CorsiSelect onChange={(e) => { settipocorso(null); setcorso(e.target.value) }} selected={corso} corsi={listaCorsi} />

						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Giorno"
								type="date"
								fullWidth
								value={data}
								onChange={(e) => setdata(e.target.value)}
								InputProps={{
									startAdornment: <InputAdornment position="start"><CalendarMonthIcon /></InputAdornment>,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Ora"
								type="time"
								fullWidth
								value={ora}
								disabled={duratagiornaliera}
								onChange={(e) => setora(e.target.value)}
								InputProps={{
									startAdornment: <InputAdornment position="start"><AccessTimeIcon /></InputAdornment>,
								}}
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<TextField
								label="Durata"
								type="number"
								fullWidth
								value={durata}
								disabled={duratagiornaliera}
								onChange={(e) => setdurata(e.target.value)}
								InputProps={{
									startAdornment: <InputAdornment position="start"><TimerIcon /></InputAdornment>,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControlLabel
								control={
									<Checkbox
										checked={duratagiornaliera}
										onChange={(e) => setduratagiornaliera(e.target.checked)}
										color="primary"
									/>
								}
								label="Tutto il giorno (06:00 - 21:00)"
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<TextField
								label="Max prenotazioni"
								type="number"
								fullWidth
								value={numMaxPrenotazioni}
								onChange={(e) => setnumMaxPrenotazioni(e.target.value)}
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<FormControl fullWidth>
								<InputLabel>Tipo prenotazione</InputLabel>
								<Select
									value={tipoPrenotazione}
									onChange={(e) => settipoPrenotazione(e.target.value)}
								>
									<MenuItem value="Abbonamento">Abbonamento</MenuItem>
									<MenuItem value="Free">Free</MenuItem>
									<MenuItem value="Pagamento Anticipato">Pagamento Anticipato</MenuItem>
									<MenuItem value="Pagamento Posticipato o in loco">Pagamento Posticipato</MenuItem>
								</Select>
							</FormControl>
						</Grid>
				
						

				
					</Grid>

					<Grid container justifyContent="flex-end" spacing={2} sx={{ marginTop: 3 }}>
						<Grid item>
							<Button type="submit" variant="contained" color="primary" startIcon={<Save />}>
								Salva
							</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" color="error" startIcon={<Delete />} onClick={eliminaSchedulazione}>
								Elimina
							</Button>
						</Grid>
					</Grid>
				</form>
			</Paper>

			<Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
				<Grid container justifyContent="space-between" alignItems="center">
					<Typography variant="h6">Prenotazioni</Typography>
					<Button variant="contained" color="primary" onClick={() => setshowInsPreno(true)}>
						Aggiungi Prenotazione
					</Button>
				</Grid>

				<TableContainer sx={{ marginTop: 2 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>N.</TableCell>
								<TableCell>Nome</TableCell>
								<TableCell>Abb.</TableCell>
								<TableCell>Fisso</TableCell>
								<TableCell>Data prenotazione</TableCell>
								<TableCell>Modalità</TableCell>
								<TableCell>Azioni</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{prenotazioni
								.filter(prenotazione => !prenotazione.dataAnnullamento||prenotazione.dataAnnullamento==='')
								.concat(prenotazioni.filter(prenotazione => prenotazione.dataAnnullamento&&prenotazione.dataAnnullamento!==''))
								.map((prenotazione, i) => (
									<TableRow key={prenotazione.id}>
										<TableCell>
											{
											(!prenotazione.dataAnnullamento||prenotazione.dataAnnullamento==='')&&
											(i + 1 > numMaxPrenotazioni ? `RISERVA ${i + 1 - numMaxPrenotazioni}` : i + 1)}
											</TableCell>
										<TableCell>{prenotazione.nomecognome}</TableCell>
										<TableCell>{prenotazione.listino}</TableCell>
										<TableCell>{prenotazione.fisso ? "(FISSO)" : null}</TableCell>
										<TableCell>{prenotazione.dataOraPrenotazione}</TableCell>
										<TableCell>{prenotazione.modalitaPrenotazione}</TableCell>
										<TableCell>
											{!prenotazione.dataAnnullamento && (
												<>
													<Button
														variant="outlined"
														color="error"
														size="small"
														onClick={annullaPrenotazione}
														id={prenotazione.id}
														sx={{ marginRight: 1 }}
													>
														Annulla
													</Button>
													{prenotazione.fisso && (
														<Button
															variant="outlined"
															color="error"
															size="small"
															onClick={annullaDefinitivamente}
															id={prenotazione.id}
														>
															Annulla Fissa
														</Button>
													)}
												</>
											)}
											{prenotazione.dataAnnullamento && (
												<div>Annullato il {prenotazione.dataAnnullamento}</div>
											)}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>

			<Paper  elevation={3} 
  sx={{ 
    padding: 3, 
    marginBottom: 4, 
    maxWidth: isMediumScreen ? '80%' : '100%', 
    width: '100%' 
  }}>
				<Typography variant="h6">Invia messaggio (WhatsApp)</Typography>
				<TextField
					fullWidth
					multiline
					rows={3}
					variant="outlined"
					label="Messaggio"
					value={messaggio}
					onChange={(e) => setmessaggio(e.target.value)}
					sx={{ marginTop: 2 }}
				/>
				<Button
					variant="contained"
					color="primary"
					onClick={inviaMessaggio}
					sx={{ marginTop: 2 }}
				>
					Invia Messaggio
				</Button>
			</Paper>

			<Dialog open={showInsPreno} onClose={() => setshowInsPreno(false)}>
				<DialogContent>
					<Typography variant="h6">Nuova prenotazione per {schedulazione.descrizione}</Typography>
					<FormControl fullWidth sx={{ marginTop: 2 }}>
						<InputLabel>Anagrafica</InputLabel>
						<UtentiFilterSelect onChange={(e) => setanagrafica(e.target.value)} />
					</FormControl>
					{anagrafica && (
						<FormControlLabel
							control={<Switch checked={fisso} onChange={(e) => setfisso(e.target.checked)} />}
							label="Fisso"
							sx={{ marginTop: 2 }}
						/>
					)}
					<TextField
						fullWidth
						label="Nome libero"
						value={nomeLibero}
						onChange={(e) => setnomeLibero(e.target.value)}
						sx={{ marginTop: 2 }}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setshowInsPreno(false)}>Chiudi</Button>
					<Button variant="contained" onClick={aggiungiPrenotazione}>Aggiungi</Button>
				</DialogActions>
			</Dialog>
		</Container>
	);
}
