import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../../util/config'
import { useSelector } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

export default function NomiCalendariSelect ({label, selected, nomis, onChange}) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [nomi, setnomi] = useState(nomis)
	const [calendarioselezionato, setcalendarioselezionato] = useState(nomis)
	const [nuovocalendario, setnuovocalendario] = useState('')
	 
	useEffect(() =>{
		if(!nomis){
			const formData = new FormData();
			formData.append('website',palestra.website);
			formData.append('email',email);
			formData.append('lic','000');
			formData.append('palestra',palestra.id);
			axios.post(global.config.server+"/getNomiCalendari",formData)
			.then(response=>{
				setnomi(response.data)
			}).catch(err => {
				if (err.response){
				alert(err.response.data.message);
				}
				else{
				alert(err.response);
				}
		});
		}
	  }
	);
	
	    return (
           
			 <FormControl fullWidth={true} variant="standard">
              <InputLabel>{label}</InputLabel>
            <Select fullWidth={true}
            required size="small"
            value={selected} 
			onChange={(e) => {setcalendarioselezionato(e.target.value);setnuovocalendario('');onChange(e.target.value)}}
			 >              
			{nomi&&Array.isArray(nomi)&&nomi.length>0?
					nomi.map(nome =>(
					<MenuItem key={nome} value={nome}>{nome}</MenuItem>
					)
					)
					:null}
			</Select>
			<TextField variant="standard" label="Oppure inseriscine un nuovo calendario"
			name="nomecalendario" value={nuovocalendario} onChange={(e) =>{setnuovocalendario(e.target.value); setcalendarioselezionato(''); onChange(e.target.value)}} />
			</FormControl>
	    );
	}