 import React from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import PagamentoEdit from "../pagamenti/PagamentoEdit";
import IscrizioneEdit from './IscrizioneEdit'
import { Card,  Badge } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import AbbonamentoEdit from "./AbbonamentoEdit";
import NuovoPagamento from "../pagamenti/NuovoPagamento";
import ListaFatture from "../fatture/ListaFatture";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import AccordionRicevute from "../ricevute/AccordionRicevute";

export default function AbbonamentoUtente(props) {
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
    const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata);

  const [showModalPagamenti,setshowModalPagamenti] = useState(false);
  const [dateIngressiPrenotazioni,setdateIngressiPrenotazioni] = useState(null);
  const [ingressiTotali,setingressiTotali] = useState(null);
  const [lezioniDaRecuperare, setlezioniDaRecuperare] = useState(null);

  function togglePagamenti(){
      setshowModalPagamenti(!showModalPagamenti)
    }

    useEffect(() => {
      getIngressiResidui();
    },[anagrafica])
    
    function eliminaAbbonamento(){
        if (window.confirm('Sei sicuro di voler cancellare l\'abbonamento selezionato?')){
          const formData3 = new FormData();
          formData3.append('id', props.selected.id);
          formData3.append('website', global.config.domain);
          formData3.append('email',email);
          formData3.append('lic','000');
          formData3.append('palestra',palestra.id);
          axios.post(global.config.server+'/eliminaAbbonamentoRest',formData3)
                .then((result) => {
                   const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
              });
              Toast.fire({
                type: 'success',
                title: result.data
              })
              props.refresh(new Date().getTime())
            }).catch(err => {
                if (err.response){
                alert(err.response.data.message);
                }
                else{
                  alert(err)
                }
              });
        }
      }

      function getIngressiResidui(){
        const formData3 = new FormData();
        formData3.append('idabbonamento', props.selected.id);
        formData3.append('palestra',palestra.id);
        axios.post(global.config.server+'/listaRecuperiResidui',formData3)
        .then((result) => {
           setdateIngressiPrenotazioni(result.data.dateIngressiPrenotazioni);
           setingressiTotali(result.data.ingressiTotali);
           setlezioniDaRecuperare(result.data.lezioniDaRecuperare);
        }).catch(err => {
            if (err.response){
            alert(err.response.data.message);
            }
            else{
              alert(err)
            }
          });
            }
  
      return (
        <Card>
        <Card.Header>
          <Accordion.Toggle  as={Card.Header} eventKey={props.selected.id}>
              {props.selected.listino?
              props.selected.listino.tipoAbbonamento.descrizione+" "+props.selected.listino.periodo.descrizione
              :"Nessun listino"}
              {props.selected.valido?
              <Badge variant="primary">(Scad {props.selected.validoA})</Badge>
                        :
                        <Badge variant="danger">
                         (Scad {props.selected.validoA})
                         </Badge>
                      } &nbsp;
           {ingressiTotali&&!lezioniDaRecuperare?"Ingressi rimanenti "+(ingressiTotali-dateIngressiPrenotazioni.split(',').length)+" ("+dateIngressiPrenotazioni+")":""}
          </Accordion.Toggle>
        </Card.Header>
        
        <Accordion.Collapse eventKey={props.selected.id}>
        <Card.Body style={{backgroundColor:"#FFFFAA"}}>
        <AbbonamentoEdit abbonamento={props.selected} 
        email={email} 
        listini={props.listini}
        idpalestra={palestra.id}
        sconti={props.sconti}
        refresh={props.refresh}
        eliminaAbbonamento={eliminaAbbonamento} />
          <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={"Iscr"+props.selected.iscrizione.id}>
            Iscrizione (scad. {props.selected.iscrizione.datascadenza})
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"Iscr"+props.selected.iscrizione.id}>
              <Card.Body>
            <IscrizioneEdit iscrizione={props.selected.iscrizione} email={props.email} refresh={props.refresh} />
          </Card.Body>
          </Accordion.Collapse>
          </Card>
          </Accordion>
        
            <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={"pag"+props.selected.iscrizione.id}>
           Pagamenti (Scadenza  {props.selected.dataScadenzaPagamento}) {props.selected.pagamentiValidi?
          <Badge variant="primary">Validi </Badge>
                        :
                        <Badge variant="danger">
                         NON saldati
                         </Badge>
                      }
            &nbsp;SALDO {props.selected.prezzo - props.selected.totPagamenti}&euro;
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"pag"+props.selected.iscrizione.id}>
              <Card.Body>
             
                <NuovoPagamento id={0} idabbonamento={props.selected.id}
                  show={showModalPagamenti}
                  togglePagamenti={togglePagamenti}
                  email={props.email}
                  refresh={props.refresh}
                  />
                      {props.selected.pagamenti
                      &&props.selected.pagamenti.length>0?
                      props.selected.pagamenti.map(pagamento=>(
                        <PagamentoEdit selected={pagamento} email={props.email} refresh={props.refresh} />

                      )
                        )
                      :<span> Nessun pagamento effettuato</span>
                      }
                      
                 
                    </Card.Body>
                    </Accordion.Collapse>
                    </Card>
                    </Accordion>
          

                    <AccordionRicevute
                    idabbonamento={props.selected.id} 
                     />
               
              <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={"abb"+props.selected.iscrizione.id}>
            Fatture {props.selected.fatture
            &&Array.isArray(props.selected.fatture)
            &&props.selected.fatture.length>0?
                    <Badge variant="primary">({props.selected.fatture.length})</Badge>
                        :
                        <Badge variant="info">
                         Nessuna fattura
                         </Badge>
                      }
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"abb"+props.selected.iscrizione.id}>
              <Card.Body>
                    <ListaFatture email={props.email} idabbonamento={props.selected.id} idUtente={idanagselezionata} fatture={props.selected.fatture} descrizioneAbbonamento={props.selected.listino?props.selected.listino.tipoAbbonamento.descrizione+" "+props.selected.listino.periodo.descrizione:""}/>
               </Card.Body>
               </Accordion.Collapse>
               </Card>
              </Accordion>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      );
  }