import { TextField } from "@mui/material";
import React from "react";

export default function InputTextMultiLine({label, id, type, placeholder,name, value, onChange, align, disabled, width, required, cols}){

    return (
        <TextField 
        variant="standard" fullWidth
            label={label}
            type={type?type:"text"}
            style={{textAlign:type==='number'?'right':align?align:'',width:width?width:'100%',margin:0, padding:0}}
            required={required?required:false}
            id={id?id:Math.floor(Math.random() * 100)} 
            multiline
            cols={cols}
            
            placeholder={placeholder} name={name} value={value} onChange={onChange}
            disabled={disabled?disabled:false} />

    );
}