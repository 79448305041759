import React from "react";
import 'reactjs-popup/dist/index.css';
import { navigate } from "hookrouter";
import { useState } from "react";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useDispatch} from 'react-redux'
import { setanagselezionata, setidanagselezionata } from "../STORE/reducers/gestoreUtente";
import { Button, TableCell, TableRow } from "@mui/material";

export default function Utenti (props) {
    const [selected, setselected] = useState(false);
    const dispatch = useDispatch();

    function seleziona() {
        dispatch(setidanagselezionata(props.anagrafica.id));
        dispatch(setanagselezionata(props.anagrafica));
        navigate('/anntt-adm-dettanag/')
    }

    return (
        <TableRow >
            <TableCell component="th" scope="row">
            <Button variant="contained" onClick={seleziona} startIcon={<ManageAccountsIcon/>}>{props.anagrafica.id}</Button>
            </TableCell>
            <TableCell>
                {props.anagrafica.cognome}
            </TableCell>
            <TableCell>
                {props.anagrafica.nome}
            </TableCell>
            <TableCell>
                {props.anagrafica.datanascita}
            </TableCell>
            <TableCell>
                {props.anagrafica.citta}
            </TableCell>
            <TableCell>
                {props.anagrafica.sesso}
            </TableCell>
            <TableCell>
                {props.anagrafica.codicefiscale}
            </TableCell>
                <TableCell>
                    {props.anagrafica.telefono}
                </TableCell>
                <TableCell>
                {props.anagrafica.abbonamenti&&props.anagrafica.abbonamenti.length>0?
                    <table className="table table-sm table-bordered">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Descrizione</th>
                            <th>Scadenza</th>
                            <th>Prezzo</th>
                            <th>Pagato</th>
                            <th>Saldo</th>
                            <th>Ricevute</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.anagrafica.abbonamenti.map((abbo,index)=>(
                            <tr key={index}>
                            <TableCell>
                                {abbo.listino?abbo.listino.tipoAbbonamento.descrizione+" "+abbo.listino.periodo.descrizione:""}
                            </TableCell>
                            <TableCell>{abbo.validoA}</TableCell>
                            <TableCell>{abbo.prezzo}</TableCell>
                            <TableCell>{abbo.totPagamenti}</TableCell>
                            <TableCell>{abbo.prezzo-abbo.totPagamenti}</TableCell>
                            <TableCell>
                            {abbo.ricevute
                        &&Array.isArray(abbo.ricevute)
                        &&abbo.ricevute.length>0?
                            abbo.ricevute.map(ricevuta=>(
                                <div key={ricevuta.numero}>{ricevuta.numero+" del "+ricevuta.anno}</div>
                            ))

                    :"Nessuna ricevuta"}
                            </TableCell>
                            
                            </tr>
                        ))}
                        
                        </tbody>
                        </table>
                    :""
                }
                </TableCell>
                <TableCell>
                    {props.anagrafica.numerotessera}
                </TableCell>
                <TableCell>
                {props.anagrafica.ordini&&props.anagrafica.ordini.length>0?
                    <table className="table table-sm table-bordered">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Descrizione</th>
                            <th>Data</th>
                            <th>Prezzo</th>
                            <th>Pagato</th>
                            <th>Ricevute/Fatture</th>
                        </tr>
                        </thead>
                        <tbody>
                       {
                        props.anagrafica.ordini.map(ordine => (
                            <tr>
                            <TableCell>{ordine.prenotazioni.map(preno => 
                                <div>{preno.listino}</div>)}
                            </TableCell>
                            <TableCell>{ordine.prenotazioni[0]?ordine.prenotazioni[0].dataOra:''}</TableCell>
                            <TableCell>{ordine.totaleEuro}</TableCell>
                            <TableCell>{ordine.totalePagato}</TableCell>
                        </tr>
                        ))
                       }
                        
                        </tbody>
                        </table>
                    :""
                }
                            </TableCell>
                <TableCell>
                    {
                        props.anagrafica.onesignalid?
                        <img src='/wp-content/uploads/2022/02/smartphone.png' style={{width:'15px'}} alt='abilitato ricezione msg' title='abilitato ricezione msg' />
                        :null
                    }
                    {
                        props.anagrafica.email?
                        <strong>@</strong>
                        :null
                    }
						<input type="checkbox" name="sendmessaggio" value="1" id={"check_"+props.anagrafica.id} checked={selected}
                        onChange={(e) => {e.target.checked?setselected(true):setselected(false); e.target.checked?props.addMsgList('add', props.anagrafica.id):props.addMsgList('del', props.anagrafica.id)}}
                            />
                </TableCell>
                
        </TableRow>
    );
}