import React from "react";
import axios from 'axios';
import {navigate} from 'hookrouter'
import FatturaEdit from './FatturaEdit'
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Grid } from "@mui/material";
import Receipt from "@mui/icons-material/Receipt";
import AddCircle from "@mui/icons-material/AddCircle";

export default function ListaFatture (props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

const [fatture, setfatture] = useState([]);
const [possibiliIntestatari, setpossibiliintestatari] = useState([]);
const [showNuova, setshownuova] = useState(false);


function showNuovaFattura(){
	setshownuova(!showNuova)
  }

  useEffect(() => {
	if (props.fatture
		&&Array.isArray(props.fatture)
		&&props.fatture.length>0){
			setfatture(props.fatture);
	}
	else if (props.idabbonamento&&palestra&&palestra.palestra&&palestra.palestra.id){
		const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.palestra.id);
		axios.post(global.config.server+"/getFatture/"+props.idabbonamento,formData)
		.then(response=>{
			setfatture(response.data);
			//console.log(response);
		});
	}
	

	axios.post(global.config.server+"/getPossibiliIntestatari/"+props.idUtente)
		.then(response=>{
			setpossibiliintestatari(response.data);
			console.log(response);
		});
},[]);

    return (
    		<Grid item container xs={12}>
				<Button variant="contained" onClick={()=>navigate('/annotatio-ins-fatt/'+anagrafica.id)}>
					<Receipt />
					<AddCircle />
				</Button>
		    		{
						(fatture&&fatture.length>0?
							fatture.map(item =>(
								<FatturaEdit selected={item} refresh={props.refresh} email={email} possibiliIntestatari={possibiliIntestatari} idUtente={props.idUtente} idabbonamento={props.idabbonamento}/>
							))
		    					:
								<div className="col-sm">Nessuna fattura</div>
		    			)
		    		}	
    		</Grid>
    );
}