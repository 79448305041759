import React, { useState } from "react";
import '../../util/config'
import axios from 'axios';
import NuovoPeriodoInput from "./NuovoPeriodoInput";
import PeriodoInput from "./PeriodoInput";
import { useSelector } from "react-redux";
import serverInfo from "../../util/globals";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export default function PeriodiInput(props){
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [listaPeriodi, setlistaPeriodi] = useState([])

	useState(() =>{
		if (palestra&&palestra.id){
			const formData = new FormData();
			formData.append('website', palestra.website);
			formData.append('email',email);
			formData.append('lic','000');
			formData.append('palestra',palestra.id);
			axios.post(serverInfo.namesec+"/wp/getPeriodiRest", formData,
			{
				headers: {
				  Accept: 'application/json',
				  'Content-Type': 'multipart/form-data',
				  'Access-Control-Allow-Origin': '*',
				  'Access-Control-Allow-Methods': 'POST',
				  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				  'Access-Control-Allow-Credentials': 'true',
				  'Authorization' :'Bearer '+window.$t
				},
			  },)
		.then(response=>{
			setlistaPeriodi(response.data);
			//console.log(response);
		}).catch(err => {
			if (err.response){
			alert(err.response.data.message);
			}
			else{
			alert(err.response);
			}
	});
	}
	}
	);

    return (
    		<Grid item container xs={12}>
				
    		{listaPeriodi&&Array.isArray(listaPeriodi)&&listaPeriodi.length>0&&
    			listaPeriodi.map(item =>(
    					<PeriodoInput key={item.id} 
						selected={item} 
						email={props.email} />
    					)
    			)
    		}
			
    		<NuovoPeriodoInput email={props.email} />
    		</Grid>
    );
}