import React from "react";
import {navigate} from 'hookrouter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faPlus, faFileInvoice} from '@fortawesome/free-solid-svg-icons';
import { Grid } from "@mui/material";

export default function GestioneFatture(){

    return (
        <Grid item container xs={12}>
            <div className="col-md-4">
                    <div className="card border-dark" style={{margin:10}} onClick={()=> navigate('/annotatio-ric-fatt/',true)}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}} icon={faAddressCard} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Ricerca</h5>
                        <p className="card-text">
                        </p>
                    </div>
                    <div className="card-footer">
                    Ricerca, stampa, download, export
                     </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card border-dark" style={{margin:10}} onClick={()=> navigate('/annotatio-ins-fatt/',true)}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}} icon={faPlus} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Nuova Fattura</h5>
                        <p className="card-text">
                        </p>
                    </div>
                    <div className="card-footer">
    Inserimento nuova fattura
                     </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card border-dark" style={{margin:10}} onClick={()=> navigate('/annotatio-fattric/',true)}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}} icon={faFileInvoice} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Fatture Ricevute</h5>
                        <p className="card-text">
                        </p>
                    </div>
                    <div className="card-footer">
    Inserimento nuova fattura
                     </div>
                    </div>
                </div>
            
        </Grid>
    )
}