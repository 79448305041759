import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { copiaDatiEsercizioNextSett,  salvadatiEsercizio } from "../../DAO/schedesalaattrezziDAO";
import { Button } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EventIcon from '@mui/icons-material/Event';
import RedoIcon from '@mui/icons-material/Redo';

let timeout = 0;
export default function EsercizioSchedaSett({ numSettimana, idschedaesercizio, serieo, recuperoo, tuto, ripetizioni1o, ripetizioni2o, 
    ripetizioni3o, peso1o, peso2o, peso3o, refresh}) {
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

    const [serie, setserie] = useState(serieo ? serieo : '')
    const [recupero, setrecupero] = useState(recuperoo ? recuperoo : '')
    const [tut, settut] = useState(tuto ? tuto : '')
    const [ripetizioni1, setripetizioni1] = useState(ripetizioni1o ? ripetizioni1o : '')
    const [ripetizioni2, setripetizioni2] = useState(ripetizioni2o ? ripetizioni2o : '')
    const [ripetizioni3, setripetizioni3] = useState(ripetizioni3o ? ripetizioni3o : '')
    const [peso1, setpeso1] = useState(peso1o ? peso1o : '')
    const [peso2, setpeso2] = useState(peso2o ? peso2o : '')
    const [peso3, setpeso3] = useState(peso3o ? peso3o : '')

    const [modificato, setmodificato] = useState(false)

    function salva() {
        async function salvadati() {
            //setmodificato(true)
            console.log("salvo dati esercizio "+idschedaesercizio)
            const result = await salvadatiEsercizio(email, palestra.id, idschedaesercizio, numSettimana, serie, recupero, tut, ripetizioni1, ripetizioni2, ripetizioni3, peso1, peso2, peso3)
            console.log(result)
            //setmodificato(false)
        }
        salvadati()
    }

    function copiaSettimanaSuccessiva(){
        async function copiadat() {
            //setmodificato(true)
            const result = await copiaDatiEsercizioNextSett(email, palestra.id, idschedaesercizio, numSettimana, serie, recupero, tut, ripetizioni1, ripetizioni2, ripetizioni3, peso1, peso2, peso3)
            console.log(result)
            //setmodificato(false)
            refresh()
        }
        copiadat()

    }


    useEffect(() => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            salva()
        }, 2000)

    }, [serie, recupero, tut, ripetizioni1, ripetizioni2, ripetizioni3, peso1, peso2, peso3])

    return (
        <>
            <td>sett. {numSettimana}</td>
            <td>
                <input className="form-control" placeholder={"serie sett " + numSettimana} required={false} style={{ padding: 1 }} type="text" name="serie" value={serie && serie !== 'null' ? serie : ''}
                    onChange={e => { setserie(e.target.value) }} />
            </td>
            <td>
                <input className="form-control" placeholder={"recupero sett " + numSettimana} required={false} style={{ padding: 1 }} type="text" name="recupero"
                    value={recupero && recupero !== 'null' ? recupero : ''} onChange={(e) => setrecupero(e.target.value)} />
            </td>
            <td>
                <input className="form-control" placeholder={"tut sett " + numSettimana} style={{ padding: 1 }} type="text" name="tut" value={tut && tut !== 'null' ? tut : ''}
                    onChange={(e) => settut(e.target.value)} />
            </td>
            <td>
                <input className="form-control" placeholder={"ripetiz sett " + numSettimana} required={false} style={{ padding: 1 }} type="text" name="ripetizioni1"
                    value={ripetizioni1 && ripetizioni1 !== 'null' ? ripetizioni1 : ''} onChange={(e) => setripetizioni1(e.target.value)} /></td>
            <td>
                <input className="form-control" placeholder={"peso sett " + numSettimana} style={{ padding: 1 }} type="text" name="peso1" value={peso1 && peso1 !== 'null' ? peso1 : ''}
                    onChange={(e) => setpeso1(e.target.value)} /></td>
            <td>
                <input className="form-control" placeholder={"ripetiz 2 sett " + numSettimana} required={false} style={{ padding: 1 }} type="text" name="ripetizioni2"
                    value={ripetizioni2 && ripetizioni2 !== 'null' ? ripetizioni2 : ''} onChange={(e) => setripetizioni2(e.target.value)} />
            </td>
            <td>
                <input className="form-control" placeholder={"peso 2 sett " + numSettimana} style={{ padding: 1 }} type="text" name="peso2" value={peso2 && peso2 !== 'null' ? peso2 : ''}
                    onChange={(e) => setpeso2(e.target.value)} />
            </td>
            <td>
                <input className="form-control" placeholder={"ripetiz 3 sett " + numSettimana} required={false} style={{ padding: 1 }} type="text" name="ripetizioni3"
                    value={ripetizioni3 && ripetizioni3 !== 'null' ? ripetizioni3 : ''} onChange={(e) => setripetizioni3(e.target.value)} />
            </td>
            <td>
                <input className="form-control" placeholder={"peso 3 sett " + numSettimana} style={{ padding: 1 }} type="text" name="peso3" value={peso3 && peso3 !== 'null' ? peso3 : ''}
                    onChange={(e) => setpeso3(e.target.value)} />
            </td>
            <td>
                {numSettimana<16&&serie&&<Button title="Copia in settimana successiva" size="small" onClick={()=> copiaSettimanaSuccessiva()}>
                    <ContentCopyIcon /> <RedoIcon /> <EventIcon /> 
                </Button>}
            </td>
            {modificato&&<td>
               <button onClick={salva}>Salva</button>
            </td>}
        </>
    );
}