import React from "react";
import '../../util/config'
import axios from 'axios';
import Swal from "sweetalert2";
import { connect } from "react-redux";
import InputText from "../../util/UI/InputText";
import { Button } from "@mui/material";
import Save from "@mui/icons-material/Save";
import ControlPointIcon from '@mui/icons-material/ControlPoint';

class NuovoScadenzaRataInput extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		giorni: null,
	    		rata: null,
	    		idPeriodo: this.props.periodo,
	    		visible: false,
	    		messaggio:null
	    	}
	    this.update = this.update.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.showNuovoPeriodo=this.showNuovoPeriodo.bind(this);
	  }
	  
	  update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }
	  
	handleSubmit(event) {
		event.preventDefault();
	    const {giorni, rata, idPeriodo } = this.state;

		const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
		  formData.append('giorni', giorni);
		formData.append('rata', rata);
		formData.append('idPeriodo', idPeriodo);
		
	      axios.post(global.config.server+'/inserisciScadenza',formData)
	      .then((result) => {
		        this.setState({messaggio:result.data.message});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data.message
					})
		          this.props.refresh();
		          this.setState({ visible: false });
	      });
	  }

	showNuovoPeriodo(){
		this.setState({ visible: !this.state.visible });
	}
	  

	render() {
		var style1={
				color:"white"
		}

		
		var style3={
			backgroundColor: "#D0D0D0"
		}
		return (
			<div className="row">
				<div className="col" style={style3}>
					<h4 onClick={this.showNuovoPeriodo}>Nuova Scadenza</h4>
				</div>
				{this.state.visible?
				<div className="row">
					<div className="col-sm-12">
						<span>{this.state.messaggio}</span>
						<form className="form-horizontal" method="post">
						<div className="row">
							<div className="col">
								<InputText label="Num. Giorni scadenza pagamento rata" type="text" onChange={this.update} name="giorni" value={this.state.giorni} required={true} />
							</div>
							<div className="col">
								 <InputText label="Importo rata %" type="number" onChange={this.update} name="rata" value={this.state.rata} required={true} />
							</div>
							<div className="col">
				  				<Button onClick={this.handleSubmit}>
									<ControlPointIcon />
								</Button>
				  			</div>
		  				</div>
		  				</form>
					</div>
				</div>
			:null}
			</div>
	);
	}
}

const mapStateToProps = state => {
	return {
	  palestra: state.gestoreUtente.palestraassociata.palestra,
	  email: state.gestoreUtente.emailloggato,
	  idanagselezionata: state.gestoreUtente.idanagselezionata
	}
  }
  
  export default connect(mapStateToProps, null)(NuovoScadenzaRataInput)