import React from "react";
import axios from 'axios';
import { connect } from 'react-redux'
import { navigate } from 'hookrouter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import serverInfo from "../util/globals";
import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, Typography } from "@mui/material";
import InputText from "../util/UI/InputText";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import HomeIcon from '@mui/icons-material/Home';
import { controllodatiobbl } from "../util/controllodatifatturaele";
import ListaNazioni from "../util/UI/listaNazioni";

class RiquadroDettAnagrafica extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anagrafica: null,
            risultato: '',
            istesserato: 0,
            luogonascita: '',
            nome: '',
            cognome: '',
            sesso: '',
            datanascita: '',
            codicefiscale: '',
            nazione:'',
            citta: '',
            cap: '',
            luogonascita: '',
            indirizzo: '',
            telefono: '',
            email: '',
            code: '',
            cognomeNomeGenitore: '',
            dataNascitaGenitore: '',
            codiceFiscaleGenitore: '',
            indirizzoGenitore: '',
            cittaGenitore: '',
            capGenitore: '',
            piva:'',
            anagraficapa:0,
            coddestinatario:'',
            pec:'',
            visible: false
        };
        this.update = this.update.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.caricaAnag = this.caricaAnag.bind(this);
        this.elimina = this.elimina.bind(this);
        this.setSpecialCode = this.setSpecialCode.bind(this);
        this.setnazione = this.setnazione.bind(this);
    }

    componentDidMount() {
        console.log("RiquadroDettAnagrafiche 1")
        this.setState({ id: this.props.idanagselezionata });

        this.caricaAnag();
        console.log("fine RiquadroDettAnagrafiche")
    }

    setnazione(e) {
        this.setState({ nazione: e});
    }

    setSpecialCode(e) {
        this.setState({ code: "HCS" + Math.floor(Date.now() / 1000) });
    }

    componentDidCatch(error, info) {
        console.log(error, info);
    }

    update(e) {
        if (e.target.name === 'istesserato') {
            if (e.target.checked) {
                this.setState({ [e.target.name]: 1, visible: true });
            }
            else {
                this.setState({ [e.target.name]: 0, visible: true });
            }
        }
        else {
            this.setState({ [e.target.name]: e.target.value, visible: true });
        }
    }

    caricaAnag() {
        if (!this.props.idanagselezionata) {
            alert('Id anagrafica non specificato')
            return
        }
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email', this.props.email);
        formData.append('lic', '000');
        formData.append('palestra', this.props.palestra.id);
        formData.append('id', this.props.idanagselezionata);
        axios.post(serverInfo.namesec + '/user/getDettaglioAnagraficaSelezionata', formData,
            {
                withCredentials: true,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST',
                    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + this.props.token
                },
            })
            .then((result) => {
                console.log(result.data)
                this.setState({ ...result.data });
            });
    }

    elimina() {
        if (window.confirm("Sei sicuro di voler CANCELLARE l'anagrafica selezionata?")) {
            const formData = new FormData();
            formData.append('website', global.config.domain);
            formData.append('email', this.props.email);
            formData.append('lic', '000');
            formData.append('palestra', this.props.palestra.id);
            formData.append('id', this.state.id);
            axios.post(global.config.server + '/eliminaAnagSelezionata', formData,
                {
                    withCredentials: true,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'POST',
                        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                        'Access-Control-Allow-Credentials': 'true',
                        'Authorization': 'Bearer ' + window.$t
                    },
                })
                .then((result) => {
                    //console.log(result.data)
                    alert(result.data)
                    //window.location.href="/";
                    navigate('/annotatio-anag/', true)
                });
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        if (!controllodatiobbl(this.state.telefono, this.state.anagraficapa,this.state.coddestinatario,
            this.state.piva,
            this.state.codicefiscale)) return;
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email', this.props.email);
        formData.append('lic', '000');
        formData.append('palestra', this.props.palestra.id);
        formData.append('id', this.state.id);
        formData.append('code', this.state.code);
        formData.append('emailanag', this.state.email);
        formData.append('nome', this.state.nome);
        formData.append('cognome', this.state.cognome);
        formData.append('sesso', this.state.sesso);
        formData.append('datanascita', this.state.datanascita);
        formData.append('luogonascita', this.state.luogonascita);
        formData.append('codicefiscale', this.state.codicefiscale?this.state.codicefiscale:'');
        formData.append('nazione', this.state.nazione?this.state.nazione.toUpperCase():"IT");
        formData.append('indirizzo', this.state.indirizzo);
        formData.append('citta', this.state.citta);
        formData.append('cap', this.state.cap);
        formData.append('telefono', this.state.telefono);
        formData.append('cognomeNomeGenitore', this.state.cognomeNomeGenitore);
        formData.append('dataNascitaGenitore', this.state.dataNascitaGenitore);
        formData.append('codiceFiscaleGenitore', this.state.codiceFiscaleGenitore);
        formData.append('indirizzoGenitore', this.state.indirizzoGenitore);
        formData.append('cittaGenitore', this.state.cittaGenitore);
        formData.append('capGenitore', this.state.capGenitore);
        formData.append('piva', this.state.piva?this.state.piva:'');
        formData.append('pec', this.state.pec);
        formData.append('anagraficapa', this.state.anagraficapa);
        formData.append('coddestinatario', this.state.coddestinatario);

        axios.post(serverInfo.namesec + '/user/modAnagraficaSelezionata', formData,
            {
                withCredentials: true,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST',
                    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + window.$t
                },
            },)
            .then((result) => {
                console.log(result.data)
                //alert(result.data)
                this.setState({message:result.data, visible:true})
            });
    }

    render() {
        return (
            <Grid item container xs={12}>
                <form id="formAnag" onSubmit={this.handleSubmit}>
                    <Grid item container xs={12} padding={2}>
                        <Typography variant="h4">Dettaglio Anagrafica </Typography>
                        <Typography>{this.state.id}</Typography>
                        <Button type="submit" variant="contained">
                            <FontAwesomeIcon icon={faSave} />
                            <Snackbar
                                open={this.state.visible}
                                autoHideDuration={6000}
                                onClose={() => this.setState({ visible: false })}
                                message={this.state.message}
                            />
                        </Button>
                        <Button variant="contained" color="error" onClick={this.elimina}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Grid>
                    <Grid item container xs={12} padding={2} >
                        <Grid>
                            <InputText label="Codice per apertura tornello" type="text" id="code" name="code" value={this.state.code} onChange={this.update} />
                            <Button onClick={this.setSpecialCode}>Associa un codice speciale (no APP)</Button>
                        </Grid>
                        <Grid>
                            <InputText label="Codice nuova app" type="text" disabled={true} value={this.state.applogin ? this.state.applogin.codice : "Nessuna login con nuova app"} />
                            <Typography>{this.state.applogin && this.state.applogin.confermato === 1 ? "confermato il " + this.state.applogin.data : ""}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} padding={2} >
                        <Grid item container xs={12} md={5} padding={2} >
                            <InputText label="Nome" type="text" size="15" name="nome" value={this.state.nome} required="required" onChange={this.update} />
                        </Grid>
                        <Grid item container xs={12} md={5} padding={2} >
                            <InputText label="Cognome" type="text" size="14" name="cognome" value={this.state.cognome} required="required" onChange={this.update} />
                        </Grid>
                        <Grid item container xs={6} md={2} padding={2} >
                            <FormControl variant="standard" fullWidth>
                                <InputLabel>Sesso</InputLabel>
                                <Select name="sesso" placeholder="sesso" value={this.state.sesso} onChange={this.update}>
                                    <MenuItem value="M">M</MenuItem>
                                    <MenuItem value="F">F</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12}  >
                            <Grid item container xs={6} md={4} padding={4} >
                                <InputText label="Data di nascita" type="date" name="datanascita" id="date-mask" placeholder="Enter Date" value={this.state.datanascita} required="required" onChange={this.update} />
                            </Grid>
                            <Grid item container xs={6} md={5} padding={4} >
                                <InputText label="Luogo nascita" type="text" name="luogonascita" placeholder="Luogo nascita" value={this.state.luogonascita} onChange={this.update} />
                            </Grid>
                            <Grid item container xs={12} md={5} padding={2} >
                                <InputText label="Codice fiscale" type="text" name="codicefiscale" maxLength="16" value={this.state.codicefiscale} onChange={this.update} />
                            </Grid>
                            <Grid item container xs={12} md={5} padding={2} >
                                <InputText label="Partita IVA" type="text" name="piva" maxLength="11" value={this.state.piva?this.state.piva:''} onChange={this.update} />
                            </Grid>
                            <Grid item container xs={12} md={5} padding={2} >
                            <label >E' un Pubblica Amministrazione?
                        <input type="checkbox" 
                        className="form-control"
                        placeholder="Pubblica Amministrazione" name="anagraficapa"
                        value={1}
                        checked={this.state.anagraficapa?true:false}
                         onChange={(e) =>this.setState({anagraficapa:(e.target.checked?1:0)})} />                        
                                                </label>
                            </Grid>
                            <Grid item container xs={12} md={5} padding={2} >
                           
                        <label >Codice Destinatario fatture elettroniche
                        <input type="text" 
                        className="form-control"
                        placeholder="Codice destinatario" name="coddestinatario" value={this.state.coddestinatario}
                        onChange={this.update}/>                      
                                                </label>
                                                </Grid>

                        </Grid>
                        <Grid item container xs={12} >
                            <Grid item container xs={12} md={6} padding={2} >
                                <InputText label={<HomeIcon />} placeholder="via" type="text" name="indirizzo" value={this.state.indirizzo} maxLength="255" onChange={this.update} />
                            </Grid>
                            <Grid item container xs={8} md={5} padding={2} >
                                <InputText label="Città" placeholder="Citt&agrave;" type="text" name="citta" value={this.state.citta} maxLength="100" onChange={this.update} />
                            </Grid>
                            <Grid item container xs={4} md={1} padding={2} >
                                --{this.state.nazione}--
                                <ListaNazioni name="nazione" nazione={this.state.nazione} setnazione={this.setnazione} />
                            </Grid>
                            <Grid item container xs={4} md={1} padding={2} >
                                <InputText label="CAP" type="text" size="8" maxLength="5" name="cap" value={this.state.cap} onChange={this.update} />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} >
                            <Grid item container xs={5} md={2} padding={2} >
                                <InputText label={<LocalPhoneIcon />} type="tel" value={this.state.telefono} maxLength="24" name="telefono" required="required" placeholder="Telefono" onChange={this.update} />
                            </Grid>
                            <Grid item container xs={7} md={4} padding={2} >
                                <InputText label={<AlternateEmailIcon />} disable={true} type="email" name="email" value={this.state.email} placeholder="Email" onChange={this.update} />
                            </Grid>
                            <Grid item container xs={7} md={4} padding={2} >
                                <InputText label={<AlternateEmailIcon />} disable={true} type="email" name="pec" value={this.state.pwc} placeholder="PEC" onChange={this.update} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} padding={2} >
                        <Grid item container xs={12} padding={2} >
                            <Typography variant="h5">Eventuali dati del genitore/tutore</Typography>
                        </Grid>
                        <Grid item container xs={12} padding={2} >
                            <Grid item container md={6} xs={12} padding={2} >
                                <InputText label="Cognome e Nome" type="text" name="cognomeNomeGenitore" value={this.state.cognomeNomeGenitore} onChange={this.update} />
                            </Grid>
                            <Grid item container md={3} xs={6} padding={2} >
                                <InputText label="Data nascita" type="date" id="date-mask" placeholder="Data nascita genitore" name="dataNascitaGenitore" value={this.state.dataNascitaGenitore} onChange={this.update} />
                            </Grid>
                            <Grid item container md={3} xs={6} padding={2} >
                                <InputText label="Codice fiscale/P. IVA<" type="text" name="codiceFiscaleGenitore" value={this.state.codiceFiscaleGenitore} onChange={this.update} />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} padding={2}>
                            <Grid item container md={6} xs={12} padding={2}>
                                <InputText label="Indirizzo" type="text" name="indirizzoGenitore" value={this.state.indirizzoGenitore} onChange={this.update} />
                            </Grid>
                            <Grid item container md={3} xs={8} padding={2}>
                                <InputText label="Citta" type="text" name="cittaGenitore" value={this.state.cittaGenitore} onChange={this.update} />
                            </Grid>
                            <Grid item container md={3} xs={4} padding={2}>
                                <InputText label="CAP" type="text" name="capGenitore" value={this.state.capGenitore} onChange={this.update} />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraassociata.palestra,
        email: state.gestoreUtente.emailloggato,
        idanagselezionata: state.gestoreUtente.idanagselezionata,
        token: state.gestoreUtente.token
    }
}

export default connect(mapStateToProps, null)(RiquadroDettAnagrafica)