import React, { useEffect, useState } from "react";
import axios from 'axios';
import NuovaRicevuta from './NuovaRicevuta'
import RicevutaEdit from './RicevutaEdit'
import '../util/config'
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

export default function Ricevute({idabbonamento, idpagamento, refresh, ricevuteinput}) {

	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const idanagselezionata=useSelector(state =>state.gestoreUtente.idanagselezionata);
	const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
    const [ricevute, setricevute] =useState(ricevuteinput)

	const [showModalRicevute, setshowModalRicevute] = useState(false)

	useEffect( () => {
		if (!ricevuteinput){	
		const formData = new FormData();
		formData.append('idabbonamento',idabbonamento);
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.palestra.id);
		axios.post(global.config.server+"/getRicevute", formData)
		.then(response=>{
			setricevute(response.data);
			setkeytorefresh(new Date().getTime())
			//console.log(response);
		}).catch(err => {
			//console.log(err)
			if (err.response){
				alert("Ricevute: "+err.response.data.message);
			}
			else{
			alert("Ricevute: "+err);
			}
		})
	}
	else{
		setricevute(ricevuteinput)
	}
	},[ricevuteinput])

  return(
    	<Grid key={keytorefresh} item container columns={24}>
				<NuovaRicevuta 
				idabbonamento={idabbonamento}
				show={showModalRicevute}
				email={email}
				idpagamento={idpagamento}
				refresh={refresh}
				/>
	    		
		    		{
		    			(!ricevute)?
		    					<div className="col-sm">Nessuna ricevuta</div>
		    					:
		    				ricevute.map(item =>(
		    					<RicevutaEdit key={item.id} selected={item} refresh={refresh}
								email={email} palestra={palestra.id}/>
		    				)
		    			)
		    		}
	    		
    		</Grid>
  )
}