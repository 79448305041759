import React from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import '../util/config'
import { connect } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment } from "@mui/material";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InputText from "../util/UI/InputText";
import Save from "@mui/icons-material/Save";

class NuovaRicevuta extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			anno: new Date().getFullYear(),
			data: new Date().getFullYear()+"-"+(("0" + (new Date().getMonth() + 1)).slice(-2))+"-"+("0" + new Date().getDate()).slice(-2),
			importo: 0,
			numero: null,
			idpagamento: this.props.idpagamento,
			idabbonamento: this.props.idabbonamento,
			visible: false,
			messaggio: null
		}
		this.update = this.update.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	update(e) {
		this.setState({ [e.target.name]: e.target.value, visible: true });
	}

	componentDidMount() {
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email', this.props.email);
		formData.append('lic', '000');
		formData.append('palestra', this.props.palestra.id);
		axios.post(global.config.server + "/getNumeroRicevuta", formData)
			.then(response => {
				this.setState({ numero: response.data });
				//console.log(response);
			}).catch(err => {
				console.log(err)
				if (err.response) {
					alert("Ricevute: " + err.response.data.message);
				}
				else {
					alert("Ricevute: " + err.response);
				}
			})
	}

	handleSubmit(event) {
		event.preventDefault();
		const { id, anno, data, importo, numero, idpagamento, idabbonamento } = this.state;

		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email', this.props.email);
		formData.append('lic', '000');
		formData.append('palestra', this.props.palestra.id);
		formData.append('id', 0);
		formData.append('anno', anno);
		formData.append('data', data);
		formData.append('importo', importo);
		formData.append('numero', numero);
		formData.append('idpagamento', idpagamento ? idpagamento : '');
		formData.append('idabbonamento', idabbonamento);

		axios.post(global.config.server + '/aggiornaRicevutaRest', formData)
			.then((result) => {
				this.setState({ messaggio: result.data });
				
				this.props.refresh();
			}).catch(err => {
				console.log(err)
				if (err.response) {
					alert("Ricevute: " + err.response.data.message);
				}
				else {
					alert("Ricevute: " + err.response);
				}
			})
	}


	render() {
		return (
			<Grid itemo container xs={12}>
				<Button variant="contained" onClick={() => this.setState({ show: true })}>
					<ReceiptLongIcon />
					<AddCircleIcon />
				</Button>

				<Dialog open={this.state.show} onClose={() => this.setState({ show: false })}>
					<DialogTitle>
						Nuova Ricevuta
					</DialogTitle>
					<DialogContent>
						<Grid item container xs={12}>
							<Grid item container xs={6}>
								<InputText
									label="Anno" placeholder="Anno"
									name="anno"
									type="number"
									value={this.state.anno}
									onChange={this.update}
									required={true} />
							</Grid>
							<Grid item container xs={6}>
								<InputText label="Numero" placeholder="numero"
									name="numero"
									value={this.state.numero}
									onChange={this.update}
									required={true} />
							</Grid>
							<Grid item container xs={6}>
								<InputText label="Data" type="date"
									placeholder="Data"
									name="data"
									value={this.state.data}
									onChange={this.update}
									required={true} />
							</Grid>
							<Grid item container xs={6}>
								<InputText label="Importo" type="number" placeholder="importo"
									name="importo"
									value={this.state.importo}
									onChange={this.update}
									required={true}  
									InputProps={{
										startAdornment: <InputAdornment position="start">&euro;</InputAdornment>,
											}} />
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button variant="primary" onClick={this.handleSubmit}>
							<Save />
						</Button>
						<Button variant="primary" onClick={() => this.setState({ show: false })}>
							Close
						</Button>
					</DialogActions>
				</Dialog>

			</Grid>
		);
	}
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
	}
}

export default connect(mapStateToProps, null)(NuovaRicevuta)