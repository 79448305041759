import React from 'react';
import FiltriAbbonamenti from './FiltriAbbonamenti';
import FiltriFatturato from './FiltriFatturato';
import { Grid } from '@mui/material';


export default class Dashboard extends React.Component {
  componentDidMount() {
  }

  render() {
    return (
      <Grid item container>
        <FiltriFatturato />

        <FiltriAbbonamenti />

      </Grid>
    );
  }
}