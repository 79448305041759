/* eslint-disable react/prop-types */
import { Dialog, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react"
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import QrCode from '@mui/icons-material/QrCode';

export default function Qrcode(props){
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
    const palestraassociata = useSelector(state => state.gestoreUtente.palestraassociata);
    
    const [qrcodevalue, setqrcodevalue] = useState(anagrafica.code+'--'+(Number(new Date().getTime())));
    const [qrcodeVisible, setqrcodeVisible] = useState(false);

    useEffect(()=>{
        if (qrcodeVisible){
          setqrcodevalue(anagrafica&&anagrafica.id>0?anagrafica.code+'---'+(Number(new Date().getTime())):"SOCIO-"+palestraassociata.utente.id);
        setTimeout(() => {
          setqrcodevalue(anagrafica&&anagrafica.id>0?anagrafica.code+'---'+(Number(new Date().getTime())):"SOCIO-"+palestraassociata.utente.id);
          setTimeout(()=>
          setqrcodeVisible(false)
          ,5000)
        }, 5000);
      }
      },[qrcodeVisible])

        return (
            <>
            <Dialog onClose={() => setqrcodeVisible(!qrcodeVisible)} open={qrcodeVisible}>
            
            <QRCode value={qrcodevalue} />
            </Dialog>

            {(anagrafica.code||palestraassociata.id) && 
            <IconButton sx ={{color:'white'}} aria-label="delete" onClick={() => setqrcodeVisible(true)}>
            <QrCode />
          </IconButton>
                }
              </>
          );
}