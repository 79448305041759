import React from "react";
import PeriodiSelect from "../periodo/PeriodiSelect";
import TipoAbbonamentoSelect from "../tipoabbonamento/TipoAbbonamentoSelect";
import "./RiquadroRicercaAnag.css"
import RiquadroRisRicAnagrafiche from "./RiquadroRisRicAnagrafiche";
import axios from 'axios';
import LoadingGif from "../util/LoadingGif";
import TipiPagamentoSelect from "../pagamenti/TipiPagamentoSelect";
import { useState } from "react";
import "../util/config";
import { useSelector, useDispatch } from 'react-redux';
import { setanagricercate } from "../STORE/reducers/gestoreUtente";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { navigate } from 'hookrouter'
import '../index.css'
import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from "@mui/material";
import InputText from "../util/UI/InputText";
import SearchIcon from '@mui/icons-material/Search';

var timeout = 3000
var inputnome = ''
var inputcognome = ''
export default function RiquadroRicAnagrafiche(props) {
    let istesserato = 0;
    const palestraanagrafica = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagricercate = useSelector(state => state.gestoreUtente.anagricercate);

    const dispatch = useDispatch();
    const [nome, setnome] = useState('');
    const [cognome, setcognome] = useState('');
    const [codicefiscale, setcodicefiscale] = useState('');
    const [emaildacercare, setemaildacercare] = useState('');
    const [valide, setvalide] = useState('');
    const [saldati, setsaldati] = useState(0);
    const [tipoabbonamento, settipoabbonamento] = useState('');
    const [tipipagamento, settipipagamento] = useState('');
    const [periodo, setperiodo] = useState('');
    const [nomecognome, setnomecognome] = useState('');
    const [loading, setloading] = useState('');
    const [keytorefresh, setkeytorefresh] = useState(new Date());
    const [anagrafiche, setanagrafiche] = useState(null)
    //const [istesserato, setistesserato] = useState(0);
    const [dataDa, setdatada] = useState(' ');
    const [dataA, setdataA] = useState(' ');
    const [effettuatoDa, seteffettuatoDa] = useState(' ');
    const [effettuatoA, seteffettuatoA] = useState(" ")
    const [mostraAbbonamenti, setmostraAbbonamenti] = useState(false);
    const [advanced, setadvanced] = useState(false)
    const [tesserasanitaria, settesserasanitaria] = useState('');

    function handleSubmit(event) {
        event.preventDefault();
        setloading(true);
        setanagrafiche([])

        if (event.detail === 1 && palestraanagrafica.id === 1) {
            istesserato = 1;
        }

        if (event.detail === 2 || palestraanagrafica.id !== 1) {
            istesserato = 0;
        }
        cerca()

    }

    function testalo(inputcodfisc) {
        let codfisc = inputcodfisc.substring(1, 17)
        //console.log('codfisc '+codfisc)
        setcodicefiscale(codfisc)

        // let valuetemp=inputcodfisc.split('?')
        //  //console.log('prima parte cod fisc '+valuetemp[0].substring(1,17))
        //  //console.log('seconda parte nome cognome '+valuetemp[0].substring(17))
        //  let nomecognometemp=valuetemp[0].substring(17).split(' ');
        //  nomecognometemp=nomecognometemp.filter(nc => nc!=='')
        //  if (nomecognometemp.length===2){
        //           setcognome(nomecognometemp[0])
        //           setnome(nomecognometemp[1])
        //       }
        //       else if (nomecognometemp.length>2){
        //           setcognome(nomecognometemp[0]+" "+nomecognometemp[1])
        //           let nometemp=''
        //           for (let i=2; i<nomecognometemp.length; i++){
        //             nometemp+=nomecognometemp[i]
        //             if (i<nomecognometemp.length-1){
        //                 nometemp+=' '
        //             }
        //           }
        //           setnome(nometemp)
        //       }
        //       else if (nomecognometemp.length===1){
        //           setcognome(nomecognometemp[0])
        //           setnome('')
        // }
        setTimeout(() => {
            setnomecognome('')
            settesserasanitaria('')
            cerca()
        }, 1000);
    }

    function splitdati(value) {
        if (value.indexOf('%') === 0 && value.length >= 30) {
            clearTimeout(timeout);
            let inputcodfisc = value
            inputcodfisc = inputcodfisc.replace('. ', '')
            inputcodfisc = inputcodfisc.replace('\n', ' ')
            inputcodfisc = inputcodfisc.replace('\r', ' ')
            testalo(inputcodfisc)
            //timeout = setTimeout(testalo, 1000);
        }
    }

    function cerca() {
        const formData = new FormData();
        formData.append('website', 'annotatio.online');
        formData.append('email', email);
        formData.append('lic', '000');
        formData.append('palestra', palestraanagrafica.id);
        formData.append('nomecognome', nomecognome ? nomecognome : '');
        formData.append('nome', nome ? nome : '');
        formData.append('cognome', cognome ? cognome : '');
        formData.append('codfisc', codicefiscale ? codicefiscale : '');
        formData.append('valide', valide ? valide : '');
        formData.append('saldati', saldati ? saldati : '');
        formData.append('idTipoAbbonamento', tipoabbonamento ? tipoabbonamento : '');
        formData.append('idPeriodo', periodo ? periodo : '');

        formData.append('dataDa', dataDa.trim() ? dataDa.trim() : '');
        formData.append('dataA', dataA.trim() ? dataA.trim() : '');
        formData.append('effettuatoDa', effettuatoDa.trim() ? effettuatoDa.trim() : '');
        formData.append('effettuatoA', effettuatoA.trim() ? effettuatoA.trim() : '');
        formData.append('mostraAbbonamenti', mostraAbbonamenti ? mostraAbbonamenti : '');
        formData.append('tipipagamento', tipipagamento ? tipipagamento : '');
        formData.append('emaildacercare', emaildacercare ? emaildacercare : '');

        formData.append('istesserato', istesserato);
        axios.post(global.config.server + '/cercaAnagrafiche2', formData,
            {
                withCredentials: true,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST',
                    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + window.$t
                },
            },)
            .then((result) => {
                //console.log(result.data)
                setanagrafiche(result.data)
                dispatch(setanagricercate(result.data))
                setloading(false)
                setkeytorefresh(new Date())
            })
    }

    return (
        <Grid item container xs={12}>
            <Grid item container xs={12} sx={{ display: 'flex' }}>
                <h5 >Ricerca anagrafiche</h5>
                <Button onClick={() => navigate('/annotatio-newanag/')}>
                    <PersonAddIcon />
                </Button>
            </Grid>
            <Grid item container xs={12}>
                <Container>
                    <Paper elevation={5} sx={{ display: { md: 'flex', xs: 'auto' }, margin: 2, padding: 2 }}>
                        
                        <Grid item container xs={12} md={4} margin={1}>
                            <InputText
                                label="Cerca per nome o cognome"
                                type="text"
                                id="form-field-name"
                               
                                name="nomecognome" value={nomecognome} placeholder="Ricerca veloce per nome e/o cognome" onChange={(e) => { setnomecognome(e.target.value); }}
                                handleSubmit={handleSubmit} />
                            <InputText label="cerca per codice fiscale"
                                type="text"
                                id="form-field-name"
                                handleSubmit={handleSubmit}
                                name="nomecognome" value={codicefiscale} placeholder="Codice Fiscale" onChange={(e) => { setcodicefiscale(e.target.value); clearTimeout(timeout); timeout = setTimeout(splitdati(e.target.value), 2000); }} />
                        </Grid>
                        <Grid item container xs={12} md={4} margin={1}>
                            <FormControl fullWidth={true} variant="outlined">
                                <InputLabel>Tipo di iscrizione</InputLabel>
                                <Select fullWidth variant="standard"
                                    label="tipo di iscrizione"
                                    id="form-field-name"
                                    name="valide" placeholder="Iscrizioni valide" value={valide} onChange={(e) => setvalide(e.target.value)}>
                                    <MenuItem value="">--Tutte le iscrizioni--</MenuItem>
                                    <MenuItem value="iscrizioniOggi">Oggi</MenuItem>
                                    <MenuItem value="iscrizioniMese">In un mese</MenuItem>
                                    <MenuItem value="iscrizioniAnno">In un anno</MenuItem>
                                    <MenuItem value="valide">In corso</MenuItem>
                                    <MenuItem value="scadute">Scadute</MenuItem>
                                    <MenuItem value="scadenza7">Scadenza in 7 giorni</MenuItem>
                                    <MenuItem value="scadenza30">Scadenza in 30 giorni</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} md={4} margin={1}>
                            <Button variant="contained" onClick={handleSubmit}>
                                <SearchIcon />
                            </Button>
                        </Grid>
                    </Paper>
                </Container>
            </Grid>
            <Button onClick={()=> setadvanced(!advanced)}>
                    Ricerca avanzata <i className="fa fa-angle-down"></i>
                </Button>
            <Grid item container xs={12}>
                
                {advanced&&<Container>
                    <Paper elevation={5} sx={{ display: { md: 'flex', xs: 'auto' }, margin: 2, padding: 2 }}>
                        <Grid item container xs={12}>
                            <Grid item container xs={12} md={4}>
                                <InputText label="Cerca per email" type="email"
                                    handleSubmit={handleSubmit}
                                    name="emaildaceercare" value={emaildacercare} placeholder="Cerca una email" onChange={(e) => setemaildacercare(e.target.value)}
                                />
                            </Grid>
                            <Grid item container xs={12} md={4}>
                                <InputText label="cerca per nome" type="text"
                                    handleSubmit={handleSubmit}
                                    name="nome" value={nome} placeholder="Nome" onChange={(e) => setnome(e.target.value)}
                                />
                            </Grid>
                            <Grid item container xs={12} md={4}>
                                <InputText label="cerca per cognome" type="text"
                                    handleSubmit={handleSubmit}
                                    name="cognome" value={cognome} placeholder="Cognome" onChange={(e) => setcognome(e.target.value)} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>}
            </Grid>

            {advanced&&<Grid item container xs={12}>
                <Container>
                    <Paper elevation={5} sx={{  margin: 2, padding: 2 }}>
                    <Grid item container xs={12}>
                        <Grid item container xs={6} md={3}>

                            <InputText label="Abbonamento valido da" type="date"
                               placeholder=""
                               handleSubmit={handleSubmit}
                                name="dataDa" value={dataDa}
                                onChange={(e) => setdatada(e.target.value)} />

                        </Grid>
                        <Grid item container xs={6} md={3}>

                            <InputText label="Abbonamento valido a" type="date"
                                handleSubmit={handleSubmit}
                                name="dataA" value={dataA} placeholder="Fine validita"
                                onChange={(e) => setdataA(e.target.value)} />

                        </Grid>
                        <Grid item container xs={6} md={3}>

                            <InputText label="Abbonamento effettuato dal" type="date"
                                handleSubmit={handleSubmit}
                                name="effettuatoDa" value={effettuatoDa} placeholder="Effettuato dal"
                                onChange={(e) => seteffettuatoDa(e.target.value)} />

                        </Grid>
                        <Grid item container xs={6} md={3}>

                            <InputText label="Abbonamento effettuato entro il" type="date"
                                handleSubmit={handleSubmit}
                                name="effettuatoA" value={effettuatoA} placeholder="Effettuato entro il"
                                onChange={(e) => seteffettuatoA(e.target.value)} />

                        </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                        <Grid item container xs={6} md={3}>
                            <select id="form-field-email"
                                className="form-control"
                                name="saldati" placeholder="Pagamenti saldati" value={saldati} onChange={(e) => setsaldati(e.target.value)}>
                                <option value="0">--Tutti i pagamenti--</option>
                                <option value="1">Saldati</option>
                                <option value="2">Da saldare</option>
                            </select>
                        </Grid>
                        <Grid item container xs={6} md={3}>
                            <TipoAbbonamentoSelect selected={tipoabbonamento} update={(e) => settipoabbonamento(e.target.value)} />
                        </Grid>

                        <Grid item container xs={6} md={3}>
                            <PeriodiSelect selected={periodo} update={(e) => setperiodo(e.target.value)} />
                        </Grid>
                        <Grid item container xs={6} md={3}>
                            <TipiPagamentoSelect selected={tipipagamento} update={(e) => settipipagamento(e.target.value)} />
                        </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Grid>}

            {
                loading ?
                    <div style={{
                        margin: 'auto',
                        width: '50%'
                    }
                    } >
                        <LoadingGif />
                    </div >
                    : null
            }
            <RiquadroRisRicAnagrafiche key={keytorefresh} email={email} idpalestra={palestraanagrafica.id} anagrafiche={anagrafiche ? anagrafiche : anagricercate} link={props.link} setview={props.setview} childrenKey={keytorefresh} />
        </Grid >
    );
}