import React, { useEffect, useState } from "react";
import '../../util/config'
import { getAllPlaces } from "../../DAO/tipiprodottiDAO";
import { useSelector } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import SelectText from "../../util/UI/SelectText";

export default function LuogoSelect(props,{label}) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);

	const [places, setplaces] = useState([]);
 
	 const[show, setshow] = useState(false);
	 const [selected, setselected] = useState(props.selected);
 
	 function caricaplaces(){
        async function carica(){
          const result = await getAllPlaces(palestra.palestra.id);
          //console.log("get all,places ",result);
          setplaces(result);
          if (result.length===1){
            setselected(result[0].id);
            props.setselected(result[0].id);
        }
          
        }
        if (!places || places.length===0){
          carica();
        }
        
    }

    useEffect(() => {
      caricaplaces();
        if (places.length===1){

          setselected(places[0].id);
          props.setselected(places[0].id);
      }
    },[]);
	
	    return (
        <FormControl fullWidth={true} variant="standard">
        <InputLabel>Luogo</InputLabel>
       <Select
       fullWidth={true}
       size="small"
      value={selected} 
      onChange={(e)=>{setselected(e.target.value); props.setselected(e.target.value)}} >
               {places&&Array.isArray(places)&&places.length>0&&
               places.map(place => (
                   <MenuItem key={place.id} value={place.title}>{place.title}</MenuItem>
               ))}
           </Select>
           <TextField variant="standard" placeholder="Oppure inseriscine uno nuovo" name="luogo" onChange={(e)=>{setselected(e.target.value);props.setselected(e.target.value)}} />
   </FormControl>
        

	    );
	}