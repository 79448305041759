import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import WidgetsIcon from '@mui/icons-material/Widgets';

import Menu from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import {navigate} from 'hookrouter'
import { useSelector } from 'react-redux';

export default function Laterale() {
  const palestraassociata= useSelector(state => state.gestoreUtente.palestraassociata);
  const menuadminlaterale = useSelector(state => state.gestoreMenu.menuadminlaterale);

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // function isModuloIncluso(modulo){
  //   if (palestraassociata
  //     &&palestraassociata.palestra
  //     &&palestraassociata.palestra.moduli
  //     &&palestraassociata.palestra.moduli.includes(modulo)){
  //       return true;
  //     }
  //     return false
  // }

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
      {menuadminlaterale && Array.isArray(menuadminlaterale) && menuadminlaterale.length > 0 &&
          menuadminlaterale.map(menuitem =>
              <ListItem key={menuitem.id} disablePadding
          onClick={()=> navigate(menuitem.link)}>
            <ListItemButton>
              <ListItemIcon>
               <i className={menuitem.faiconclass} />
              </ListItemIcon>
              <ListItemText primary={menuitem.nome} />
            </ListItemButton>
          </ListItem>
          )

        }
      {/* {isModuloIncluso('CASSA')&&
          <ListItem key={"cassa"} disablePadding
          onClick={()=> navigate('/annotatio-cassa/')}>
            <ListItemButton>
              <ListItemIcon>
                <Euro />
              </ListItemIcon>
              <ListItemText primary={"Cassa"} />
            </ListItemButton>
          </ListItem>}

          {isModuloIncluso('CONTROLLOACCESSI')&&
          <ListItem key={"accessi"} disablePadding
          onClick={()=> navigate('/annotatio-accessi/')}>
            <ListItemButton>
              <ListItemIcon>
                <MeetingRoomIcon />
              </ListItemIcon>
              <ListItemText primary={"Controllo accessi"} />
            </ListItemButton>
          </ListItem>}
          {isModuloIncluso('ARCHIVI')&&
          <ListItem key={"arch"} disablePadding
          onClick={()=> navigate('/annotatio-arch/')}>
            <ListItemButton>
              <ListItemIcon>
                <TuneIcon />
              </ListItemIcon>
              <ListItemText primary={"Impostazioni"} />
            </ListItemButton>
          </ListItem>} */}
      </List>
      <Divider />
      <List>
          <ListItem key={"gestoremenu"} disablePadding onClick={()=> navigate('/annotatio-menu/')}>
            <ListItemButton>
              <ListItemIcon>
                <WidgetsIcon />
              </ListItemIcon>
              <ListItemText primary={"Gestione del menu"} />
            </ListItemButton>
          </ListItem>
      </List>
      <Divider />
      <List>
          <ListItem key={"cancellati"} disablePadding >
            <ListItemButton>
              <ListItemIcon>
                <PersonRemoveIcon />
              </ListItemIcon>
              <ListItemText primary={"Cancella i tuoi dati"}/>
            </ListItemButton>
          </ListItem>
          </List>
    </Box>
  );

  return (
    <>
    <IconButton color='white' aria-label="delete" onClick={toggleDrawer(true)}>
  <Menu sx={{color:'white'}}/>
</IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </>
  );
}