import React from "react";
import '../../util/config'
import { Box, Button, FormControl, Grid, Input, InputLabel, MenuItem, Select } from "@mui/material";

export default class PeriodoSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    		selezionato:this.props.selected
    };
    this.aggiornaSelezionato = this.aggiornaSelezionato.bind(this);
  }

	componentDidMount(){

	}
  
	aggiornaSelezionato(e){
		 this.props.updatePeriodo(this.props.listaPeriodi[e.target.selectedIndex-1].id);
	 }

  render() {
    return (
    		
		<FormControl fullWidth={true} variant="standard">
        <InputLabel>Periodi</InputLabel>
				<Select onChange={this.aggiornaSelezionato} name="periodo" value={this.props.selected?this.props.selected:""}>
					{
						this.props.listaPeriodi&&Array.isArray(this.props.listaPeriodi)&&
						this.props.listaPeriodi.length>0&&
						 this.props.listaPeriodi.map((item,index) =>(
						<MenuItem key={index} value={item.id} >{item.descrizione}</MenuItem>
						)
		    			)
		    		}
				</Select>

		</FormControl>
    );
  }
}