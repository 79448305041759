import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import { navigate } from 'hookrouter'
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

export default function Ingressi(props) {
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
  const email = useSelector(state => state.gestoreUtente.emailloggato);
  const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata)

  const [ingressi, setingressi] = useState([])


  useEffect(() => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('website', palestra.website);
    formData.append('lic', '000');
    formData.append('palestra', palestra.id);
    formData.append('id', idanagselezionata);
    axios.post(global.config.server + "/getIngressi", formData)
      .then(response => {
        console.log("Ingressi: ")
        console.log(response.data)
        setingressi(response.data);
      })
      .catch(err => {
        if (err.response) {
          alert("Ingressi: " + err.response.data.message);
        }
        else {
          alert("Ingressi: " + err.response);
        }
      });
  }, [idanagselezionata])


  return (
    <Grid item container xs={12}>
      <Grid item container xs={12}>
        <h4>Ingressi</h4>
      </Grid>
              {ingressi && Array.isArray(ingressi) && ingressi.length > 0 ?
                ingressi.map(ingr => (
                  <Grid item container xs={12} padding={1}>
                        <Typography> {ingr.data} </Typography>
                        </Grid>
                   
                ))
                : "Nessun ingresso trovato"}
            
    </Grid>
  );
}