import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function SelectTipologiaCorso({selected, updateTipo}){

	const tipicorso = useSelector(state => state.gestoreTipiProdotto.tipi);

	function aggiornaSelezionato(e){
		
		if ((e.target.value)===''){
			updateTipo(null)
		}
		else{
		 updateTipo(tipicorso[e.target.selectedIndex-1]);
		}
	 }

	 useEffect(() =>{
		console.log(tipicorso,'tipicorso')
		console.log(selected)
	 })

	    return (
				<FormControl fullWidth={true} variant="standard">
             <InputLabel>Tipologia</InputLabel>
            <Select 
            fullWidth={true}
            size="small"
            value={selected?selected.id:''} onChange={aggiornaSelezionato}>
                   
                    {tipicorso&&Array.isArray(tipicorso)&&tipicorso.length>0&&
                    tipicorso.map((item,index) =>(
                        <MenuItem key={index} value={item.id}>{item.nome}</MenuItem>
                    ))}
                </Select>
        </FormControl>
	    );
	}