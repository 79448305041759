import React from "react";
import axios from 'axios';
import '../../util/config'
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import InputText from "../../util/UI/InputText";
import { Button, FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import Save from "@mui/icons-material/Save";

var timer=0
export default function TipoAbbTipoCorsoInput({corsi, idtipoabb, tipocorso}) {
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
  const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [descrCorso, setdescrCorso] = useState("")
  const [free, setfree] = useState(0)
  const [valore, setvalore] = useState(0)

  function update() {
    
    var that=this
    clearTimeout(this.timer)
    timer=setTimeout(function() {
            const formData = new FormData();
            formData.append('idtipoabb', idtipoabb);
            formData.append('idtipocorso', tipocorso.id);
            formData.append('free', free?1:0);
            formData.append('valore', valore);
            formData.append('website', global.config.domain);
            formData.append('email',email);
          formData.append('lic','000');
		  formData.append('palestra',palestra.id);
		        axios.post(global.config.server+'/aggiornaTipoAbbTipoCorsoRest',formData)
            .then((result) => {
                
                that.setState({messaggio:result.data});
                  const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                      });
                      Toast.fire({
                        type: 'success',
                        title: result.data
                      })
            })
        },1000)
	}
  
	useEffect(() =>{
          var trovato=false;
          if (corsi&&corsi.length>0){
            //console.log("corsi")
            //console.log(corsi)
            for (var i = 0; i < corsi.length; i++) {
                if (corsi[i].idtipocorso===tipocorso.id){
                  //console.log("trovato: ")
                  //console.log(corsi[i])
                    setdescrCorso(corsi[i].descrTipoCorso)
                    setvalore(corsi[i].numingressisett)
                    setfree(corsi[i].free?1:0)
                    trovato=true;
                    break;
                }
            }
          }
          if(!trovato){
            setdescrCorso(tipocorso.nome)
            setvalore(0)
          }
 
    },[idtipoabb, tipocorso])


    return (
      <Grid item container xs={12}>
        <Grid item container xs={4}>
          <InputText label={"Ingressi "+descrCorso+" sett."} type="number" name="valore" align="right" 
          onChange={(e) => setvalore(e.target.value)}  value={valore} required="required"  />
      </Grid>
      <Grid item container xs={4}>
      <FormGroup>
        <FormControlLabel control={<Switch defaultChecked={false} checked={free===1?true:false} onChange={(e) => e.target.checked?setfree(1):setfree(0)} value={free===1?1:0}  />} label="Free" />
    </FormGroup>

</Grid>
      <Grid item container xs={4}>
          <Button onClick={() => update()}>
            <Save />
          </Button>
          </Grid>
    </Grid>
    )
}