import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import { useSelector } from "react-redux";
import { Accordion, Badge, Card } from "react-bootstrap";
import Ricevute from "./Ricevute";

export default function AccordionRicevute({ idabbonamento, }) {

    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    //const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata);

    const [ricevute, setricevute] = useState(0)

    const [keytorefresh, setkeytorefresh] = useState()

    useEffect(() => {

        const formData = new FormData();
        formData.append('idabbonamento', idabbonamento);
        formData.append('website', global.config.domain);
        formData.append('email', email);
        formData.append('lic', '000');
        formData.append('palestra', palestra.palestra.id);
        axios.post(global.config.server + "/getRicevute", formData)
            .then(response => {
                setricevute(response.data);
                //console.log(response);
            }).catch(err => {
                console.log(err)
                if (err.response) {
                    alert("Ricevute: 1 " + err.response.data.message);
                }
                else {
                    alert("Ricevute: ???");
                }
            })
    }, [keytorefresh])

    return (
        <Accordion key={keytorefresh}>
            <Card >
                <Accordion.Toggle as={Card.Header} eventKey={"ric" + idabbonamento}>
                    Ricevute {ricevute
                        && Array.isArray(ricevute)
                        && ricevute.length > 0 ?
                        <Badge variant="primary">({ricevute.length})</Badge>
                        :
                        <Badge variant="info">
                            Nessuna ricevuta
                        </Badge>
                    }
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={"ric" + idabbonamento}>
                    <Card.Body>
                        <Ricevute
                            idabbonamento={idabbonamento}
                            ricevuteinput={ricevute}
                            refresh={() => setkeytorefresh(new Date().getTime())}
                        />
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}