import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cercafatture, getNumeroFattura, inseriscifattura } from "../DAO/fattureDAO";
import NuovaRiga from "./NuovaRiga";
import { getcorsi } from "../DAO/corsiDAO";
import { getlistini } from "../DAO/listiniDAO";
import { calcolocodfisc, cercaAnagrafiche2, getAnagrafica, cercacap, cercacomune } from "../DAO/anagraficaDAO";
import SearchIcon from '@mui/icons-material/Search';
import { comuneCf, dataCf, sessoCf } from "../util/calcolocodfisc";
import { setidfattura } from "../STORE/reducers/gestoreFattura";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEuroSign,  faPlusSquare, faUserAlt, faAddressCard, faFileInvoice, faSearch } from '@fortawesome/free-solid-svg-icons'
import { navigate,} from 'hookrouter'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { calcolaTotaleLordo } from "../util/nutrizionista/calcoli";
import { Button, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import InputText from "../util/UI/InputText";
import BadgeIcon from '@mui/icons-material/Badge';

import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import LocationOn from "@mui/icons-material/LocationOn";
import LocalPhone from "@mui/icons-material/LocalPhone";
import AlternateEmail from "@mui/icons-material/AlternateEmail";
import LocationCity from "@mui/icons-material/LocationCity";

var timeout = 0
const theadstyle={backgroundColor:'black', color:'white', fontWeight:'bold', textAlign:'center'}

export default function InsFattura(props) {


    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const dispatch = useDispatch();

    const [nome, setnome] = useState('')
    const [indirizzo, setindirizzo] = useState('')
    const [citta, setcitta] = useState('')
    const [cap, setcap] = useState('')
    const [cognome, setcognome] = useState('')
    const [codicefiscale, setcodicefiscale] = useState('')
    const [numero, setnumero] = useState('')
    const [data, setdata] = useState(new Date().getFullYear() + "-" + (("0" + (new Date().getMonth() + 1)).slice(-2)) + "-" + ("0" + new Date().getDate()).slice(-2))
    const [anno, setanno] = useState(new Date().getFullYear())
    const [iva, setiva] = useState(0);
    const [importoiva, setimportoiva] = useState(0);

    const [totlordo, settotlordo] = useState(0);
    const [prodotti, setprodotti] = useState([])
    const [listini, setlistini] = useState([])
    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime());
    const [reversecharge, setreversecharge] = useState(0);
    const [tesserasanitaria, settesserasanitaria] = useState('')
    const [datanascita, setdatanascita] = useState('01/01/1900')
    const [luogonascita, setluogonascita] = useState('')
    const [sesso, setsesso] = useState('')
    const [idanag, setidnang] = useState(0)
    const [messaggio, setmessaggio] = useState('')
    const [telefono, settelefono] = useState('')
    const [emailanag, setemailanag] = useState('')

    const [datapag, setdatapag] = useState(new Date().getFullYear() + "-" + (("0" + (new Date().getMonth() + 1)).slice(-2)) + "-" + ("0" + new Date().getDate()).slice(-2))
    const [importopag, setimportopag] = useState(0);
    const [tipopag, settipopag] = useState('')
    const [nomecognomecodfisc, setnomecognomecodfisc] = useState('')
    const [anagrafiche, setanagrafiche] = useState([])
    const [loading, setloading] = useState(false)
    const [showcomunipernascita, setshowcomunipernascita] = useState(false);
    const [showcomunipercitta, setshowcomunipercitta] = useState(false)
    const [showcomunipercap, setshowcomunipercap] = useState(false)
    const [righe, setrighe] = useState([{
        idrigafattura: '_' + Math.random().toString(36).substr(2, 9),
        quantita: 1,
        costo: 0,
        descrizione: "",
        aliquotaiva: 0,
        fenatura: '',
        idcorso: 0
    }]);

    const [comuni, setcomuni] = useState([])

    function cercaanagbyid(idanag) {

        console.log('cercaanagbyid ' + idanag)
        async function cercanaag() {
            const result = await getAnagrafica(email, '000', palestra.palestra.id, idanag)
            if (result) {
                setmessaggio('Anagrafica trovata')
                setidnang(result.id)
                setcognome(result.cognome)
                setnome(result.nome)
                setdatanascita(result.datanascita)
                setsesso(result.sesso)
                setluogonascita(result.luogonascita)
                setindirizzo(result.indirizzo)
                setcap(result.cap)
                setcitta(result.citta)
                settelefono(result.telefono)
                setemailanag(result.email)
                setcodicefiscale(result.codicefiscale)
            }
        }
        cercanaag();
    }

    function ricercaanagrafica() {
        async function cercaanag() {
            try {
                const result = await cercaAnagrafiche2(email, palestra.palestra.id, nomecognomecodfisc ? nomecognomecodfisc : '', '', '', '', false, false, '', '', '', '', '', '', false, 0);
                console.log(result)
                setanagrafiche(result)
            }
            catch (error) {
                console.log(error)
            }
        }
        if (nomecognomecodfisc) {
            cercaanag();
        }
    }

    function calcolacf() {
        async function calccf() {
            const cf = await calcolocodfisc(nome, cognome, sesso, datanascita, luogonascita)
            setcodicefiscale(cf)
        }
        calccf()
    }

    function calcolacomune(codfisc) {
        async function calccf() {
            const comunetemp = await comuneCf(codfisc)
            setluogonascita(comunetemp)
        }
        calccf()
    }

    function cercacomuni(comune) {
        clearTimeout(timeout);
        async function cerca() {
            const comunitemp = await cercacomune(comune)
            setcomuni(comunitemp)
        }
        timeout = setTimeout(() => cerca(), 2000)
    }

    function cercacaps(cap) {
        clearTimeout(timeout);
        async function cercacapi() {
            const comunitemp = await cercacap(cap)
            console.log(comunitemp)
            setcomuni(comunitemp)
        }
        timeout = setTimeout(() => cercacapi(), 2000)
    }


    function testalo(inputcodfisc) {
        if (!inputcodfisc || inputcodfisc.trim() === '') {
            return
        }
        let codfisc = inputcodfisc;
        if (inputcodfisc.indexOf('%') === 0) {
            codfisc = inputcodfisc.substring(1, 17)
        }

        console.log('codfisc ' + codfisc)
        setcodicefiscale(codfisc.toUpperCase())
        async function cercanaag() {
            const result = await cercafatture('', '', '', codfisc, '', '', '', palestra.palestra.id, email);
            console.log(result)
            if (result && Array.isArray(result) && result.length > 0 && result[0].idanag) {
                setmessaggio('testalo Anagrafica trovata')
                setidnang(result[0].id)
                setcognome(result[0].cognome)
                setnome(result[0].nome)
                setdatanascita(result[0].datanascita)
                setsesso(result[0].sesso)
                setluogonascita(result[0].luogonascita)
                setindirizzo(result[0].indirizzo)
                setcap(result[0].cap)
                setcitta(result[0].citta)
                settelefono(result[0].telefono)
                setemailanag(result[0].email)
                setnomecognomecodfisc('')
                setcodicefiscale(result[0].codicefiscale.toUpperCase())
            }
            else {
                setmessaggio('Nuova anagrafica')
                console.log('Nuova anagrafica con cofice fisacle: ' + codfisc)

                var sextemp = sessoCf(codfisc)
                setsesso(sextemp ? sextemp : '');
                var datanasctemp = dataCf(codfisc)
                setdatanascita(datanasctemp ? datanasctemp : '');
                calcolacomune(codfisc)
                setnome('')
                setcognome('')
                setindirizzo('')
                setcitta('')
                setcap('')
                settelefono('')
                setemailanag('')
                let valuetemp = [];
                if (inputcodfisc.indexOf('?') > 0) {
                    valuetemp = inputcodfisc.split('?')
                }
                else if (inputcodfisc.indexOf('_') > 0) {
                    valuetemp = inputcodfisc.split('_')
                }
                //console.log('prima parte cod fisc '+valuetemp[0].substring(1,17))
                //console.log('seconda parte nome cognome '+valuetemp[0].substring(17))
                if (valuetemp && Array.isArray(valuetemp) && valuetemp.length > 0) {
                    let nomecognometemp = valuetemp[0].substring(17).replace('\\n', ' ').replace('\\r', ' ').split(' ');

                    nomecognometemp = nomecognometemp.filter(nc => nc !== '')
                    if (nomecognometemp.length === 2) {
                        console.log('1 ', nomecognometemp[0], ' - ', nomecognometemp[1])
                        setcognome(nomecognometemp[0])
                        setnome(nomecognometemp[1])
                    }
                    else if (nomecognometemp.length > 2) {
                        console.log('2 ', nomecognometemp[0], ' - ', nomecognometemp[1])
                        setcognome(nomecognometemp[0] + " " + nomecognometemp[1])
                        let nometemp = ''
                        for (let i = 2; i < nomecognometemp.length; i++) {
                            nometemp += nomecognometemp[i]
                            if (i < nomecognometemp.length - 1) {
                                nometemp += ' '
                            }
                        }
                        setnome(nometemp)
                    }
                    else if (nomecognometemp.length === 1) {
                        setcognome(nomecognometemp[0])
                        setnome('')
                    }
                }
                setnomecognomecodfisc('')
            }
        }
        cercanaag()
        setTimeout(() => {
            settesserasanitaria('')
        }, 1000);

    }

    function splitdati(value) {
        if (value.indexOf('%') === 0 && value.length >= 30) {
            clearTimeout(timeout);
            let inputcodfisc = value
            inputcodfisc = inputcodfisc.replace('. ', '')
            inputcodfisc = inputcodfisc.replace('\n', ' ')
            inputcodfisc = inputcodfisc.replace('\r', ' ')
            testalo(inputcodfisc)
            //timeout = setTimeout(testalo, 1000);
        }
    }

    function insfatt() {
        async function insfattura() {
            try {
                if (!nome) {
                    alert('Nome obbligatorio')
                    return;
                }
                if (!cognome) {
                    alert('Cognome obbligatorio')
                    return;
                }
                if (!codicefiscale) {
                    alert('Codice fiscale obbligatorio')
                    return;
                }
                if (!indirizzo) {
                    alert('Indirizzo obbligatorio')
                    return;
                }
                if (!citta) {
                    alert('Citta obbligatoria')
                    return;
                }
                if (!cap) {
                    alert('CAP obbligatorio')
                    return;
                }
                if (!telefono) {
                    alert('Telefono obbligatorio')
                    return;
                }
                if (!numero) {
                    alert('numero fattura obbligatorio')
                    return;
                }
                if (!anno) {
                    alert('Anno fattura obbligatorio')
                    return;
                }
                if (!data) {
                    alert('Data fattura obbligatoria')
                    return;
                }
                if (!righe || righe.length === 0) {
                    alert('Inserire almeno una riga di dettaglio');
                    return;
                }
                for (let i = 0; i < righe.length; i++) {
                    if (!righe[i].descrizione) {
                        alert('Indicare una descrizione alla riga ' + (i + 1))
                        return;
                    }
                }
                if (!datapag || !importopag || importopag === 0 || !tipopag) {
                    if (window.confirm('Dati di pagamento mancanti, Continuare comunque?')) {

                    }
                    else {
                        return;
                    }
                }
                let codfisctemp = await calcolocodfisc(nome, cognome, sesso, datanascita, luogonascita);
                console.log('codice fiscale calcolato: ' + codfisctemp)

                if (codfisctemp !== codicefiscale) {
                    if (!window.confirm('Codice fiscale calcolato (' + codfisctemp + ') differisce dal codice fiscale inserito (' + codicefiscale + '). Continuare?'))
                        return
                }
                setloading(true)
                const result = await inseriscifattura(
                    nome, cognome, codicefiscale, indirizzo, citta, cap, sesso, datanascita, luogonascita, telefono, emailanag,
                    numero, anno, data, iva, righe,
                    reversecharge,
                    datapag, importopag, tipopag,
                    palestra.palestra.id, email);
                if (result && result.id) {
                    dispatch(setidfattura(result.id))
                    alert("Fattura inserita correttamente")
                    navigate('/annotatio-dett-fatt/' + result.id)
                }
                else {
                    alert("Ci sono stati problemi durante l'inserimento della fattura")
                }
                setloading(false)
            }
            catch (error) {
                console.log(error)
            }
        }
        insfattura();
    }

    function aggiornarigadaprodotti(idprodotto, riga) {
        console.log('aggiornarigadaprodotti ', idprodotto, riga)
        let prodottotemp = { prezzo: 0, descrizione: '' }
        if (idprodotto > 0) {
            prodottotemp = prodotti.filter(prod => prod.id === Number(idprodotto))[0]
            console.log(prodottotemp)
        }
        let rigatemp = {
            idrigafattura: riga.idrigafattura,
            quantita: riga.quantita,
            costo: prodottotemp.prezzo,
            descrizione: prodottotemp.descrizione,
            aliquotaiva: prodottotemp.aliquotaiva ? prodottotemp.aliquotaiva : iva,
            fenatura: prodottotemp.fenatura ? prodottotemp.fenatura : '',
            idcorso: prodottotemp.id
        }
        console.log(rigatemp)
        let listarighetemp = righe.map(rigat => rigat.idrigafattura !== riga.idrigafattura ? rigat : rigatemp)
        setrighe(listarighetemp)
        calcolaTotaleLordo(listarighetemp, palestra.palestra.id, setimportoiva, settotlordo, setimportopag);
    }

    function aggiornarigadalistini(idlistino, riga) {
        console.log('aggiornarigadalistini ', idlistino, riga)
        let listinotemp = { prezzo: 0, descrizione: '' }
        if (idlistino > 0) {
            listinotemp = listini.filter(listi => listi.id === Number(idlistino))[0]
            console.log(listinotemp)
        }
        let rigatemp = {
            idrigafattura: riga.idrigafattura,
            quantita: riga.quantita,
            costo: listinotemp.prezzo,
            descrizione: listinotemp.descrizione,
            aliquotaiva: iva,
            fenatura: '',
            idcorso: listinotemp.id
        }
        console.log(rigatemp)
        let listarighetemp = righe.map(rigat => rigat.idrigafattura !== riga.idrigafattura ? rigat : rigatemp)
        setrighe(listarighetemp)
        calcolaTotaleLordo(listarighetemp, palestra.palestra.id, setimportoiva, settotlordo, setimportopag);
    }

    function updateRiga(e, idriga) {
        var righetemp = righe;
        const index = righetemp.findIndex(rigat => rigat.idrigafattura === idriga);
        righetemp[index][e.target.name] = e.target.value
        console.log(e.target.name + ' - ' + e.target.value)
        setrighe(righetemp);
        calcolaTotaleLordo(righetemp, palestra.palestra.id, setimportoiva, settotlordo, setimportopag);
        //setkeytorefresh(new Date().getTime());
    }



    function aggiungiRiga() {
        //var righetemp=righe;
        var nuovariga = { idrigafattura: '_' + Math.random().toString(36).substr(2, 9), quantita: 1, costo: 0, descrizione: "" }
        let righetemp = righe.map(riga => riga.idrigafattura !== nuovariga.idrigafattura ? riga : nuovariga)
        righetemp.push(nuovariga);
        setrighe(righetemp);
        //setkeytorefresh(new Date().getTime())
        calcolaTotaleLordo(righetemp, palestra.palestra.id, setimportoiva, settotlordo, setimportopag);
    }

    function eliminaRiga(event, idriga) {
        console.log('elimino riga ', idriga)
        let righetem = righe.filter(rigat => rigat.idrigafattura !== idriga);
        setrighe(righetem);
        //setkeytorefresh(new Date().getTime())
        calcolaTotaleLordo(righe, palestra.palestra.id, setimportoiva, settotlordo, setimportopag);
    }

    function caricaListini() {
        async function listini() {
            const result = await getlistini(email, palestra.palestra.id);
            console.log(result)
            setlistini(result);
        }
        listini();
    }

    function caricaProdotti() {
        async function prodotti() {
            const result = await getcorsi(email, palestra.palestra.id, '');

            let corsitemp = []
            result.map(tipocorso =>
                tipocorso.corsi.map(corso => {
                    corsitemp.push(corso)
                }
                )
            )
            setprodotti(corsitemp);
        }
        prodotti();
    }

    function getnumfattura() {
        async function getnumf() {
            const result = await getNumeroFattura(palestra.palestra.id, email);
            setnumero(result)
        }
        getnumf();
    }

    useEffect(() => {
        caricaListini();
        caricaProdotti();
        getnumfattura();
        if (props.idanag && props.idanag > 0) {
            cercaanagbyid(props.idanag);
        }
    }, [email, palestra])

    function svuotacampi() {
        setnome('')
        setindirizzo('')
        setcitta('')
        setcap('')
        setcognome('')
        setcodicefiscale('')
        setdatanascita('')
        setluogonascita('')
        setsesso('')
        settipopag('')
        settelefono('')
        setemailanag('')
        setimportopag(0);
    }

    return (
        <Grid item container xs={12} key={keytorefresh}>
            <Grid item container xs={12} >
                <h4>Inserimento nuova fattura</h4>
                <Button variant="outlined" title="Vai alla pagina di ricerca fattura" onClick={() => navigate('/annotatio-ric-fatt/')} >
                    <ArrowForwardIosIcon /> <FontAwesomeIcon icon={faSearch} />
                </Button>
                <Button onClick={svuotacampi}>Svuota campi fattura</Button>
            </Grid>
            <Grid item container xs={12} className="sezione" marginY={1}>


                <InputText label="Ricerca anagrafica" type="text"
                    autoFocus={true}
                    handleSubmit={ricercaanagrafica}

                    className="form-control"
                    placeholder="NOME COGNOME CODICE FISCALE"

                    name="nomecognomecodfisc" value={nomecognomecodfisc} onChange={(e) => { setnomecognomecodfisc(e.target.value); clearTimeout(timeout); timeout = setTimeout(splitdati(e.target.value), 2000); }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><SearchIcon onClick={ricercaanagrafica} /></InputAdornment>,
                    }}
                />

                {messaggio}

                {anagrafiche && anagrafiche.length > 0 &&
                    <TableContainer>
                        <table className="table">
                            <thead>
                                <TableRow>
                                    <th>Seleziona</th>
                                    <th>Nome</th>
                                    <th>Cognome</th>
                                    <th>Data nascita</th>
                                    <th>Indirizzo</th>
                                    <th>Citta'</th>
                                </TableRow>
                            </thead>
                            <tbody>
                                {anagrafiche.map(anag => (
                                    <TableRow>
                                        <TableCell><Button onClick={() => { cercaanagbyid(anag.id); setanagrafiche([]) }}>{anag.id}</Button></TableCell>
                                        <TableCell>{anag.nome}</TableCell>
                                        <TableCell>{anag.cognome}</TableCell>
                                        <TableCell>{anag.datanascita}</TableCell>
                                        <TableCell>{anag.indirizzo}</TableCell>
                                        <TableCell>{anag.citta}</TableCell>
                                    </TableRow>
                                ))}
                            </tbody>
                        </table>
                    </TableContainer>
                }
            </Grid>
            <Grid item container xs={12} spacing={1} padding={2}>
            <Grid item container xs={12}><h4> <FontAwesomeIcon icon={faUserAlt} /> Anagrafica</h4></Grid>



            <Grid item container xs={12} md={4}>

                    <InputText label="Nome" type="text"
                        inputProps={{ style: { textTransform: 'capitalize' } }}
                        placeholder="NOME" name="nome" value={nome} onChange={(e) => setnome(e.target.value)} />
                </Grid>
                <Grid item container xs={12} md={4}>

                    <InputText label="Cognome" type="text"
                        inputProps={{ style: { textTransform: 'capitalize' } }}
                        placeholder="COGNOME" name="cognome" value={cognome} onChange={(e) => setcognome(e.target.value)} />
                </Grid>
                <Grid item container xs={7} md={3}>

                    <InputText label="Codice Fiscale" type="text"
                        inputProps={{ style: { textTransform: 'capitalize' } }}
                        placeholder="CODICE FISCALE" name="codicefiscale" value={codicefiscale}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><BadgeIcon onClick={calcolacf} /></InputAdornment>,
                        }}
                        onChange={(e) => setcodicefiscale(e.target.value.toUpperCase())} />

                </Grid>



                <Grid item container xs={5} md={3}>

                    <InputText label="Data nascita" type="date"

                        placeholder="DATA NASCITA" name="datanascita" value={datanascita} onChange={(e) => setdatanascita(e.target.value)} />
                </Grid>
                <Grid item container xs={7} md={7}>

                    <InputText label="Luogo nascita" type="text"

                        inputProps={{ style: { textTransform: 'capitalize' } }}
                        placeholder="LUOGO NASCITA" name="luogonascita" value={luogonascita} 
                        onChange={(e) => { setluogonascita(e.target.value); setshowcomunipernascita(true); cercacomuni(e.target.value) }} />
                    {
                        comuni.map(comune =>
                            showcomunipernascita && comune.comune.toUpperCase().indexOf(luogonascita.toUpperCase()) === 0 &&
                            <ul style={{ cursor: 'pointer', fontSize: 'smaller', borderStyle: "dashed", borderWidth: 1, borderColor: 'black' }}>
                                <li onClick={() => { setluogonascita(comune.comune); setshowcomunipernascita(false) }}>{comune.comune} ({comune.provincia}) - {comune.regione}</li>
                            </ul>
                        )}
                </Grid>
                <Grid item container xs={2} md={2}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel>Sesso</InputLabel>
                        <Select name="sesso" value={sesso} onChange={(e) => setsesso(e.target.value)} >
                            <MenuItem value='M'>M</MenuItem>
                            <MenuItem value='F'>F</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>


            </Grid> {/*  FINE ANAGRAFICA */}

            <Grid item container xs={12} spacing={1} padding={2}>
            <Grid item container xs={12}><h4><FontAwesomeIcon icon={faAddressCard} /> Indirizzo e recapiti</h4></Grid>
              
                    <Grid item container xs={12} md={5}>

<InputText label="Indirizzo" type="text"
    InputProps={{
        startAdornment: <InputAdornment position="start"><LocationOn /></InputAdornment>,
    }}
    placeholder="INDIRIZZO" name="indirizzo" value={indirizzo} onChange={(e) => setindirizzo(e.target.value)} />
</Grid>
<Grid item container xs={12} md={4}>

<InputText label="Citta" type="text"
    InputProps={{
        startAdornment: <InputAdornment position="start"><LocationCity /></InputAdornment>,
    }}
    placeholder="CITTA" name="citta" value={citta} onChange={(e) => { setcitta(e.target.value); setshowcomunipercitta(true); cercacomuni(e.target.value) }} />
{
            comuni.map(comune =>
                showcomunipercitta && comune.comune.toUpperCase().indexOf(citta.toUpperCase()) === 0 &&
                <ul style={{ cursor: 'pointer', fontSize: 'smaller', borderStyle: "dashed", borderWidth: 1, borderColor: 'black' }}>
                    <li onClick={() => { setcitta(comune.comune); setcap(comune.cap); setshowcomunipercitta(false) }}>{comune.comune} ({comune.provincia}) - {comune.regione}</li>
                </ul>
            )}
</Grid>
<Grid item container xs={12} md={2}>

<InputText label="CAP" type="text"
    
    placeholder="CAP" name="cap" value={cap} onChange={(e) => { setcap(e.target.value); setshowcomunipercap(true); cercacaps(e.target.value) }} />
{
            comuni.map(comune =>
                showcomunipercap && ("" + comune.cap).indexOf(cap) === 0 &&
                <ul style={{ cursor: 'pointer', fontSize: 'smaller', borderStyle: "dashed", borderWidth: 1, borderColor: 'black' }}>
                    <li onClick={() => { setcitta(comune.comune); setcap(comune.cap); setshowcomunipercap(false) }}>{comune.comune} ({comune.provincia}) - {comune.regione}</li>
                </ul>
            )}
</Grid>
<Grid item container xs={12} md={4}>

<InputText label="Telafono" type="phone"
    placeholder="TELEFONO"
    InputProps={{
        startAdornment: <InputAdornment position="start"><LocalPhone /></InputAdornment>,
    }}
    name="telefono" value={telefono} onChange={(e) => settelefono(e.target.value)} />
</Grid>
<Grid item container xs={12} md={4}>

<InputText label="Email" type="email"
    InputProps={{
        startAdornment: <InputAdornment position="start"><AlternateEmail /></InputAdornment>,
    }}
    placeholder="EMAIL" name="email" value={emailanag} onChange={(e) => setemailanag(e.target.value)} />
</Grid>
            </Grid> {/*  FINE INDIRIZZO */}

            <Grid item container xs={12} spacing={1} padding={2}>
                <Grid item container xs={12}><h4><FontAwesomeIcon icon={faFileInvoice} /> Dati fattura</h4></Grid>

                <Grid item container xs={6} md={2}>

                    <InputText label="Numero" type="text"
                        placeholder="NUMERO" name="numero" value={numero} onChange={(e) => setnumero(e.target.value)} />

                </Grid>
                <Grid item container xs={6} md={2}>

                    <InputText label="Anno" type="text"
                        className="form-control"
                        placeholder="ANNO" name="anno" value={anno} onChange={(e) => setanno(e.target.value)} />
                </Grid>
                <Grid item container xs={6} md={2}>

                    <InputText label="Data" type="date"
                        className="form-control"
                        placeholder="Data" name="data" value={data} onChange={(e) => setdata(e.target.value)} />
                </Grid>

                <Grid item container xs={2}>
                    <FormControlLabel control={
                        <Switch defaultChecked={false}
                            checked={reversecharge === true || reversecharge === 1 || reversecharge === "1" ? true : false}
                            value={reversecharge === true || reversecharge === 1 || reversecharge === "1" ? 0 : 1}
                            name="reversecharge"
                            onChange={(e) => setreversecharge(e.target.value)} />} label={"Reverse Charge"}
                    />
                </Grid>


            </Grid>
            <Grid item container xs={12} spacing={1} padding={2}>
            <h3>Righe fattura</h3>
            <TableContainer container={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={theadstyle}>Descrizione</TableCell>
                            <TableCell style={theadstyle}>Quantita</TableCell>
                            <TableCell style={theadstyle}>Costo</TableCell>
                            <TableCell style={theadstyle}>Tot. servizio</TableCell>
                            <TableCell style={theadstyle}>Aliquota Iva</TableCell>
                            <TableCell style={theadstyle}>Importo IVA</TableCell>
                            <TableCell style={theadstyle}>Natura (F.E.)</TableCell>
                            <TableCell style={theadstyle}>Elimina</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {righe.map(riga => (
                            <NuovaRiga key={riga.idrigafattura} updateRiga={updateRiga} riga={riga} eliminaRiga={eliminaRiga}
                                listini={listini} prodotti={prodotti}
                                aggiornarigadaprodotti={aggiornarigadaprodotti}
                                aggiornarigadalistini={aggiornarigadalistini} />
                        ))}
                        <TableRow>
                            <TableCell>
                                <Button onClick={aggiungiRiga}>
                                    <FontAwesomeIcon icon={faPlusSquare} /> Aggiungi riga
                                </Button>
                            </TableCell>
                            <TableCell colSpan={3} align={"right"}>Importo netto totale</TableCell>
                            <TableCell>{totlordo - importoiva}</TableCell>
                            <TableCell align={"right"}>Importo totale IVA</TableCell>
                            <TableCell>{importoiva}</TableCell>
                        </TableRow>
                        <TableRow><TableCell colSpan={6} align={"right"}>Totale Fattura</TableCell>
                            <TableCell>{totlordo}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            </Grid>
            <Grid item container xs={12} spacing={1} padding={2}>
                <h4><FontAwesomeIcon icon={faEuroSign} /> Dati pagamento</h4>
                <Grid item container xs={12}>
                <Grid item container md={3}>
                           
                            <InputText label="Data pagamento" type="date"
                                
                                placeholder="Data" name="datapag" value={datapag} onChange={(e) => setdatapag(e.target.value)} />
                        </Grid>
                        <Grid item container md={3}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel>Tipo pagamento</InputLabel>
                            <Select name="tipopag" value={tipopag} onChange={(e) => settipopag(e.target.value)}>
                                <MenuItem value="carta">carta/bancomat</MenuItem>
                                <MenuItem value="contanti">contanti</MenuItem>
                                <MenuItem value="assegno">assegno</MenuItem>
                                <MenuItem value="bonifico">bonifico</MenuItem>
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item container md={3}>
                           
                            <InputText align={"right"} label="Importo" type="number"
                            inputProps={{ style: { textAlign: 'right' } }}

                                     placeholder="Importo" name="importopag" value={importopag} onChange={(e) => setimportopag(e.target.value)} />

                        </Grid>
                    
                </Grid>
            </Grid>

            <Grid item container xs={12}>
                {!loading &&
                    <Button fullWidth variant="contained" title="Salva Fattura" onClick={insfatt}><SaveIcon /> <DescriptionIcon /> </Button>}
                {loading && <div>Attendere prego...</div>}
            </Grid>
        </Grid>
    );
}