import React from "react";
import axios from 'axios';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import ListaFatturato from "./ListaFatturato";
import {connect} from 'react-redux'
import { Grid, Paper } from "@mui/material";

class FiltriFatturato extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
				daAnnoMese:moment().format("YYYY-MM-DD"),
				aAnnoMese:moment().format("YYYY-MM-DD"),
				popupVisible:false,
                fatturato:[]
	    };
		this.refresh = this.refresh.bind(this);
		this.update = this.update.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.togglePopup=this.togglePopup.bind(this);
	  }
	
	componentDidMount(){
		
	  }

	update(e) {
		this.setState({ [e.target.name]: e.target.value, visible: true });
	}
	
	refresh(){

	  }

	  togglePopup(){
		this.setState({popupVisible: !this.state.popupVisible})
	}

	  handleSubmit(event){
		event.preventDefault();
	    const { daAnnoMese,
            aAnnoMese} = this.state;

		const formData = new FormData();

		formData.append('daAnnoMese', daAnnoMese);
		formData.append('aAnnoMese', aAnnoMese);
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
      axios.post(global.config.server+'/reportFatturato', formData)
        .then((result) => {
			console.log(result.data)
			this.setState({fatturato:[]});
        	this.setState({fatturato:result.data});
        });
	  }
	
	componentDidCatch(error, info) {
	    // You can also log the error to an error reporting service
	    console.log(error, info);
	  }
	
	render() {
	    return (
			<Grid item container xs={12} md={6} padding={2}>
			<Paper elevation={5} sx={{width:'100%'}}>
			<Grid item container xs={12}>
				<div className="col"><h3 className="text-center">FATTURATO</h3></div>
			</Grid>	
			<Grid item container xs={6}>
				<div className="col">
					<label>Da:
					<input type="date" id="start_date1" max={this.state.aAnnoMese} name="daAnnoMese" className="form-control" value={this.state.daAnnoMese} onChange={this.update}/></label> 
				</div>
				<div className="col">
					<label>A:
					<input type="date" id="end_date1" min={this.state.daAnnoMese} name="aAnnoMese" className="form-control" value={this.state.aAnnoMese} onChange={this.update}/></label> 
				</div>

			</Grid>
			<Grid item container xs={6}>
				<a type="button" className="btn btn-primary btn-lg" onClick={this.handleSubmit}>
					<FontAwesomeIcon icon={faSearch} />
				</a>
			</Grid>
				<ListaFatturato fatturato={this.state.fatturato}/>
				</Paper>
		</Grid>
	    );
	  }
}

const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraassociata.palestra,
        email: state.gestoreUtente.emailloggato
    }
}

export default connect(mapStateToProps, null)(FiltriFatturato)