import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { aggiornaCorsoFiglio, caricacorsifigli, cercaCorsi } from "../../DAO/corsiDAO";

var timeout;
export default function CorsiFigli ({corsopadre}){
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
    const token = useSelector(state => state.gestoreUtente.token);

    const [corsifigli, setcorsifigli] = useState([])
    const [disabled, ] = useState(false)
    const [corsi, setcorsi] = useState([])
    const [corsoselezionato, setcorsoselezionato] = useState('')

    function salvaCorsoFiglio(){
        async function salva(){
            const result=await aggiornaCorsoFiglio(emailloggato, corsoselezionato, corsopadre.id, token)
            alert(result)
            setcorsoselezionato(null)
            setcorsi(null)
            caricafigli()
        }

        salva()
    }

    function eliminaCorsoFiglio(idfiglio){
        async function salva(){
            const result=await aggiornaCorsoFiglio(emailloggato, idfiglio, null, token)
            alert(result)
            setcorsoselezionato(null)
            setcorsi(null)
            caricafigli()
        }

        salva()
    }

    function cercacorsi(descr){
        async function cercac(){
            const result=await cercaCorsi(emailloggato, palestra.palestra.id, descr)
            if(result&&Array.isArray(result)&&result.length>0){
                console.log(result)
                setcorsi(result)
            }
        }
        if (descr&&descr.length>=3){
            if(timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                cercac()
            }, 1000);
        }
    }

    function caricafigli(){
        async function caricafigli(){
                const result=await caricacorsifigli(emailloggato, palestra.palestra.id, corsopadre.tipoCorso.id, corsopadre.id)
                setcorsifigli(result)
        }
       
            caricafigli()
       
    }

    useEffect(() => {
        console.log(corsopadre)
        caricafigli()
        setcorsoselezionato(null)
            setcorsi(null)
            caricafigli()
    },[])

    return (

        <div className="container-fluid">
            <div className="row">
                <div className="col-md sezione">
                    <h4>Lista prodotti figli</h4>
                {
                corsifigli&&Array.isArray(corsifigli)&&corsifigli.length>0&&
                corsifigli.map(figlio =>(
                    <p>{figlio.nome} <button onClick={() => eliminaCorsoFiglio(figlio.id)}>Elimina</button></p>
                ))
            }
                </div>
                <div className="col-md sezione">
                <h4>Nuovo prodotto figlio</h4>
                <input type="text" className="form-control" name="filtro" onChange={(e) => cercacorsi(e.target.value)} placeholder="Cerca prodotti" disabled={disabled}/>
            {disabled&&"Attendere..."}

            {corsi&&Array.isArray(corsi)&&corsi.length>0&&
            <div>
            <select className="form-control" name="anagrafica" onChange={(e)=>setcorsoselezionato(e.target.value)}>
                <option value="">-- seleziona prodotto --</option>
                {corsi?
                corsi.map(corso=>(
                <option value={corso.id}>{corso.nome}</option>
                ))
                :<option>NULLA</option>
                }
            </select>
            <button onClick={() => salvaCorsoFiglio()}>salva</button>
            </div>}
            {
             (!corsi||corsi.length===0) &&
             <div>Nessun prodotto selezionato</div>
            }
                </div>
            </div>
        </div>
    )

}