import { getComuneByCodErariale } from '../DAO/anagraficaDAO';
const MESI = { A: '01', B: '02', C: '03', D: '04', E: '05', H: '06', L: '07', M: '08', P: '09', R: '10', S: '11', T: '12' };


export async function comuneCf (codiceFiscale) {
	let codice="";
	if (codiceFiscale.charAt(12).toUpperCase() === 'Z'){
		codice = "Estero";
	 }
	 else {
		codice=await getComuneByCodErariale(codiceFiscale.substring(11,15).toUpperCase())
	 }
	 return codice

}

export function dataCf (codiceFiscale) {
	let [ anno, giorno ] = [ codiceFiscale.substring(6,8), codiceFiscale.substring(9,11) ];
	if (giorno>40) {
		giorno -= 40;
		giorno = ("0" + giorno).slice(-2);
	}
	return (anno < 20 ? "20" : "19" ) + anno + "-" + MESI[codiceFiscale.charAt(8)] + "-" + giorno;
}

export function sessoCf (codiceFiscale) { return codiceFiscale.substring(9,11) > 40 ? "F" : "M"; }