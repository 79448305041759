import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { aggiornaAllergeni } from "../../DAO/corsiDAO";

export default function Allergeni(props){
    const listaallergeni=["arachidi","crostacei", "fruttasecca", "glutine","latte", "lupini", "molluschi", "pesce", "sedano", "senape"]

    const [allergeni, setallergeni] = useState(props.allergeni);

    function selezionaallergene(event, allerg){
        var ellergtemp=allergeni;
        ellergtemp=ellergtemp.filter(temp=> temp!==allerg)
        console.log("checked "+event.target.checked)

        if (event.target.checked){    
            ellergtemp.push(allerg);
        }
        setallergeni(ellergtemp)
        console.log("lista arregnei selezionati: "+ellergtemp.toString())
        props.setallergeni(ellergtemp.toString())
        aggiornaAllergeniProdotto(ellergtemp);
    }

    function aggiornaAllergeniProdotto(ellergtemp){
        async function aggiorna(){
            const result=await aggiornaAllergeni(props.idcorso, ellergtemp.toString())
            console.log("risultato aggiornamendo allergeni su db: "+result)
        }
        aggiorna()
    }

    function allergeneCHecked(allergtemp){
        console.log("check "+allergtemp+" in "+allergeni)
        return allergeni&&allergeni.length>0&&allergeni.filter(alle=> alle===allergtemp).length>0
    }

    useEffect(() =>{
       
    },[props.allergeni])

    return(
        <div className="row">

            {listaallergeni.map(allergene => (
                <div key={allergene}>
                    <label>
                    <input type="checkbox" name={allergene} value={allergene} checked={allergeneCHecked(allergene)} onChange={(e) => selezionaallergene(e, allergene)} />
                    {allergene}
                    <img alt="allergene" src={"https://annotatio.online/wp-content/uploads/2023/02/"+allergene+".png"} style={{width:30}} />
                    &nbsp;&nbsp;
                    </label>
                </div>
            )

            )}
            
        </div>
    );
}