import React from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import NuovaSchedaSA from "./NuovaSchedaSA";
import RicercaSchedaSARiga from "./RicercaSchedaSARiga";
import { connect } from 'react-redux'
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import InputText from "../util/UI/InputText";
import NuovoEsercizioScheda from "./NuovoEsercizioScheda";

const stileHead={textAlign:'center', backgroundColor:'black', fontWeight:'bold', color:'white'}

class RicercaSchedaSA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            descrscheda: '',
            cognomenome: '',
            schede: [],
            dacancellare: [],
            nuovaSchedaVisible: false,
            keytorefresh: new Date().getTime()
        }
        this.update = this.update.bind(this);
        this.cercaScheda = this.cercaScheda.bind(this);
        this.showNuovaScheda = this.showNuovaScheda.bind(this);
        this.eliminaSchede = this.eliminaSchede.bind(this);
    }

    componentDidMount() {

    }

    eliminaSchede(e) {
        e.preventDefault()
        if (this.state.dacancellare.length === 0) {
            alert('Selezionare almeno una scheda da cancellare')
            return
        }
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email', this.props.email);
        formData.append('lic', '0001');
        formData.append('palestra', this.props.palestra.id);
        if (this.state.dacancellare) {
            this.state.dacancellare.map(schedatemp => {
                formData.append('idScheda', schedatemp);
            })
        }
        axios.post(global.config.server + '/eliminaSchedaSalaAttrezzi', formData)
            .then((result) => {
                this.setState({ messaggio: result.data });
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                });
                Toast.fire({
                    type: 'success',
                    title: result.data
                })
                //this.props.refresh();
                this.setState({ keytorefresh: new Date().getTime() })
                this.cercaScheda(null)
            });
    }

    showNuovaScheda() {
        this.setState({ nuovaSchedaVisible: !this.state.nuovaSchedaVisible })
    }

    cercaScheda(e) {
        if (e) {
            e.preventDefault()
        }
        const { descrscheda, cognomenome } = this.state;
        if (descrscheda === '' && cognomenome === '') {
            alert('Specificare almeno un filtro')
            return
        }
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email', this.props.email);
        formData.append('lic', '0001');
        formData.append('palestra', this.props.palestra.id);
        formData.append('descrscheda', descrscheda);
        formData.append('cognomenome', cognomenome);

        axios.post(global.config.server + '/cercaSchedeSalaAttrezzi', formData)
            .then((result) => {
                this.setState({ schede: result.data })
            });
    }

    update(e) {
        if (e.target.name === 'template') {
            if (e.target.checked) {
                this.setState({ [e.target.name]: 1 });
            }
            else {
                this.setState({ [e.target.name]: 0 });
            }
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    

    render() {
        return (
            <Grid container spacing={3}>
            {/* Sezione superiore per creare nuove schede ed esercizi */}
            <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>
                                Crea Nuova Scheda
                            </Typography>
                            <NuovaSchedaSA email={this.props.email} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>
                                Aggiungi Nuovo Esercizio
                            </Typography>
                            <NuovoEsercizioScheda
                                categorie={this.state.categorie}
                                refresh={this.refresh}
                                setview={this.props.setview}
                                email={this.props.email}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            {/* Sezione di ricerca delle schede */}
            <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Cerca Scheda
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <InputText
                                label="Descrizione"
                                handleSubmit={this.cercaScheda}
                                name="descrscheda"
                                onChange={this.update}
                                value={this.state.descrscheda}
                                type="text"
                                placeholder="Descrizione scheda"
                                aria-label="Search"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText
                                label="Cognome Nome"
                                handleSubmit={this.cercaScheda}
                                name="cognomenome"
                                onChange={this.update}
                                value={this.state.cognomenome}
                                type="text"
                                placeholder="Assegnata a"
                                aria-label="Search"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={this.cercaScheda} fullWidth>
                                Cerca
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            {/* Sezione risultati della ricerca */}
            <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Risultati della Ricerca
                    </Typography>
                    {this.state.schede && this.state.schede.length > 0 ? (
                        <>
                            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                Trovate {this.state.schede.length} schede
                            </Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={stileHead}>ID</TableCell>
                                            <TableCell sx={stileHead}>Descrizione</TableCell>
                                            <TableCell sx={stileHead}>Valido da</TableCell>
                                            <TableCell sx={stileHead}>Predefinita</TableCell>
                                            <TableCell sx={stileHead}>Assegnata a</TableCell>
                                            <TableCell sx={stileHead}>
                                                <Button
                                                    id="deleteSelected"
                                                    color="error"
                                                    onClick={this.eliminaSchede}
                                                    variant="contained"
                                                >
                                                    Elimina le schede selezionate
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.schede.map((scheda) => (
                                            <RicercaSchedaSARiga
                                                key={scheda.idscheda}
                                                scheda={scheda}
                                                dacancellare={this.state.dacancellare}
                                                link={this.props.link}
                                                setview={this.props.setview}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <Typography variant="body1" color="textSecondary">
                            Nessuna scheda trovata
                        </Typography>
                    )}
                </Paper>
            </Grid>
        </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraassociata.palestra,
        email: state.gestoreUtente.emailloggato
    }
}

export default connect(mapStateToProps, null)(RicercaSchedaSA)