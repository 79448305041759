import React from "react";
import axios from 'axios';
import '../util/config'
import Accordion from 'react-bootstrap/Accordion';
import NuovoAbbonamento from "../abbonamenti/NuovoAbbonamento";
import AbbonamentoUtente from "../abbonamenti/AbbonamentoUtente"
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


import { Button, Grid } from "@mui/material";

export default function AbbonamentiUtente(props) {
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
  const email = useSelector(state => state.gestoreUtente.emailloggato);
  const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata);
  const palestrasocio = useSelector(state => state.gestoreUtente.palestraassociata);
  const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);


  const [abbonamenti, setabbonamenti] = useState(null);
  const [listini, setlistini] = useState([])
  const [sconti, setsconti] = useState([])
  const [showModalAbb, setshowModalAbb] = useState(false)
  const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())

  useEffect(() => {
    console.log('riquadri - AbbonamentiUtente')
    const formData = new FormData();
    formData.append('website', palestra.website);
    formData.append('email', email);
    formData.append('lic', '000');
    formData.append('palestra', palestra.id);
    formData.append('id', idanagselezionata);
    axios.post(global.config.server + "/getAbbonamenti", formData)
      .then(response => {
        console.log(response);
        axios.post(global.config.server + "/listiniRest", formData)
          .then(response2 => {
            axios.post(global.config.server + "/listaSconti", formData)
              .then(response3 => {
                setabbonamenti(response.data)
                setlistini(response2.data);
                setsconti(response3.data);
                //console.log(response.data)

              }).catch(err => {
                console.log(err)
                alert("Sconti: " + err.response);
              });
            //console.log(response);
          }).catch(err => {
            if (err.response) {
              alert("Listini: " + err.response.data.message);
            }
            else {
              alert("Listini: " + err.response);
            }
          });
      }).catch(err => {
        alert("Abbonamenti: " + err.response.data.message);
      });
  }, [idanagselezionata, keytorefresh])

  function togglePopup() {
    setshowModalAbb(!showModalAbb)
  }

  return (
    <Grid item container xs={12} key={keytorefresh}>
      <NuovoAbbonamento
          show={showModalAbb}
          togglePopup={togglePopup}
          email={email}
          idpalestra={palestra.id}
          listini={props.listini}
          refresh={setkeytorefresh}
        />
        <Grid item container xs={12}>
        <Accordion>
        
        {
          abbonamenti && Array.isArray(abbonamenti) && abbonamenti.length > 0 ?
            abbonamenti.map(item => (
              <AbbonamentoUtente key={item.id} selected={item}
                email={email}
                listini={listini} sconti={sconti}
                refresh={setkeytorefresh}
              />
            )
            )
            : null
        }
        {(!abbonamenti || abbonamenti.length == 0) ? <p>Nessun Abbonamento Trovato</p> : null}
      </Accordion>
      </Grid>
    </Grid>
  );
}