import React from "react";
import {navigate} from 'hookrouter'
import {useSelector} from 'react-redux'
import { AppBar, Box, Button, Container, Divider, Toolbar, Typography } from "@mui/material";
import Laterale from "./Laterale";
import Qrcode from "../anagrafica/Qrcode";

export default function ToolbarAnnotatioAdmin(){
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const palestraassociata= useSelector(state => state.gestoreUtente.palestraassociata);
    const menuadmin = useSelector(state => state.gestoreMenu.menuadmin);

    const [, setAnchorElNav] = React.useState(null);

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
      };

    return(
<>
<AppBar position="sticky" sx={{marginBottom:5}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Laterale />
        

        {//LOGO GRANDE
        palestraassociata&&palestraassociata.palestra&& 
       <Button sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}  onClick={()=> navigate('/annotatio/')}>
            <img src={palestraassociata&&palestraassociata.palestra&&palestraassociata.palestra.id?palestraassociata.palestra.logo:"https://annotatio.online/wp-content/uploads/2022/02/logoannotatio.png"} 
        title={palestraassociata.palestra.palestra} alt={palestraassociata.palestra.palestra} style={{height:'4em', float:'left', marginRight:'15px'}}
         />
            </Button>}
            {//LOGO PICCOLO
            palestraassociata&&palestraassociata.palestra&&palestraassociata.palestra.id&& 
            <Button sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}  onClick={()=> navigate('/annotatio/')}  color="error">
            <img src={palestra&&palestraassociata.palestra.id?palestraassociata.palestra.logo:"https://annotatio.online/wp-content/uploads/2022/02/logoannotatio.png"} 
        title={palestraassociata.palestra.palestra} alt={palestraassociata.palestra.palestra} style={{height:'3em', float:'left', marginRight:'15px'}}
         />
            </Button>}

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

            {menuadmin && Array.isArray(menuadmin) && menuadmin.length > 0 &&
          menuadmin.map(menuitem =>
            menuitem.link&&
            <Button key={menuitem.id} onClick={()=> {navigate(menuitem.link);handleCloseNavMenu()}} sx={{ my: 2, color: 'white', display: 'block' }}>
                <i className={menuitem.faiconclass} />
                  <Typography textAlign="center" sx={{fontSize:'small'}}>
                 {menuitem.nome} 
                  </Typography>
                  
                </Button>
                

          )

        }
        
              </Box>
          <Box sx={{ flexGrow: 0 }}>
          
            {palestraassociata&&palestraassociata.utente?
              <Box>
              <Typography sx={{display:{xs:'none', md:'flex'}}} variant="subtitle2">{palestraassociata.utente.nome} {palestraassociata.utente.cognome} - {palestraassociata.id}</Typography>
              <Typography variant="subtitle2">{palestraassociata.utente.email} </Typography>
              </Box>
              :
              <Box >
                {palestraassociata&&palestraassociata.palestra&&<Typography variant="subtitle2">{palestraassociata.palestra.palestra}</Typography>}
                {palestraassociata&&palestraassociata.palestra&&<Typography variant="caption">{palestraassociata.palestra.descrizioneattivita}</Typography>}
              </Box>
              }
            
          </Box>
          <Qrcode />
        </Toolbar>
      </Container>
    </AppBar>
    
 







            {/* <nav className="navbar sticky-top navbar-expand-lg navbar-light " style={{backgroundColor: "#e3f2fd"}}>
                <Intestazione />
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('CLIENTI')>=0))&& 
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='ricanag'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-anag/',)}>Ric. Anagrafica</a>
                    </li>
                    }
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('CALENDARIOEPRENOTAZIONI')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='calendari'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-prods/',)}>Calendari</a>
                    </li>
                    }
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('CASSA')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='cassa'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-cassa/',)}>Cassa</a>
                    </li>}
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('REPORT')>=0))&&
                        <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='report'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-dashboard/',)}>Report e Stat.</a>
                    </li>
                    }
                {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('SCHEDESALAATTREZZI')>=0))&&
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#appadminannotatio" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Schede
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a className={"nav-link "+(props.viewselected==='schedeSA'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-sa/',)}>Sala Attrezzi</a></li>
                        <li><a className={"nav-link "+(props.viewselected==='schedeCC'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-cc/',)}>Composizione Corporea e Diario Alimentare</a></li>
                        </ul>
                    </li>}
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('CONTROLLOACCESSI')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='accessi'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-accessi/',)}>Controllo Accessi</a>
                    </li>
                    }
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('ARCHIVI')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='archivi'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-arch/',)}>Archivi</a>
                    </li>
                    }
                    {palestra&&(palestra.amministratore||(palestra.funzionalita&&palestra.funzionalita.indexOf('COLLABORATORI')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='archivi'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-collab/',)}>Collaboratori</a>
                    </li>
                    }
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('TESSERAMENTI')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='archivi'?"active":"")} href="#" onClick={()=> navigate('/annotatio-tess/',)}>Tesseramenti</a>
                    </li>
                    }
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('FATTURE')>=0))&&
                    <li className="nav-item dropdown ms-auto">
                         <A className="nav-link dropdown-toggle" href="/annotatio-fatt/" id="navbarfattDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                            Fatture
                        </A>
                        <ul className="dropdown-menu">
                            <li><A className={"nav-link"} href="/annotatio-ric-fatt/" >Ricerca</A></li>
                            <li><A className={"nav-link"} href="/annotatio-ins-fatt/">Inserimento</A></li>
                        </ul>
                    </li>
                   
                    }
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('ALIMENTI')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='archivi'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-alimenti/',)}>Gestione Alimenti</a>
                    </li>
                    }
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('DOCUMENTI')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='archivi'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-doc/',)}>Gestione Template Documenti</a>
                    </li>
                    }
                    {palestra&&palestra.amministratore&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='archivi'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-dett-pal/',)}>Dettaglio Attivita' commerciale</a>
                    </li>
                    }
                    
                    
                    </ul>
                </div>
                <a className="navbar-brand" href="#appadminannotatio" onClick={()=> navigate('/annotatio/',true)}>
                    <img src="https://annotatio.online/wp-content/uploads/2022/09/annotatioA512__1_-removebg-preview-1.png" width="30" height="30" alt="Annotatio" />nnotatio
                </a>
            </nav> */}
            </>
    );

}