import React, { useState } from "react";
import '../util/config'
import TipologieCorso from "./tipologiaCorso/TipologieCorso";

import ListaCorsi from "./corsi/ListaCorsi";
import TipologieAbbonamentoInput from "./tipologiaAbbonamento/TipologieAbbonamentoInput";
import PeriodiInput from "./periodi/PeriodiInput";
import ListiniInput from "./listino/ListiniInput";
import ScontiInput from "./sconti/ScontiInput";
import ImportEsami from "../nutrizionista/esame/ImportEsami";
import { Grid, Tab, Tabs } from "@mui/material";
import SwipeableViews from 'react-swipeable-views';

export default function Archivi (props) {
	const [tabvalue, settabvalue] = useState(0)
	const handleChange = (event, newValue) => {
		console.log(newValue)
		settabvalue(newValue);
	  };

    return (
		<Grid item container>
				 <Tabs variant="scrollable" scrollButtons="auto" sx={{width:'100%'}} value={tabvalue} onChange={handleChange}>
					 <Tab value={0} label="Categorie">
					
					 </Tab>
					 <Tab value={1} label="Servizi/Prodotti">
					 
					 </Tab>
					 <Tab value={2} label="Tipologia Abbonamento">
					 
					 </Tab>
					 <Tab value={3} label="Periodi">
					 
					 </Tab>
					 <Tab value={4} label="Listini">
					 	
					 </Tab>
					 <Tab value={5} label="Sconti">
					 
					 </Tab>
					 <Tab value={6} label="Importa esami">
					 
					 </Tab>
				</Tabs>
				<SwipeableViews
        index={tabvalue}
        onChangeIndex={(newvalue) => {console.log("new value: "+newvalue);settabvalue(newvalue)}}
      >
		<TipologieCorso key={0} email={props.email} />
		<ListaCorsi key={1} email={props.email} />
		<TipologieAbbonamentoInput key={2} email={props.email} />
		<PeriodiInput key={3} email={props.email} />
		<ListiniInput key={4} email={props.email} />
		<ScontiInput key={5} email={props.email} />
		<ImportEsami key={6} />
		</SwipeableViews>
				</Grid>
    );
}
