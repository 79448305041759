import React, { useEffect, useState } from "react";
import '../../util/config'
import axios from 'axios';
import Swal from "sweetalert2";
import ListinoInput from "./ListinoInput";
import NuovoListinoInput from "./NuovoListinoInput";
import { useSelector } from "react-redux";
import serverInfo from "../../util/globals";
import { Grid } from "@mui/material";

export default function ListiniInput() {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [listini, setlistini]= useState([])
	const [listaPeriodi, setlistaPeriodi]= useState([])
	const [listaTipologie, setlistaTipologie]= useState([])
	const [getConfigRest, setgetConfigRest] =useState(null);
	const [prezzoiscrizione, setprezzoiscrizione]= useState(0)
	const [durataiscrizione, setdurataiscrizione]= useState(0)
	const [mostranuovoabbonamento, setmostranuovoabbonamento] =useState(null)
	const [mostranuovopagamento, setmostranuovopagamento] =useState(null)
	const [ppid, setppid] =useState('')

	const [messaggio, setmessaggio] = useState('')
	const [visible, setvisible] = useState('')


	useEffect( () => {
		if (palestra&&palestra.id){
		const formData = new FormData();
		formData.append('website', palestra.website);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
		axios.post(serverInfo.namesec+"/wp/getPeriodiRest", formData,
		{
			headers: {
			  Accept: 'application/json',
			  'Content-Type': 'multipart/form-data',
			  'Access-Control-Allow-Origin': '*',
			  'Access-Control-Allow-Methods': 'POST',
			  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
			  'Access-Control-Allow-Credentials': 'true',
			  'Authorization' :'Bearer '+window.$t
			},
		  },
		)
	  .then(response1=>{
		 
			axios.post(global.config.server+"/listiniRest", formData)
		  .then(response2=>{
			 
				axios.post(global.config.server+"/getTipologieAbbRest", formData)
					.then(response3=>{
					
					axios.post(global.config.server+"/getConfigRest", formData)
				.then(response4=>{
					//console.log(response2.data)
					//console.log(response4.data)
					setlistaTipologie(response3.data);
					setlistini(response2.data)
					setlistaPeriodi(response1.data)
					setgetConfigRest(response4.data);
					setppid(response4.data.ppid);
					setmostranuovoabbonamento(response4.data.mostranuovoabbonamento);
					setmostranuovopagamento(response4.data.mostranuovopagamento);
					setprezzoiscrizione(response4.data.prezzoiscrizione)
					setdurataiscrizione(response4.data.durataiscrizione)

				}).catch(err => {
					if (err.response){
					  alert(err.response.data.message);
					}
					else{
					  alert(err.response);
					}
				  });

					}).catch(err => {
						if (err.response){
						  alert(err.response.data.message);
						}
						else{
						  alert(err.response);
						}
					  });
					

			  
		  }).catch(err => {
			if (err.response){
			  alert(err.response.data.message);
			}
			else{
			  alert(err.response);
			}
		  });




	  }).catch(err => {
		if (err.response){
		  alert(err.response.data.message);
		}
		else{
		  alert(err.response);
		}
	  });
	}
	  
  },[palestra]);


  function salvaInfoIscrizione(event){
	event.preventDefault();

	    const formData = new FormData();
	    formData.append('prezzoiscrizione', prezzoiscrizione);
		formData.append('durataiscrizione', durataiscrizione);
		formData.append('mostranuovoabbonamento', mostranuovoabbonamento);
		formData.append('mostranuovopagamento', mostranuovopagamento);
		formData.append('ppid', ppid);
		
		
		formData.append('website', palestra.website);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
		  axios.post(global.config.server+'/aggiornaPrezzoIscrizione',formData)
	      .then((result) => {
		        setmessaggio(result.data);
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          setvisible(false);
	      }).catch(err => {
			if (err.response){
			  alert('1'+err.response.data.message);
			}
			else{
				console.log(err)
			  alert('2'+err.response);
			}
		  });
	}

	  let style1={
		color:"white"
		}

    return (
		<Grid item container xs={12}>
		<div className="row" style={{borderWidth:"1px", borderStyle:"solid", borderColor:"black"}}>
			<div className="col-4">
				Informazione sull'iscrizione (valida per tutti i listini) e sui pagamenti
			</div>
			<div className="col-8">
				<div className="row">
					<div className="col-6">
						<label className="control-label">Prezzo iscrizione</label>
						<input className="form-control" type="number" step="0.5" onChange={(e) => setprezzoiscrizione(e.target.value) } value={prezzoiscrizione} name="prezzoiscrizione" />
					</div>
					<div className="col-6">
						<label className="control-label">Validità in mesi</label>
						<input className="form-control" type="number" step="1" onChange={(e) => setdurataiscrizione(e.target.value)} value={durataiscrizione} name="durataiscrizione" />
					</div>
					<div className="row">
					<div className="col-3">
						<label className="control-label">Mostra bottone "Nuovo Abbonamento"</label>
						<select className="form-control" name="mostranuovoabbonamento" value={mostranuovoabbonamento&&(mostranuovoabbonamento===1||mostranuovoabbonamento==="1"||mostranuovoabbonamento===true)?"1":"0"} onChange={(value) => setmostranuovoabbonamento(value)}>
							<option value="1">Si</option>
							<option value="0">No</option>
						</select>
					</div>
					<div className="col-3">
						<label className="control-label">Mostra bottone "Paga ora"</label>
						<select className="form-control" name="mostranuovopagamento" value={mostranuovopagamento&&(mostranuovopagamento===true||mostranuovopagamento===1||mostranuovopagamento==="1"||mostranuovopagamento===true)?"1":"0"} onChange={(value) => setmostranuovopagamento(value)}>
							<option value="1">Si</option>
							<option value="0">No</option>
						</select>
					</div>
					<div className="col-3">
						<label className="control-label">PayPal id</label>
						<input className="form-control" type="text" onChange={(e) => setppid(e.target.value)} value={ppid} name="ppid" />
					</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
					<a type="button" className="btn btn-primary" onClick={salvaInfoIscrizione} style={style1}>Aggiorna Informazioni iscrizione</a>
					</div>
				</div>
			</div>
		</div>
	{
		listini.map(item =>(
				<ListinoInput listaPeriodi={listaPeriodi} 
				listaTipologie={listaTipologie} 
				key={item.id} 
				selected={item} 
				email={email} />
				)
		)
	}
	<NuovoListinoInput listaPeriodi={listaPeriodi} listaTipologie={listaTipologie} email={email} />
	</Grid>
    );
}