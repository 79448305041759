import React from "react";
import '../../util/config'
import axios from 'axios';
import CategorieSelect from "./CategorieSelect";
import NuovoEsercizio from "./NuovoEsercizio";
import EsercizioInput from "./EsercizioInput";
import { connect } from "react-redux";

class ListaEserciziInput extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
			descrizione:null,
			categoria:'',
			esercizi:[],
			categorie:[],
			loading:false
			}
		this.updateCategoria = this.updateCategoria.bind(this);
		this.update = this.update.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.refresh = this.refresh.bind(this);
	  }

	  componentDidMount(){
		console.log('ListaEsercizi')
		  this.setState({loading:true})
		  const formData = new FormData();
		  formData.append('website', global.config.domain);
		  formData.append('email',this.props.email);
		  formData.append('lic','000');
		  formData.append('palestra',this.props.palestra.id);        
		          //axios.post(global.config.server+"/getEserciziRest", formData)
		//.then(response=>{
			axios.post(global.config.server+"/getCategEserciziRest",formData)
		.then(response2=>{
			this.setState({categorie: response2.data, loading:false});
		})
		.catch(err => {
			if (err.response)
			alert("lista esercizi: "+err.response.message);
		});
		//});
		
	}

	refresh(){
		console.log('refresh')
		const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
		formData.append('descrizione',this.state.descrizione)
		formData.append('categoria',this.state.categoria)
	      axios.post(global.config.server+'/cercaEserciziRest',formData)
	      .then((response) => {
			console.log(response.data)
			this.setState({esercizi: response.data});
			this.setState({loading:false})
	      }).catch(err => {
			if (err.response)
			alert("lista esercizi: "+err.response.message);
		});
	}

	update(e) {
		this.setState({ [e.target.name]: e.target.value, visible: true });
	}

	handleSubmit(event) {
		this.setState({loading:true})
		this.setState({esercizi:[]});
		event.preventDefault();
		if (!this.state.descrizione
			&&!this.state.categoria){
			alert('Specificare almeno un parametro di ricerca')
			return;
		}
	    const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
		formData.append('descrizione',this.state.descrizione)
		formData.append('categoria',this.state.categoria)
	      axios.post(global.config.server+'/cercaEserciziRest',formData)
	      .then((response) => {
			console.log(response.data)
			this.setState({esercizi: response.data});
			this.setState({loading:false})
	      }).catch(err => {
			if (err.response)
			alert("lista esercizi: "+err.response.message);
		});
	  }

	  updateCategoria(categSelezionata) {
		var categoria = {...this.state.categoria}
		categoria = categSelezionata;
		this.setState({categoria, buttonVisible: true})
	  }
	 
	render() {

	    return (
			<div>
				
			<nav className="navbar navbar-expand-lg navbar-light bg-light">
			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent22" aria-controls="navbarSupportedContent11" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>
			<div className="collapse navbar-collapse" id="navbarSupportedContent22">

				<form className="form-inline mr-auto" method="post">
					<input className="form-control" onChange={this.update} name="descrizione" type="text" placeholder="descrizione esercizio" aria-label="Search" />
					<CategorieSelect selected={this.state.categoria} categorie={this.state.categorie} updateCategoria={this.updateCategoria} />
					<button className="btn btn-mdb-color btn-rounded btn-sm my-0 ml-sm-2" onClick={this.handleSubmit}>Cerca</button>
				</form>

			</div>
			<a className="navbar-brand" href="#">Cerca Esercizi</a>
			</nav>
			<NuovoEsercizio categorie={this.state.categorie} 
			refresh={this.refresh} 
			setview={this.props.setview}
			email={this.props.email}
			/>
			{this.state.esercizi&&!this.state.loading?"Trovati "+this.state.esercizi.length+" esercizi":null}
				<div class="table">
				{this.state.loading?
				<div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
					<img alt="loading..." src={"https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp"} />
				</div>
			:null}
    		{ 
				this.state.esercizi?
				this.state.esercizi.map(esercizio =>(
					<EsercizioInput key={esercizio.id} selected={esercizio} categorie={this.state.categorie} refresh={this.refresh}
					email={this.props.email} />
				)

				)
				:null
			}
			</div>
			</div>
	    );
	  }
	}

	const mapStateToProps = state => {
        return {
            palestra: state.gestoreUtente.palestraassociata.palestra,
            email: state.gestoreUtente.emailloggato,
            idanagselezionata: state.gestoreUtente.idanagselezionata
        }
    }
    
    export default connect(mapStateToProps, null)(ListaEserciziInput)