import React from "react";
import axios from 'axios';
import '../util/config'
import {connect} from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import InputText from "../util/UI/InputText";
import AttributionIcon from '@mui/icons-material/Attribution';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import AssignmentIcon from '@mui/icons-material/Assignment';

class ClienteAssociatoScheda extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            cerca:'',
                anagrafiche:[],
                id:'',
                visible:false,
                timeout: false,
                typingTimeout: 0,
                searching:false
	    	}
        this.cerca = this.cerca.bind(this);
        this.seleziona = this.seleziona.bind(this);
        this.conferma = this.conferma.bind(this);
	  }

	componentDidMount(){
       
    }

    cerca(e) {
        const self = this;
        this.setState({ [e.target.name]: e.target.value },e=>{
            
                if(this.timeout) clearTimeout(this.timeout);
                if (self.state.typingTimeout) {
                    clearTimeout(self.state.typingTimeout);
                 }
                
                this.timeout = setTimeout(() => {
                    if (this.state.cerca.length>=3){
                        this.setState({searching:true})
                        axios.post(global.config.server+"/cercaAnagraficaPerCognomeJSON?term="+this.state.cerca+"&palestra="+this.props.palestra.id)
                        .then(response=>{
                            this.setState({anagrafiche: response.data, searching:false});
                        });
                    }
                }, 1000);
       })
       
    }

    seleziona(e) {
       this.setState({ selezionato: e.target.value });
    }

    conferma(e) {
        this.props.associa(this.state.selezionato)
     }

	render() {
	    return (
            <Grid item container xs={12} >
                <Button title="Associa scheda a utenti" variant="outlined" onClick={() => this.setState({visible:true})}>
                    <AssignmentIcon /><KeyboardDoubleArrowRightIcon /><AttributionIcon />
                </Button>
                <Dialog open={this.state.visible} onClose={()=> this.setState({visible:false})} >
                    <DialogTitle>Associa questa scheda ad un cliente</DialogTitle>
                    <DialogContent sx={{padding:2}}>
                   <InputText label="Cerca anagrafica" type="text" name="cerca" onChange={this.cerca} value={this.state.cerca} placeholder="cerca un cliente"/>
                   <Grid item container xs={12}>
                   {this.state.anagrafiche&&this.state.anagrafiche.length>0&&!this.state.searching&&
                     <span>
                         
                   <select className="form-control" name="idselezionato" onChange={this.seleziona}>
                   <option value=""> --Seleziona un cliente-- </option>
                    {this.state.anagrafiche.map(anagrafica=>(
                       <option value={anagrafica.id+"___"+anagrafica.value}> {anagrafica.value}</option>
                       ))}
                   </select>
                   
                   </span>
                   }
                   </Grid>
                   {this.state.searching&&
                 
                 <Typography variant="caption">Attendere prego... sto cercando "{this.state.cerca}"...</Typography>
                }
                </DialogContent>
                <DialogActions>
                {this.state.anagrafiche&&this.state.anagrafiche.length>0&&<Button variant="contained" onClick={this.conferma}>associa</Button>}
                <Button title="Associa utente" variant="text" onClick={() => this.setState({visible:false})}>Close</Button>
                </DialogActions>
                </Dialog>
				</Grid>
	    );
	  }
	}

    const mapStateToProps = state => {
        return {
            palestra: state.gestoreUtente.palestraassociata.palestra,
            email: state.gestoreUtente.emailloggato
        }
    }
    
    export default connect(mapStateToProps, null)(ClienteAssociatoScheda)