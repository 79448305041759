import React from "react";
import axios from 'axios';
import { connect } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

class PagamentiSelect extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
              pagamenti:[]
      };
    }

    componentDidMount(){
      if (this.props.pagamenti){
        this.setState({pagamenti: this.props.pagamenti});
      }
      else{
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
	      formData.append('lic','000');
		    formData.append('palestra',this.props.palestra.id);
          //carica la lista degli abbonamento dell'utente
          axios.post(global.config.server+"/getPagamentiAbb/"+this.props.idabbonamento,formData)
        .then(response=>{
          this.setState({pagamenti: response.data});
          console.log(response);
        });
      }
    }
  
    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.log(error, info);
    }
  
    render() {
      return (
        <FormControl fullWidth variant="standard">
          <InputLabel>Associa a pagamento</InputLabel>
        
              <Select name="idpagamento" onChange={this.props.update}>
              {
                  this.state.pagamenti&&this.state.pagamenti.length>0?
                  this.state.pagamenti.map(pagamento =>(
                  <MenuItem key={pagamento.id} value={pagamento.id} selected={this.props.selezionato===pagamento.id}>{pagamento.dataPagamento + " " + pagamento.importo}</MenuItem>
                    )
                  )
                  :<MenuItem>Nessun pagamento presente</MenuItem>
              }
              </Select>
              </FormControl>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      palestra: state.gestoreUtente.palestraassociata.palestra,
      email: state.gestoreUtente.emailloggato,
      idanagselezionata: state.gestoreUtente.idanagselezionata
    }
  }
  
  export default connect(mapStateToProps, null)(PagamentiSelect)