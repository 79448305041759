import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useSelector } from "react-redux";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Home from '@mui/icons-material/Home';
import { navigate } from 'hookrouter'

export default function BottomMenu() {
  const [value, setValue] = React.useState(0);
  const palestraassociata = useSelector(state => state.gestoreUtente.palestraassociata);
  const menuadmin = useSelector(state => state.gestoreMenu.menuadmin);

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, paddingBottom: 0, zIndex: 100, overflow: 'hidden' }} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >

        <BottomNavigationAction label={"Home"}
          sx={{ display: { xs: 'none' } }}
          onClick={() => navigate('/annotatio/')}
          icon={<Home />} />

        {menuadmin && Array.isArray(menuadmin) && menuadmin.length > 0 &&
          menuadmin.map(menuitem =>
            menuitem.link&&<BottomNavigationAction
            sx={{fontSize:'1.5em'}}
              onClick={() => navigate(menuitem.link)}
              icon={<i className={menuitem.faiconclass} />} />
          )

        }


        {/* {palestraassociata && palestraassociata.funzionalita && palestraassociata.funzionalita.includes('CLIENTI') &&
          <BottomNavigationAction

            onClick={() => navigate('/annotatio-anag/')}
            icon={<PersonSearchIcon />} />}

        {palestraassociata && palestraassociata.funzionalita && palestraassociata.funzionalita.includes('CALENDARI') &&
          <BottomNavigationAction
            onClick={() => navigate('/annotatio-prods/')}
            icon={<CalendarMonth />} />}
        {palestraassociata && palestraassociata.funzionalita && palestraassociata.funzionalita.includes('SCHEDE') &&
          <BottomNavigationAction
            onClick={() => { navigate('/annotatio-sa/'); }}
            icon={<FitnessCenter />}
          />
        } */}

        {/* {palestraassociata && palestraassociata.funzionalita && palestraassociata.funzionalita.includes('ORDINI') &&
          <BottomNavigationAction key={"Ordini"}
            onClick={() => { navigate('/annotatio-ordini/'); }}
            icon={<StickyNote2Icon />}
          />}

        {palestraassociata && palestraassociata.funzionalita && palestraassociata.funzionalita.includes('FATTURE') &&
          <BottomNavigationAction
            onClick={() => { navigate('/annotatio-fatt/'); }}
            icon={<ReceiptLongIcon />}
          />}
        {palestraassociata && palestraassociata.funzionalita && palestraassociata.funzionalita.includes('REPORT') &&
          <BottomNavigationAction
            onClick={() => { navigate('/annotatio-dashboard/'); }}
            icon={<AssessmentIcon />}
          />}

        {palestraassociata && palestraassociata.funzionalita && palestraassociata.funzionalita.includes('MESSAGGI') &&
          <BottomNavigationAction
            onClick={() => { navigate('/anntt-adm-msg/'); }}
            icon={<Message />}
          />} */}
      </BottomNavigation>
    </Paper>
  );
}