import React from "react";
import axios from 'axios';
import '../util/config'
import Collaboratore from "./Collaboratore";
import { useState, useEffect } from "react";
import {useSelector} from "react-redux"

export default function Collaboratori (props){
    const palestraassociata = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

    const [collaboratori, setcollaboratori]=useState([]);
    const [moduli, setmoduli]=useState([]);
    const [amministratore, setamministratore]=useState(false);
    const [emailNuovoCollaboratore, setemailcollab]=useState('');
    const [nome, setnome] = useState('');
    const [telefono, settelefono] = useState('');
    const [address, setaddress] = useState('');
    const [city, setcity] = useState('');
    
  
  useEffect(() =>{
    if (palestraassociata&&palestraassociata.palestra&&palestraassociata.palestra.id){
	const formData = new FormData();
	formData.append('website', global.config.domain);
    formData.append('email',email);
    formData.append('lic','000');
    formData.append('palestra',palestraassociata.palestra.id);
    axios.post(global.config.server+"/collaboratori", formData)
	  .then((response)=>{
        console.log(response);
          axios.post(global.config.server+"/getModuli", formData)
          .then((response2)=>{
            setcollaboratori(response.data);
            response.data.map(coll=>{
                if (coll.utente.email===email
                &&coll.amministratore===true)
                {
                    setamministratore(true)
                }
            })
		    setmoduli(response2.data);
		    console.log(response2);
            
		    
        }).catch(err => {
            if (err.response){
                alert(err.response.data.message);
            }
            else{
                alert(err.message)
            }
        });
    }).catch(err => {
        if (err.response){
            alert(err.response.data.message);
        }
        else{
            alert(err.message)
        }
    });
}
  },[palestraassociata, email]);

  function nuovoCollaboratore(e){
      e.preventDefault()
      if (window.confirm('Sei sicuro di voler avere un nuovo collaboratore?')){
            const formData = new FormData();
            formData.append('website', global.config.domain);
            formData.append('email',email);
            formData.append('lic','000');
            formData.append('palestra',palestraassociata.palestra.id);
            formData.append('emailCollaboratore',emailNuovoCollaboratore);

            axios.post(global.config.server+"/nuovoCollaboratore", formData)
            .then((response)=>{
                alert(response.data)
                //window.location.reload();
            }).catch(err => {
                if (err.response){
                    alert(err.response.data.message);
                }
                else{
                    alert(err.message)
                }
            });
        }
  }

  function cercaESalvaNuovoCollaboratore(e){
    e.preventDefault()
    if (window.confirm('Sei sicuro di voler avere un nuovo collaboratore?')){
          const formData = new FormData();
          formData.append('website', global.config.domain);
          formData.append('email',email);
          formData.append('lic','000');
          formData.append('palestra',palestraassociata.palestra.id);
          formData.append('emailnuovocollaboratore',emailNuovoCollaboratore);
          formData.append('name',nome);
          formData.append('telefono',telefono);
          formData.append('address',address);
          formData.append('city',city);

          axios.post(global.config.server+"/nuovoCollaboratore", formData)
          .then((response)=>{
              alert(response.data)
              //window.location.reload();
          }).catch(err => {
              if (err.response){
                  alert(err.response.data.message);
              }
              else{
                  alert(err.message)
              }
          });
      }
}

    return (
        <span>
            <table>
                <thead>
                    <tr><th>ID</th>
                    <th>Name</th>
                    <th>Nome</th>
                    <th>email</th>
                    <th>tel</th>
                    <th>Moduli</th>
                    <th>Amministratore</th>
                    <th></th>
                    <th></th></tr>
                </thead>
                <tbody>
             {
                collaboratori&&collaboratori.length>0?
                collaboratori.map(collaboratore =>(
                    <Collaboratore key={collaboratore.id} email={email} palestra={palestraassociata.palestra} collaboratore={collaboratore} moduli={moduli} amministratore={amministratore} />
                ))
                :"nessun collaboratore trovato"
            }
            </tbody>
            </table>
            {amministratore?
            <span>
            <form method="post" onSubmit={nuovoCollaboratore}>
            <fieldset>
            <legend>Nuovo collaboratore</legend>
            Cerca per email di registrazione dell'utente 
            <input type="email" name="emailNuovoCollaboratore" required={true} onChange={(e) => setemailcollab(e.target.value) } />
            <button type="submit" name="cercautente" value="1" class="btn btn-primary">Cerca e associa</button>
            </fieldset>
            </form>
            <form method="post" onSubmit={cercaESalvaNuovoCollaboratore}>
            <fieldset>
            <legend>Oppure inserisci i dati anagrafici</legend>
            <label>Email
            <input type="email" name="emailnuovocollaboratore" required={true} onChange={(e) => setemailcollab(e.target.value) } />
            </label>
            <label>Nome e cognome
            <input type="text" name="nome" value={nome} required={true} onChange={(e) => setnome(e.target.value)} />
            </label>
            <label>Telefono
            <input type="text" name="telefono" value={telefono} onChange={(e) => settelefono(e.target.value)} />
            </label>
            <label>Indirizzo
            <input type="text" name="address" value={address} onChange={(e) => setaddress(e.target.value)} />
            </label>
            <label>Citta
            <input type="text" name="city" onChange={(e) => setcity(e.target.value)} />
            </label>
            <button type="submit" name="creautente" value="1" class="btn btn-primary">Salva</button>
            </fieldset>
            </form>
            </span>
        :null}
            </span>
    );
}