import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import { getTessereByAnag, salvatesseraanag } from "../DAO/tessereDAO";
import RigaTessereAnagrafica from "./RigaTessereAnagrafica";

export default function TessereAnagrafica(){
    const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata);
    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata);

    const [tessere, settessere] = useState([])
    const [numero, setnumero] = useState()
    const [validoda, setvalidoda] = useState()
    const [validoa, setvalidoa] = useState()
    const [messaggio, setmessaggio] = useState()

    function caricatessere(){
        async function gettessereanag(){
            const result = await getTessereByAnag(idanagselezionata)
            settessere(result)
        }
        gettessereanag();
    }

    function salvatesseramento(){
        async function salvatessereanag(){
            const result = await salvatesseraanag(0,idanagselezionata, numero, validoda, validoa)
            if (result&&result.id>0){
                setmessaggio("Tessera modificata correttamente")
                caricatessere()
            }
            else{
                setmessaggio("Si e' verificato un problema durante la modifica della tessera")
            }
        }
        if (!numero||numero===''){
            setmessaggio('numero obbligatorio')
            return
        }
        
        if (!validoda||validoda===''){
            setmessaggio('Valido DA obbligatorio')
            return
        }
        if (!validoa||validoa===''){
            setmessaggio('Valido A obbligatorio')
            return
        }
        salvatessereanag();
    }

    useEffect(() =>{
        caricatessere();
    },[idanagselezionata])

    return (
        <div>
            <h3>Tesseramenti {anagselezionata.nome} {anagselezionata.cognome}
            </h3>
            <div className="sezione">
                <h4>Nuovo tesseramento</h4>
                <label>Numero
                <input type="text" name="numero" value={numero} onChange={(e) => setnumero(e.target.value)} />
                </label>
                <label>Valido Da
                <input type="date" name="validoda" value={validoda} onChange={(e) => setvalidoda(e.target.value)} />
                </label>
                <label>Valido A
                <input type="date" name="validoa" value={validoa} onChange={(e) => setvalidoa(e.target.value)} />
                </label>
                <button onClick={() => salvatesseramento()}>Inserisci</button>
                <b>{messaggio}</b>
            </div>
            <table className="table">
                <thead>
                    <tr>
                    <th>Nome</th>
                    <th>Cognome</th>
                    <th>Data nascita</th>
                    <th>Numero</th>
                    <th>Valida dal</th>
                    <th>Valida al</th>
                    <th>Salva</th>
                    <th>Elimina</th>
                    </tr>
                </thead>
                <tbody>
            {tessere&&Array.isArray(tessere)&&tessere.length>0&&
            tessere.map(tessera => 
           <RigaTessereAnagrafica tessera={tessera} caricatessere={caricatessere} />
                )}
                </tbody>
            </table>
        </div>
    );
}
