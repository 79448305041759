import React from "react";
import '../../util/config'
import axios from 'axios';
import Swal from "sweetalert2";
import { connect } from "react-redux";
import InputText from "../../util/UI/InputText";
import { Button } from "@mui/material";
import Delete from "@mui/icons-material/Delete";

class ScadenzaRataInput extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: this.props.selected.id,
	    		giorni: this.props.selected.giorni,
	    		rata: this.props.selected.rata,
	    		idPeriodo: this.props.periodo,
	    		visible: false,
	    		messaggio:null
	    	}

	    this.update = this.update.bind(this);
	    this.elimina = this.elimina.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	  }

	update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }

	  handleSubmit(event) {
		event.preventDefault();
	    const { id,rata, giorni, idPeriodo} = this.state;

	    const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
		  formData.append('id', id);
		formData.append('rata', rata);
		formData.append('giorni', giorni);
		formData.append('idPeriodo', idPeriodo);
		
	      axios.post(global.config.server+'/aggiornaScadenza',formData)
	      .then((result) => {
				this.setState({messaggio:result.data.message});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data.message
					})
		          this.props.refresh();
		          this.setState({ visible: false });
	      });
	  }

	  elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare il periodo selezionato?')){
			const { id,idPeriodo} = this.state;
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
		  formData.append('id', id);
			formData.append('idPeriodo', idPeriodo);
			  axios.post(global.config.server+'/rimuoviScadenza',formData)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data.message
						})
			        this.props.refresh();
			          this.setState({ visible: false });
			        });
			}
	  }

	render() {
		var style1={
				color:"white"
		}
	    return (
	    		<div className="row">
					<div className="col-sm-12">
						<span>{this.state.messaggio}</span>
						<form className="form-horizontal">
						<div className="row">
						<input type="hidden" name="id" value={this.state.id} />
						<div className="col">
							
							<InputText label="Num. Giorni scadenza rata" type="text" onChange={this.update} name="giorni" value={this.state.giorni} required="required" />
						</div>
						<div className="col">
							 <InputText label="Importo rata %" type="number" onChange={this.update} name="rata" value={this.state.rata} required="required" />
						</div>
						{this.state.visible?
								<Button onClick={this.handleSubmit}>Salva modifiche</Button>
				  				
				  			:null}
							<div className="col">
								<Button onClick={this.elimina} color="error">
									<Delete />
								</Button>
							</div>
						</div>
						</form>
					</div>
				</div>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
		  palestra: state.gestoreUtente.palestraassociata.palestra,
		  email: state.gestoreUtente.emailloggato,
		  idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	  }
	  
	  export default connect(mapStateToProps, null)(ScadenzaRataInput)