import React from "react";
import axios from 'axios';
import '../util/config'
import { connect } from "react-redux";
import PersonSearch from "@mui/icons-material/PersonSearch";
import InputText from "../util/UI/InputText";
import { FormControl, Grid, InputAdornment, MenuItem, Select } from "@mui/material";

class UtentiFilterSelect extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { 
                anagrafiche:[],
                timeout: false,
                typingTimeout: 0
        };
        this.cercaAnagrafica = this.cercaAnagrafica.bind(this);
      }

      componentDidMount(){
      }

      cercaAnagrafica(e){
        this.setState({ [e.target.name]: e.target.value},()=>{
        if (e.target.value.length>=3){
            if(this.timeout) clearTimeout(this.timeout);

             this.timeout = setTimeout(() => {
                this.setState({disabled:true})
                const formData = new FormData();
                formData.append('website', global.config.domain);
                formData.append('email',this.props.email);
                formData.append('lic','000');
                formData.append('term',e.target.value);
                formData.append('palestra',this.props.palestra.id);
                axios.post(global.config.server+'/cercaAnagraficaPerCognomeJSON',formData)
                .then((result) => {
                    console.log(result.data)
                    this.setState({disabled:false})
                    this.setState({anagrafiche:result.data});
            }).catch(err => {
                if (err.response){
            alert(err.response.data.message);
                }
                else{
                    alert(err)
                }
            });
            
            }, 1000);
                
        }
        })
	  }
    
render() {
    return (
        <Grid item container xs={12} padding={2}>
            <InputText label="Cerca una anagrafica" type="text" 
            InputProps={{
                startAdornment: <InputAdornment position="start"><PersonSearch /></InputAdornment>,
            }}
            
            name="filtro" onChange={this.cercaAnagrafica} placeholder="Cerca anagrafica" disabled={this.state.disabled}/>
            {this.state.disabled&&"Attendere..."}
            
            {this.state.anagrafiche&&Array.isArray(this.state.anagrafiche)&&this.state.anagrafiche.length>0&&
            <FormControl fullWidth variant="standard">
            <div>seleziona una anagrafica dalla lista</div>
            
            <Select label="Seleziona" defaultValue={""} name="anagrafica" onChange={this.props.onChange}>
            
                {this.state.anagrafiche?
                this.state.anagrafiche.map(anag=>(
                <MenuItem value={anag.id}>{anag.value}</MenuItem>
                ))
                :<MenuItem>Nessuna anagrafica trovata</MenuItem>
                }
            </Select>
            
            </FormControl>
            
            }
            {
             (!this.state.anagrafiche||this.state.anagrafiche.length===0) &&
             <div>Nessun utente selezionato</div>
            }
            
        </Grid>
        );
  }
}

const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraassociata.palestra,
        email: state.gestoreUtente.emailloggato
    }
}

export default connect(mapStateToProps, null)(UtentiFilterSelect)