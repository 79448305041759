import React, { useEffect, useState } from "react";
import '../../util/config'
import TipologiaInput from "./TipologiaInput";
import NuovaTipologiaInput from "./NuovaTipologiaInput";
import { useSelector, useDispatch } from "react-redux";
import { getTipi } from "../../DAO/tipiprodottiDAO";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from "@mui/material";

export default function TipologieCorso(props)  {
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [tipi, settipi] = useState([]);
    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
    const [expanded, setExpanded] = useState(false);

    const dispatch = useDispatch();
	
	const handleChange =
    (idtipo, panel, isExpanded) => {
	  setExpanded(isExpanded ? idtipo : false);
    };

	function gettipi(){
        async function gettipiprod(){
            if (palestra&&palestra.id){
                console.log("palestra NON nulla")
                const result=await getTipi(palestra.id, '', '', '');
                //console.log(result);
                settipi(result);
            }
        }
        gettipiprod();
    }

    useEffect(() => {
        console.log("useEffect")
        gettipi();
    },[palestra, keytorefresh])

    return (
    		<Grid item container xs={12}>
                <Grid item container xs={12}>
                <NuovaTipologiaInput setkeytorefresh={setkeytorefresh} />
                </Grid>
    		{
    			tipi.map(item =>(
                   
                            <TipologiaInput key={item.id} selected={item} 
                            setkeytorefresh={setkeytorefresh} />
    					)
    			)
    		}
    		</Grid>
    );
}