import React from "react";
import '../../util/config'
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default class TipologieAbbonamentoSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    		selezionato:this.props.selected
    };
    this.aggiornaSelezionato = this.aggiornaSelezionato.bind(this);
  }

	componentDidMount(){

	}
  
	aggiornaSelezionato(e){
		 this.props.updateTipo(this.props.listaTipologie[e.target.selectedIndex-1].id);
	 }

  render() {
    return (
		<FormControl fullWidth={true} variant="standard">
        <InputLabel>Tipo Abbonamento</InputLabel>
       <Select
       fullWidth={true}
       size="small"
    
	  onChange={this.aggiornaSelezionato} value={this.props.selected?this.props.selected:""}>
				
					{
						this.props.listaTipologie.map((item,index) =>(
						<MenuItem key={index} value={item.id} >{item.descrizione}</MenuItem>
						)
		    			)
		    		}
				</Select>
			</FormControl>
    );
  }
}