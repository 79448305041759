import React from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import {connect} from 'react-redux'
import { navigate } from 'hookrouter'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ControlPointIcon from '@mui/icons-material/ControlPoint';


class NuovaSchedaSA extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
                validoDa:'',
                descrizione:'',
                template:true,
                visible:true
	    	}
        this.update = this.update.bind(this);
        this.salvaScheda = this.salvaScheda.bind(this);
	  }

	componentDidMount(){
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
        axios.post(global.config.server+"/getListaTemplate", formData)
        .then(response=>{
            this.setState({templates: response.data});
        })
    }

    salvaScheda(e) {
        e.preventDefault()
        const { descrizione,validoDa, template, templateSelezionato } = this.state;

	    const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
	    formData.append('descrizione', descrizione);
		formData.append('schedaDataDa', validoDa);
        formData.append('template', template===true?1:0);
        formData.append('templateSelezionato', templateSelezionato?templateSelezionato:"");

	      axios.post(global.config.server+'/inserisciSchedaSalaAttrezzi',formData)
	      .then((result) => {
            console.log("risposta dopo inserimento scheda: "+result)
              if(result.data.message){
                //window.location.href = "/dettaglioSchedaSalaAttrezzi?idScheda="+result.data.idNuovoOggetto;
                localStorage.setItem('idscheda',result.data.idNuovoOggetto);
                //this.props.setview('dettschedaSA');
                navigate('/anntt-adm-dettsa/',true);
                
              }
              else{
		        this.setState({messaggio:result.data.errore});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'error',
					  title: result.data.errore
                    })
                }
            });
    }

	update(e) {
        if (e.target.name==='template'){
            if (e.target.checked) {
                this.setState({ [e.target.name]: true});
            }
            else{
                this.setState({ [e.target.name]: false});
            }
        }
        else{
          this.setState({ [e.target.name]: e.target.value});
        }
        if (e.target.name==='templateSelezionato'){
            if (e.target.value===null||e.target.value==='') {
                this.setState({visible:true})
            }
            else{
                this.setState({visible:false})
            }
        }
          
    }
    
	render() {
	    return (
            <span>
                <button title="Nuova scheda" alt="Nuova scheda" type="button" className="btn btn-primary" data-toggle="modal" data-target="#nuovascheda">
                    <ControlPointIcon /> <FitnessCenterIcon />
			    </button>
			<div className="modal fade" id="nuovascheda" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Inserimento Nuova Scheda</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row form-group">
                                <label>Crea una nuova scheda a partire da un template: </label>
                                <select name="templateSelezionato" onChange={this.update} value={this.state.templateSelezionato}>
                                    <option value="">--seleziona un template--</option>
                                    {this.state.templates&&this.state.templates.length>0?
                                    this.state.templates.map(template=>(
                                    <option key={template.idscheda} value={template.idscheda}>{template.descrizione}</option>
                                    ))
                                    :null}
                                </select>
                            </div>
                            {this.state.visible?
                            <span>
                            <div style={{"width": '100%', 'height': '10px', 'borderBottom': '1px solid black', 'textAlign': 'center'}}>
                            <span style={{'fontSize': '15px', 'backgroundColor': '#FFFFFF', 'padding': '0 5px'}}>
                               oppure inserisci i dati
                            </span>
                            </div>
                            <div className="row">&nbsp;</div>
                            <div className="row form-group">
                                <div className="col form-check">
                                    <input id="template" className="form-check-input" type="checkbox" name="template" value="1" onChange={this.update} checked={this.state.template&&this.state.template===true?true:false} />
                                    <label htmlFor="template" className="form-check-label">Usa questa scheda come predefinita</label>
                                </div>
                                <div className="col">
                                    <label>Validità da</label>
                                    <input className="form-control" style={{width: 'auto'}} type="date" name="validoDa" onChange={this.update} value={this.state.validoDa} />
                                </div>
                            </div>
                            <div className="row form-group">
                                <label>Descrizione</label>
                                <textarea required={true} className="form-control" name="descrizione" onChange={this.update} value={this.state.descrizione}></textarea>
                            </div>
                            </span>
                            :null}
    			
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" >Chiudi</button>
                                <button className="btn btn-info" onClick={this.salvaScheda} data-dismiss="modal">Avanti</button>
                            </div>
                        </div>
                </div>
                </div>
                </div>
                </span>
	    );
	  }
	}

    const mapStateToProps = state => {
        return {
            palestra: state.gestoreUtente.palestraassociata.palestra,
            email: state.gestoreUtente.emailloggato
        }
    }
    
    export default connect(mapStateToProps, null)(NuovaSchedaSA)