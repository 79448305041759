import React from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import '../util/config'
import ListinoSelect from "../archivi/listino/ListinoSelect";
import ScontiSelect from "../archivi/sconti/ScontiSelect";
import { connect } from "react-redux";
import { Button, Grid, InputAdornment } from "@mui/material";
import InputText from "../util/UI/InputText";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

class AbbonamentoEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      dataOperazione: null,
      prezzo: 0,
      prezzoIscrizione: 0,
      validoDa: "",
      validoA: "",
      dataScadenzaPagamento: "",
      prezzoListino: 0,
      listino: null,
      palestra: 0,
      sconto: null,
      idsconto: '',
      fatture: [],
      valoreSconto: 0
    };
    this.update = this.update.bind(this)
    this.aggiornaIscrizione = this.aggiornaIscrizione.bind(this)
    this.calcolaTotale = this.calcolaTotale.bind(this);
  }

  componentDidMount() {
    this.setState({ ...this.props.abbonamento }, () => (
      this.setState({
        listino: this.state.listino,
        prezzoListino: this.state.listino ? this.state.listino.prezzoSenzaIscrizione : null,
        idsconto: this.state.sconto ? this.state.sconto.id : '',
        idlistino: this.state.listino ? this.state.listino.id : '',
        valoreSconto: this.state.sconto ? this.state.sconto.valoreSconto : 0
      })
    ))
  }

  calcolaTotale() {
    var tot = (parseFloat(this.state.prezzoIscrizione) + parseFloat(this.state.prezzoListino));
    var sconto = this.state.valoreSconto ? tot * parseFloat(this.state.valoreSconto) / 100 : 0
    this.setState({ prezzo: tot - sconto })
  }

  update(e) {
    if (e.target.name === 'listino') {
      this.setState({ idlistino: e.target.value })
      if (this.props.listini) {
        var result = this.props.listini.find(obj => {
          return obj.id === Number(e.target.value)
        })

        this.setState({ prezzoListino: result ? result.prezzo : 0 }, () => (
          this.calcolaTotale()
        ))
      }
    }
    else if (e.target.name === 'sconto') {
      this.setState({ idsconto: e.target.value })
      if (this.props.sconti) {
         result = this.props.sconti.find(obj => {
          return obj.id === Number(e.target.value)
        })
        var scontistica = 0;
        if (result) {
          scontistica = result.valoreSconto;
        }
        this.setState({ valoreSconto: scontistica }, () => (
          this.calcolaTotale()
        ))
      }
    }
    else if (e.target.name === 'prezzoIscrizione') {
      var iscrizione = 0
      if (!e.target.value || e.target.value === '') {
        iscrizione = 0;
      }
      else {
        iscrizione = e.target.value;
      }
      this.setState({ prezzoIscrizione: iscrizione }, () => (
        this.calcolaTotale()
      ));

    }
    else {
      this.setState({ [e.target.name]: e.target.value, visible: true });
    }
  }

  aggiornaIscrizione() {
    console.log('EMAIL: ' + this.props.email)
    const { id, validoDa, validoA, prezzo, idlistino, prezzoIscrizione, idsconto, dataScadenzaPagamento, iscrizione } = this.state;
    const formData = new FormData();
    formData.append('website', global.config.domain);
    formData.append('email', this.props.email);
    formData.append('lic', '000');
    formData.append('palestra', this.props.palestra.id);
    formData.append('id', id);
    formData.append('validoDa', validoDa);
    formData.append('validoA', validoA);
    formData.append('prezzo', prezzo);
    formData.append('listino', idlistino);
    formData.append('prezzoIscrizione', prezzoIscrizione);
    formData.append('sconto', idsconto);
    formData.append('dataScadenzaPagamento', dataScadenzaPagamento);
    formData.append('idiscrizione', iscrizione.id);//TODO
    axios.post(global.config.server + '/aggiornaAbbonamento',
      formData
    )
      .then((result) => {
        this.setState({ messaggio: result.data });
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false
        });
        Toast.fire({
          type: 'success',
          title: result.data
        })
        this.setState({ visible: false });
        this.props.refresh();
      })
      .catch(err => {
        alert(err.response.data.message);
      });
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    return (
      <Grid item container xs={12}>
        <Grid item container xs={12}>
          <Grid item container xs={12} md={3}>
            <ListinoSelect email={this.props.email}
              idpalestra={this.props.idpalestra}
              selected={this.state.idlistino ? this.state.idlistino : ""}
              listini={this.props.listini}
              onChange={this.update} />
          </Grid>
          <Grid item container xs={6} md={6}>
            <InputText
              label="Abbonamento valido da"

              placeholder="Valido da"
              value={this.state.validoDa}
              name="validoDa"
              type="date"
              onChange={this.update}
            />
          </Grid>
          <Grid item container xs={6} md={3}>
            <InputText
              label="Abbonamento valido a"
              placeholder="Valido A"
              value={this.state.validoA}
              name="validoA"
              type="date"
              onChange={this.update}
            />
          </Grid>

          <Grid item container xs={6} md={3}>
            <InputText
              label="Scadenza pagamento"
              placeholder="Valido da"
              value={this.state.dataScadenzaPagamento}
              name="dataScadenzaPagamento"
              onChange={this.update}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item container xs={6} md={3}>
            <InputText label="Costo iscrizione"
              placeholder="Prezzo"
              value={this.state.prezzoIscrizione}
              name="prezzoIscrizione"
              type="number"
              onChange={this.update}
              InputProps={{
                startAdornment: <InputAdornment position="start">&euro;</InputAdornment>,
                    }}
            />
          </Grid>
          <Grid item container xs={6} md={3}>

            <InputText label="Prezzo da listino"
              placeholder="Prezzo da listino"
              value={this.state.prezzoListino}
              type="number"
              disabled
              InputProps={{
                startAdornment: <InputAdornment position="start">&euro;</InputAdornment>,
                    }}
            />
          </Grid>
          <Grid item container xs={12} md={3}>

            <ScontiSelect email={this.props.email} selected={this.state.idsconto ? this.state.idsconto : ""} onChange={this.update} />
          </Grid>
          <Grid item container xs={12} md={3}>
            <InputText label=" Da pagare"
              id="inlineFormInput"
              placeholder="Da pagare"
              type="number"
              value={(this.state.prezzo)}
              name="prezzo"
              onChange={this.update}
              InputProps={{
                startAdornment: <InputAdornment position="start">&euro;</InputAdornment>,
                    }}
            />

          </Grid>
          <Grid item container xs={12} md={6} sx={{display:'flex'}}>
            <Button variant="contained" onClick={this.aggiornaIscrizione}>
            <SaveIcon /> Aggiorna Abbonamento
            </Button>
            <Button variant="contained" color="error" onClick={this.props.eliminaAbbonamento}>
                  <DeleteIcon /> Elimina abbonamento
                </Button>
          </Grid>

        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    palestra: state.gestoreUtente.palestraassociata.palestra,
    email: state.gestoreUtente.emailloggato,
    idanagselezionata: state.gestoreUtente.idanagselezionata
  }
}

export default connect(mapStateToProps, null)(AbbonamentoEdit)