import { TextField } from "@mui/material";
import React from "react";

export default function InputText({ label, id, type, 
    placeholder, name, value, onChange, step, align, 
    disabled, width, multiline, required, 
    inputProps, autoFocus,
    checkedc, variant, onClick, handleSubmit,InputProps,
defaultValue }) {

    return (
        <TextField
        
        autoFocus={autoFocus?autoFocus:false}
            InputProps={InputProps}
            inputProps={inputProps}
            onKeyDown={(event) => {
                if (event.key === 'Enter'&&handleSubmit) {
                    handleSubmit(event)
                }
            }}
            variant={variant ? variant : "standard"}
            fullWidth
            label={label}
            defaultValue={defaultValue?defaultValue:""}
            type={type}
            align={align}
            style={{
                width: width ? width : '100%', margin: 0, padding: 0,
                "&.Mui-disabled": {                        
                    color: "#000000 !important"
                  },
            }}
            autoCapitalize
            sx={{textTransform:'capitalize'}}
            step={step ? step : 1}
            id={id ? id : Math.floor(Math.random() * 100)}
            multiline={multiline ? multiline : false}
            required={required ? required : false}
            placeholder={placeholder}
            name={name} value={value}
            onChange={onChange}
            onClick={onClick}
            checked={checkedc === true ? true : false}
            disabled={disabled ? disabled : false} 
            />
    );
}