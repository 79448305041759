import React from "react";
import {  Button, } from 'react-bootstrap';
import axios from 'axios';
import Swal from "sweetalert2";
import '../util/config'
import { connect } from "react-redux";
import { FormControlLabel, Grid, InputAdornment, Switch } from "@mui/material";
import InputText from "../util/UI/InputText";
import SaveIcon from '@mui/icons-material/Save';

class AbbonamentoUtente extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
            id:null,
            dataOperazione: null,
            prezzo: 500,
            prezzoIscrizione: 0,
            validoDa: "",
            validoA: "",
            dataScadenzaPagamento: "",
            idlistino: "",
            palestra: 0,
            fatture:[],
            istesserato:false,
      };
      this.update=this.update.bind(this)
      this.aggiornaIscrizione=this.aggiornaIscrizione.bind(this)
    }

    componentDidMount(){
        this.setState({...this.props.iscrizione})
    }

    update(e) {
      console.log(e.target.name+"="+e.target.value)
      if (e.target.name==='istesserato'){
        if (e.target.checked) {
            this.setState({ [e.target.name]: 1, visible: true });
        }
        else{
            this.setState({ [e.target.name]: 0, visible: true });
        }
      }
      else{
          this.setState({ [e.target.name]: e.target.value, visible: true });
      }
    }
    
    aggiornaIscrizione(){
        const { id,dataiscrizione,datascadenza,prezzo,datavaliditacertificatomedico,note} = this.state;
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
        formData.append('id', id);
	    formData.append('dataiscrizione',dataiscrizione);
		formData.append('datascadenza', datascadenza);
		formData.append('prezzo', prezzo);
		formData.append('datavaliditacertificatomedico', datavaliditacertificatomedico);
		formData.append('note', note);
    formData.append('numerotessera', this.state.numerotessera?this.state.numerotessera:'');
    formData.append('istesserato', this.state.istesserato?this.state.istesserato:0);
    formData.append('datatesserada', this.state.datatesserada?this.state.datatesserada:'');
    formData.append('datatesseraa', this.state.datatesseraa?this.state.datatesseraa:'');

      axios.post(global.config.server+'/salvaIscrizione', 
      		formData
      )
        .then((result) => {
        this.setState({messaggio:result.data});
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
          this.setState({ visible: false });
          this.props.refresh();
        }).catch(err => {
          console.log(err);
          if (err.response&&err.response.data){
			      alert(err.response.data.message);
          }
		});
	}
  
    componentDidCatch(error, info) {
          // You can also log the error to an error reporting service
          console.log(error, info);
        }
  
    render() {
      return (
            <Grid item container xs={12}>
                <Grid item container xs={6} md={3}>
                 <InputText label="Iscrizione valida da"
                   
                    placeholder="Data inizio iscrizione"
                    value={this.state.dataiscrizione}
                    name="dataiscrizione"
                    type="date"
                    onChange={this.update}
                  />
                </Grid>
                <Grid item container xs={6} md={3}>
                <InputText label="Iscrizione valida a"
                    placeholder="Data scadenza"
                    value={this.state.datascadenza}
                    name="datascadenza"
                    type="date"
                    onChange={this.update}
                  />
                </Grid>
                <Grid item container xs={6} md={3}>
                <InputText label="Prezzo"
                    placeholder="Prezzo"
                    value={this.state.prezzo}
                    name="prezzo"
                    type="number"
                    onChange={this.update}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">&euro;</InputAdornment>,
                          }}
                  />
                </Grid>
                <Grid item container xs={6} md={3}>
                 <InputText
                 label="Validità Certificato medico"
                    placeholder="Data scadenza cert. med."
                    value={this.state.datavaliditacertificatomedico}
                    onChange={this.update}
                    type="date"
                    name="datavaliditacertificatomedico"
                  />
                </Grid>
                <Grid item container xs={12}>
                  <InputText label="Note"
                    placeholder="Note"
                    value={this.state.note}
                    name="note"
                    onChange={this.update}
                    multiline={true}
                    
                  />
                </Grid>
                <Grid item container xs={12}>
                <Grid item container xs={6} md={3}>
                 
        <FormControlLabel control={
          <Switch defaultChecked={false} 
          checked={this.state.istesserato===true||this.state.istesserato===1||this.state.istesserato==="1"?true:false} 
          value={1} 
          name="istesserato"
          onChange={this.update} />} label={this.state.istesserato!==1?"Non Tesserato":"Tesserato"} />

                </Grid>
                <Grid item container xs={6} md={3}>
                
                    <InputText label="Numero tessera" type="text" name="numerotessera" value={this.state.numerotessera} onChange={this.update} />
                  
                </Grid>
                <Grid item container xs={6} md={3}>
                    <InputText label="Tessera valida DA" type="date" name="datatesserada" value={this.state.datatesserada} onChange={this.update} />
                 </Grid>
                 <Grid item container xs={6} md={3}>
                    <InputText label="Tessera valida A" type="date" name="datatesseraa" value={this.state.datatesseraa} onChange={this.update} />
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={3}>
                <Button variant="primary" onClick={this.aggiornaIscrizione}>
                  <SaveIcon />Modifica Iscrizione
                </Button>
                </Grid>
            </Grid>
      );
    }
  }

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato,
			idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	}
	
	export default connect(mapStateToProps, null)(AbbonamentoUtente)