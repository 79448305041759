import {createSlice} from '@reduxjs/toolkit';

export const gestoreMenuSlice = createSlice({
  name: 'gestoreMenu',
  initialState: {
    menuadmin:[],
    menuadminlaterale:[],
    menucliente:[],
  },
  reducers: {
    setmenuadmin: (state, menu) => {
      state.menuadmin =  null;
      state.menuadmin =  menu.payload;
    },
    setmenuadminlaterale: (state, menu) => {
      state.menuadminlaterale =  null;
      state.menuadminlaterale =  menu.payload;
    },
    setmenucliente: (state, menu) => {
      state.menucliente =  null;
      state.menucliente =  menu.payload;
    },
  },
});

export const {setmenuadmin,setmenuadminlaterale, setmenucliente} =
  gestoreMenuSlice.actions;

export default gestoreMenuSlice.reducer;
