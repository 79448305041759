import { useState } from 'react';
import { useEffect } from 'react';
import ViewCalendario from './view/ViewCalendario';
import RicercaSchedaCC from './schedasalaattrezzi/RicercaSchedaCC';
import RicercaSchedaSA from './schedasalaattrezzi/RicercaSchedaSA';
import DettaglioScheda from './schedasalaattrezzi/DettaglioScheda';
import ListaSchedeCompCorp from './schedasalaattrezzi/composizionecorporea/ListaSchedeCompCorp';
import ListaEserciziInput from './schedasalaattrezzi/eserciziSalaAttrezzi/ListaEserciziInput';
import ToolbarSchede from './toolbar/ToolbarSchede';

function GestioneSchede(props) {
  const [viewtoshow, setViewtoshow] = useState( 'annotatiohome' );
  const [email, setEmail] = useState( '' );

  useEffect(()=>{
    if (props.email){
      setEmail(props.email)
    }
    else{
      console.log('no email')
    }
  },[]
  )


  function setview(view){
    console.log(view)
    setViewtoshow(view)
  }

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12' style={{marginBottom:60}}>
      <ToolbarSchede setview={setview} viewselected={viewtoshow} />
      </div>
      </div>
      <div className='container-fluid'>
      
      {viewtoshow==='calendari'?
      <ViewCalendario calendario={"Sala Attrezzi"} email={email} setview={setview} />
      :null
      }
     
       {viewtoshow==='schedeSA'?
      <RicercaSchedaSA email={email} setview={setview} />
      :null
      }
      {viewtoshow==='tipoEserSA'?
      <ListaEserciziInput email={email} setview={setview} />
      :null
      }
      {viewtoshow==='dettschedaSA'?
      <DettaglioScheda email={email} setview={setview} />
      :null
      }
      {viewtoshow==='dettschedaCC'?
      <ListaSchedeCompCorp email={email} setview={setview} />
      :null
      }
      
      {viewtoshow==='schedeCC'?
      <RicercaSchedaCC email={email} setview={setview} />
      :null
      }
      </div>
    </div>
  );
}

export default GestioneSchede;
