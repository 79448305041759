import React from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import TipologieEserciziSelect from "./TipologieEserciziSelect";
import {connect} from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import InputText from "../util/UI/InputText";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';

class NuovoEsercizioScheda extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            showInsEser:false
	    	}
        this.update = this.update.bind(this);
        this.updateEsercizio = this.updateEsercizio.bind(this);
        this.inserisci = this.inserisci.bind(this);
	  }

	update(e) {
          this.setState({ [e.target.name]: e.target.value });
		
    }

    updateEsercizio(nome, valore) {
        this.setState({ [nome]: valore });
      
  }

    inserisci(e){
        e.preventDefault()
        const { peso,recupero, ripetizioni, serie, tut, ordine, numeroGiorno, note, idesercizio } = this.state;

	    const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','0001');
        formData.append('palestra',this.props.palestra.id);
	    formData.append('idschedafk', this.props.idscheda);
	    formData.append('peso', peso?peso:0);
		formData.append('recupero', recupero);
		formData.append('ripetizioni', ripetizioni);
		formData.append('serie', serie);
        formData.append('tut', tut);
        formData.append('ordine', ordine);
        formData.append('numeroGiorno', numeroGiorno);
        formData.append('note', note?note:'');
        formData.append('idesercizio', idesercizio);

	      axios.post(global.config.server+'/inserisciEsercizioScheda',formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          this.props.refresh();
		          this.setState({ visible: false });
	      });
    }
	render() {
	    return (
            <Grid item container xs={12}>
                <Button title="Aggiungi nuovo esercizio in questa scheda" variant="outlined" onClick={() => this.setState({showInsEser:true})}>
                    <AddCircleOutlineIcon /> <SportsGymnasticsIcon />
                </Button>
                <Dialog open={this.state.showInsEser} onClose={() => this.setState({showInsEser:false})}>
                    <DialogTitle>Nuovo Esercizio Scheda</DialogTitle>
                    <DialogContent>
            <form onSubmit={this.inserisci}>
                id scheda: {this.props.idscheda}
                <Grid item container xs={12}>
                <Grid item container xs={4}>
                        <InputText  label="Giorno" required={true} type="text" name="numeroGiorno" value={this.state.numeroGiorno} onChange= {this.update} />
                        </Grid>
                        <Grid item container xs={4}>
                        <InputText label="Serie" required={true} type="text" name="serie" value={this.state.serie} onChange= {this.update} />
                        </Grid>
                        <Grid item container xs={4}>
                        <InputText label="Recupero" required={true} type="text" name="recupero" value={this.state.recupero} onChange= {this.update} />
                        </Grid>
                        <Grid item container xs={4}>
                    
                        <InputText label="T.U.T." ype="text" name="tut" value={this.state.tut} onChange= {this.update} />
                        </Grid>
                        <Grid item container xs={4}>
                        <InputText label="Ordine" multiline={true} type="text" name="note" value={this.state.note} onChange= {this.update} />
                        </Grid>
                        <Grid item container xs={4}>
                        <InputText label="Ripetizioni" required={true} style={{padding:1}} type="text" name="ripetizioni" value={this.state.ripetizioni} onChange= {this.update} />
                        </Grid>
                        <Grid item container xs={4}>
                        <InputText label="Peso" style={{padding:1}} type="text" name="peso" value={this.state.peso} onChange= {this.update} />
                        </Grid>
                        <Grid item container xs={12}>

                        <TipologieEserciziSelect tipologie={this.props.tipologieEsercizi} selected={this.state.idesercizio} name={"idesercizio"} update={this.updateEsercizio}/>
                        </Grid>
            </Grid>
            </form>
            </DialogContent>
            <DialogActions>
            <Button variant="contained" type="submit" >Inserisci</Button>
            </DialogActions>
            </Dialog>
            </Grid>
	    );
	  }
	}

    const mapStateToProps = state => {
        return {
            palestra: state.gestoreUtente.palestraassociata.palestra,
            email: state.gestoreUtente.emailloggato
        }
    }
    
    export default connect(mapStateToProps, null)(NuovoEsercizioScheda)