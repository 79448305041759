import React from "react";
import axios from 'axios';
import '../util/config'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import AbbonamentiPalestra from "./AbbonamentiPalestra";
import ListaNazioni from "../util/UI/listaNazioni";


export default function Palestra(props) {

  const palestraanagrafica = useSelector(state => state.gestoreUtente.palestraAnagrafica);
  const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
  const palestrasocio = useSelector(state => state.gestoreUtente.palestraassociata);
  const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

  const [palestra, setpalestra] = useState(palestrasocio.palestra?palestrasocio.palestra.palestra:'');
  const [descrizioneattivita, setdescrizioneattivita] = useState(palestrasocio.palestra?palestrasocio.palestra.descrizioneattivita:'');
  const [logo, setlogo] = useState(palestrasocio.palestra?palestrasocio.palestra.logo:'');

  const [indirizzo, setindirizzo] = useState(palestrasocio.palestra?palestrasocio.palestra.indirizzo:'');
  const [citta, setcitta] = useState(palestrasocio.palestra?palestrasocio.palestra.citta:'');
  const [cap, setcap] = useState(palestrasocio.palestra?palestrasocio.palestra.cap:'');
  const [piva, setpiva] = useState(palestrasocio.palestra?palestrasocio.palestra.piva:'');
  const [codicefiscale, setcodicefiscale] = useState(palestrasocio.palestra?palestrasocio.palestra.codicefiscale:'');
  const [feregime, setferegime] = useState(palestrasocio.palestra?palestrasocio.palestra.feregime:'');
  
  const [telefono, settelefono] = useState(palestrasocio.palestra?palestrasocio.palestra.telefono:'');
  const [email, setemail] = useState(palestrasocio.palestra?palestrasocio.palestra.email:'');
  const [gpscoords, setgpscoords] = useState(palestrasocio.palestra?palestrasocio.palestra.gpscoords:'');
  const [website, setwebsite] = useState(palestrasocio.palestra?palestrasocio.palestra.website:'');
  const [subdomain, setsubdomain] = useState(palestrasocio.palestra?palestrasocio.palestra.subdomain:'');
  const [nazione, setnazione] = useState(palestrasocio.palestra?palestrasocio.palestra.nazione:'');
  const [urlicon, seturlicon] = useState(palestrasocio.palestra?palestrasocio.palestra.urlicon:'');
  const [urlbadge, seturlbadge] = useState(palestrasocio.palestra?palestrasocio.palestra.urlbadge:'');
  const [appPw, setappPw] = useState(palestrasocio.palestra?palestrasocio.palestra.wpApplicationPass:'');
  const [orarioApertura1, setorarioApertura1] = useState(palestrasocio.palestra?palestrasocio.palestra.orarioApertura1:'');
  const [orarioChiusura1, setorarioChiusura1] = useState(palestrasocio.palestra?palestrasocio.palestra.orarioChiusura1:'');
  const [orarioApertura2, setorarioApertura2] = useState(palestrasocio.palestra?palestrasocio.palestra.orarioApertura2:'');
  const [orarioChiusura2, setorarioChiusura2] = useState(palestrasocio.palestra?palestrasocio.palestra.orarioChiusura2:'');

  function handleSubmit(e){
    e.preventDefault();
		  const formData = new FormData();
		  formData.append('website', global.config.domain);
		  formData.append('email',emailloggato);
		  formData.append('lic','000');
      formData.append('id',  palestrasocio.palestra.id);
		  formData.append('palestra', palestra);
		  formData.append('descrizione', descrizioneattivita);
      formData.append('logo', logo);
      formData.append('indirizzo', indirizzo);
      formData.append('citta', citta);
      formData.append('cap', cap);
      formData.append('piva', piva);
      formData.append('codicefiscale', codicefiscale?codicefiscale:'');
      formData.append('feregime', feregime);
      formData.append('telefono', telefono);
      formData.append('emailpalestra', email);
      formData.append('gpscoords', gpscoords);
      formData.append('websitepalestra', website);
      formData.append('subdomain', subdomain);
      formData.append('nazione', nazione);
      formData.append('urlIcon', urlicon?urlicon:'');//ok
      formData.append('urlBadge', urlbadge?urlbadge:'');//ok
      formData.append('appPw', appPw?appPw:'');
      formData.append('orarioApertura1', orarioApertura1?orarioApertura1:'');
      formData.append('orarioChiusura1', orarioChiusura1?orarioChiusura1:'');
      formData.append('orarioApertura2', orarioApertura2?orarioApertura2:'');
      formData.append('orarioChiusura2', orarioChiusura2?orarioChiusura2:'');
      axios.post(global.config.server+'/aggiornaPalestra', formData)
        .then((result) => {
			console.log(result.data)
        	alert(result.data);
        })
        .catch(err => {
            if (err.response){
              alert(err.response.data.message);
            }
            else{
              alert(err.response);
            }
      });
	  }

    useEffect(()=>{
      setpalestra(palestrasocio.palestra?palestrasocio.palestra.palestra:'');
  setdescrizioneattivita(palestrasocio.palestra?palestrasocio.palestra.descrizioneattivita:'');
  setlogo(palestrasocio.palestra?palestrasocio.palestra.logo:'');

  setindirizzo(palestrasocio.palestra?palestrasocio.palestra.indirizzo:'');
  setcitta(palestrasocio.palestra?palestrasocio.palestra.citta:'');
  setcap(palestrasocio.palestra?palestrasocio.palestra.cap:'');
  setpiva(palestrasocio.palestra?palestrasocio.palestra.piva:'');
  settelefono(palestrasocio.palestra?palestrasocio.palestra.telefono:'');
  setemail(palestrasocio.palestra?palestrasocio.palestra.email:'');
  setgpscoords(palestrasocio.palestra?palestrasocio.palestra.gpscoords:'');
  setwebsite(palestrasocio.palestra?palestrasocio.palestra.website:'');
  setsubdomain(palestrasocio.palestra?palestrasocio.palestra.subdomain:'');
  setnazione(palestrasocio.palestra?palestrasocio.palestra.nazione:'');
  seturlicon(palestrasocio.palestra?palestrasocio.palestra.urlicon:'');
  seturlbadge(palestrasocio.palestra?palestrasocio.palestra.urlbadge:'');
  setappPw(palestrasocio.palestra?palestrasocio.palestra.wpApplicationPass:'');
  setorarioApertura1(palestrasocio.palestra?palestrasocio.palestra.orarioapertura1:'');
  setorarioChiusura1(palestrasocio.palestra?palestrasocio.palestra.orariochiusura1:'');
  setorarioApertura2(palestrasocio.palestra?palestrasocio.palestra.orarioapertura2:'');
  setorarioChiusura2(palestrasocio.palestra?palestrasocio.palestra.orariochiusura2:'');
console.log(palestrasocio.palestra) 

    },[palestrasocio])

    return (
        <form method="POST" onSubmit={handleSubmit} >
          
	        <div className="row">
			
			<div className="col-md">
				<div className="form-group">
            		<label htmlFor="palestra">Nome</label>
              		<input type="text" className="form-control" 
                      placeholder="Nome *" id="palestra" 
                      name="palestra" 
                      value={palestra} 
                      required="true" onChange={(e)=>setpalestra(e.target.value)} />
            	</div>
	        </div>
	        <div className="col-md">
				<div className="form-group">
            		<label htmlFor="palestra">Abbreviazione Nome senza spazi</label>
              		<input type="text" className="form-control" 
                      placeholder="Sottodominio" 
                      id="sottodominio" 
                      name="subdomain" 
                      value={subdomain} onChange={(e)=>setsubdomain(e.target.value)} />
            	</div>
	        </div>
	        <div className="col-md">
	            	<div className="form-group">
	            	<label htmlFor="piva">Partita Iva</label>
	            		<input type="text" className="form-control" 
                        placeholder="Partita IVA *" 
                        id="piva" 
                        name="piva" 
                        value={piva}
                        required="true" onChange={(e)=>setpiva(e.target.value)} />
	            	</div>
	        </div>
          <div className="col-md">
	            	<div className="form-group">
	            	<label htmlFor="piva">Codice Fiscale</label>
	            		<input type="text" className="form-control" 
                        placeholder="Codice Fiscale" 
                        id="codicefiscale" 
                        name="codicefiscale" 
                        value={codicefiscale}
                        required="true" onChange={(e)=>setcodicefiscale(e.target.value)} />
	            	</div>
	        </div>
          <div className="col-md">
	            	<div className="form-group">
	            	<label htmlFor="regime">Regime Fiscale (solo per fattura elettronica)</label>
                  <select className="form-control" id="regime" name="regimefiscale" value={feregime} onChange={(e)=>setferegime(e.target.value)} >
                    <option>--seleziona un regime fiscale--</option>
                    <option value="RF01">Regime ordinario</option>
                    <option value="RF02">Regime dei contribuenti minimi (art. 1,c.96-117, L. 244/2007)</option>
                    <option value="RF04">Agricoltura e attività connesse e pesca (artt. 34 e 34-bis, D.P.R. 633/1972)</option>
                    <option value="RF05">Vendita sali e tabacchi (art. 74, c.1, D.P.R. 633/1972)</option>
                    <option value="RF06">Commercio dei fiammiferi (art. 74, c.1, D.P.R. 633/1972)</option>
                    <option value="RF07">Editoria (art. 74, c.1, D.P.R. 633/1972)</option>
                    <option value="RF08">Gestione di servizi di telefonia pubblica (art. 74, c.1, D.P.R. 633/1972)</option>
                    <option value="RF09">Rivendita di documenti di trasporto pubblico e di sosta (art. 74, c.1, D.P.R. 633/1972)</option>
                    <option value="RF10">Intrattenimenti, giochi e altre attività	di cui alla tariffa allegata al D.P.R. 640/72 (art. 74, c.6, D.P.R. 633/1972)</option>
                    <option value="RF11">Agenzie di viaggi e turismo (art. 74-ter, D.P.R. 633/1972)</option>
                    <option value="RF12">Agriturismo (art. 5, c.2, L. 413/1991)</option>
                    <option value="RF13">Vendite a domicilio (art. 25-bis, c.6, D.P.R. 600/1973)</option>
                    <option value="RF14">Rivendita di beni usati, di oggetti	d’arte, d’antiquariato o da collezione (art. 36, D.L. 41/1995)</option>
                    <option value="RF15">Agenzie di vendite all’asta di oggetti d’arte, antiquariato o da collezione (art. 40-bis, D.L. 41/1995)</option>
                    <option value="RF16">IVA per cassa P.A. (art. 6, c.5, D.P.R. 633/1972)</option>
                    <option value="RF17">IVA per cassa (art. 32-bis, D.L. 83/2012)</option>
                    <option value="RF19">Regime forfettario</option>
                    <option value="RF18">Altro</option>
                  </select>
	            	</div>
	        </div>
	        <div className="col-md">
	            	<div className="form-group">
	            	<label htmlFor="descr">Descrizione attività</label>
	            		<input type="text" className="form-control" 
                        placeholder="Descrizione attività" 
                        id="descr" 
                        name="descrizioneattivita"  
                        value={descrizioneattivita} required="true" onChange={(e)=>setdescrizioneattivita(e.target.value)} />
	            	</div>
	        </div>
	        <div className="w-100"></div>
	        
	        <div className="w-100"></div>
	        <div className="col-md">
			        <div className="form-group">
		            	<label htmlFor="indirizzo">Indirizzo</label>
		            		<input type="text" className="form-control" 
                            placeholder="Indirizzo *" 
                            id="indirizzo" 
                            name="indirizzo"  
                            value={indirizzo}
                            required="true" onChange={(e)=>setindirizzo(e.target.value)} />
		            	</div>
		   </div>
	        <div className="col-md">
		            	<div className="form-group">
		            	<label htmlFor="citta">Città</label>
		            		<input type="text" className="form-control" 
                            placeholder="Città *" 
                            name="citta" 
                            id="citta" 
                            value={citta} required="true" onChange={(e)=>setcitta(e.target.value)} />
		            	</div>
	       </div>
	       <div className="col-md">
		            	<div className="form-group">
		            	<label htmlFor="cap">Cap</label>
		            		<input type="text" className="form-control" 
                            placeholder="CAP *" 
                            name="cap" id="cap" 
                            value={cap} required="true" onChange={(e)=>setcap(e.target.value)} />
		            	</div>
	       </div>
	        <div className="col-md">
		            	<div className="form-group">
		            	<label htmlFor="nazione">Nazione</label>
                  <ListaNazioni name="nazione" nazione={nazione} setnazione={setnazione} />
		            	</div>
	       </div>
	       <div className="w-100"></div>
	        <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="tel">Telefono</label>
			              <input type="text" className="form-control" 
                          placeholder="tel *" 
                          name="telefono" id="telefono" 
                          value={telefono} required="true" onChange={(e)=>settelefono(e.target.value)} />
			            </div>
			</div>
	        <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Email</label>
			              <input type="text" className="form-control" 
                          placeholder="email *" 
                          name="email" id="email" 
                          value={email} required="true" onChange={(e)=>setemail(e.target.value)} />
			            </div>
			</div>
			<div className="w-100"></div>
	        <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Coordinate gps</label>
			              <input type="text" className="form-control" 
                          placeholder="stringa ricerca google maps" 
                          name="gpscoords" id="gpscoords" 
                          value={gpscoords} onChange={(e)=>setgpscoords(e.target.value)} />
			            </div>
			</div>
	    <div className="col-md">
          <div className="form-group">
          <label htmlFor="email">Sito web</label>
            <input type="text" className="form-control" 
                  placeholder="sito web" 
                  name="website" id="website" 
                  value={website} onChange={(e)=>setwebsite(e.target.value)} />
          </div>
			</div>
      <div className="col-md">
          <div className="form-group">
          <label htmlFor="email">WP Application password (Application password plugin)</label>
            <input type="text" className="form-control" 
                  placeholder="wordpress application password" 
                  name={"wpApplicationPass"} id="wpApplicationPass" 
                  value={appPw} onChange={(e)=>setappPw(e.target.value)} />
          </div>
			</div>

      <div className="w-100"></div>
	        <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Orario apertura mattutina</label>
			              <input type="time" className="form-control" 
                          placeholder="Orario apertura mattutina" 
                          name="orarioApertura1" id="orarioApertura1" 
                          value={""+orarioApertura1} onChange={(e)=>setorarioApertura1(e.target.value)} />
			            </div>
			</div>

      <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Orario chiusura mattutina</label>
			              <input type="time" className="form-control" 
                          placeholder="Orario chiusura mattutina" 
                          name="orarioChiusura1" id="orarioChiusura1" 
                          value={orarioChiusura1} onChange={(e)=>setorarioChiusura1(e.target.value)} />
			            </div>
			</div>

      <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Orario apertura pomeridiana</label>
			              <input type="time" className="form-control" 
                          placeholder="Orario apertura pomeridiana" 
                          name="orarioApertura2" id="orarioApertura2" 
                          value={orarioApertura2} onChange={(e)=>setorarioApertura2(e.target.value)} />
			            </div>
			</div>

      <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Orario chiusura pomeridiana</label>
			              <input type="time" className="form-control" 
                          placeholder="Orario apertura mattutina" 
                          name="orarioChiusura2" id="orarioChiusura2" 
                          value={orarioChiusura2} onChange={(e)=>setorarioChiusura2(e.target.value)} />
			            </div>
			</div>

	    </div>
	    <div className="row">
            <div className="form-group">
	            <img src={logo} alt="logo" width="100" /><br />
	              Link Immagine: <input type="text" name="logo" value={logo} onChange={(e)=>setlogo(e.target.value)} />
            </div>
            <div className="form-group">
	            <img src={urlicon} alt="logo" width="100" /><br />
	              Link icona principale notifiche (32x32): <input type="text" name="urlicon" value={urlicon} onChange={(e)=>seturlicon(e.target.value)} />
            </div>
            <div className="form-group">
	            <img src={urlbadge} alt="logo" width="100" /><br />
	              Link icona secondaria notifiche: <input type="text" name="urlbadge" value={urlbadge} onChange={(e)=>seturlbadge(e.target.value)} />
	              <br /><br />
                </div>
            <div className="form-group text-center">
              <input type="submit" className="btnSubmit" value="Salva" />
            </div>
       </div>
       <AbbonamentiPalestra />
        </form>
        );
}