import React, { useEffect, useState } from "react";
import '../../util/config'

import SelectTipologiaCorso from "../tipologiaCorso/SelectTipologiaCorso";
import LuogoSelect from "./LuogoSelect";
import { useSelector } from "react-redux";
import { aggiornaCorso, eliminaCorso } from "../../DAO/corsiDAO";
import InputText from "../../util/UI/InputText";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Typography } from "@mui/material";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import Save from "@mui/icons-material/Save";
import InputTextMultiLine from "../../util/UI/InputTextMultiLine";

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
const headerValues = [false, 1, 2, 3, 4, 5];

export default function NuovoCorsoInput (props){
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
    
    const [nome, setnome] =useState('')
    const [descrizione, setdescrizione] =useState('')
    const [place, setplace] = useState('')
    const [idtipo, setidtipo] = useState(props.tipo)
    const [duratagiornaliera, setduratagiornaliera] = useState(true)
    const [durata, setdurata] = useState('')
    const [utenti, setutenti] = useState('100')
    const [abb, setabb] = useState('')
    const [calendariopubblico, setcalendariopubblico] = useState('')
    const [prezzo, setprezzo] = useState('')
    const [foto1, setfoto1] = useState('')
	const [file, setfile] = useState(null);
	const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())

    const [disponibileda, setdisponibileda] = useState('')
    const [disponibilea, setdisponibilea] = useState('')
    const [disponibile, setdisponibile] = useState('disponibile')
	const [imgUpload, setimgUpload] = useState(null);
	const [fenatura, setfenatura] = useState('')
	const [aliquotaiva, setaliquotaiva] = useState(0)
		
	const [visible, setvisible] = useState(false);
	const [messaggio, setmessaggio] = useState('');

	function handleInputChange(event) {
        if (event.target.files && event.target.files[0]) {
			console.log(event.target.files[0])
			let img = event.target.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(img);
			reader.onload = (e) => {

				setfile(
					{
						base64:e.target.result,
						name:img.name,
						type:img.type
					}
				)
				setvisible(true)
			}
		}
    }

	  function salva(){
        async function salvaprod(){
            const result=await aggiornaCorso(0,nome, descrizione, descrizione?descrizione:nome, idtipo&&idtipo.id?idtipo.id:props.tipo.id, utenti, durata, duratagiornaliera,
                place, abb, calendariopubblico, prezzo, disponibileda, disponibilea, disponibile,
                foto1, file, fenatura, aliquotaiva, emailloggato, palestra.palestra.id);
            setmessaggio(result)
            props.setkeytorefresh(new Date().getTime());
			setkeytorefresh(new Date().getTime())
			setvisible(false)
			setnome('')
			setdescrizione('')
			setidtipo('')
        }
		if (!nome){
			alert('Nome obbligatorio')
			return;
		}
		if (!descrizione){
			setdescrizione(nome);
		}
		if ((!idtipo||!idtipo.id)&&props.tipo){
			setidtipo(props.tipo)
		}
		if ((!idtipo||!idtipo.id)&&!props.tipo){
			alert('Tipologia obbligatoria')
			return;
		}
		if (!utenti){
			alert("Numero max prenotazioni obbligatorio");
			return
		}

		
		if (!place){
			alert('Luogo obbligatorio')
			return;
		}
		if (!prezzo){
			alert('Prezzo obbligatorio')
			return;
		}
        salvaprod();
    }
	  
	function updateTipo(tipoSelezionato) {
		setidtipo(tipoSelezionato)
		setvisible(true)
	}

	useEffect(()=>{

	},[props.tipo])

	    return (
            <Grid item container xs={12}>
                <Button type="button" className="btn btn-primary" onClick={()=>setvisible(!visible)}>
					<AddCircleOutline />
				</Button>

		<Dialog key={keytorefresh} open={visible} onClose={() => setvisible(false)}>
			<DialogTitle>Nuovo prodotto</DialogTitle>
			<DialogContent>
	    	<Grid item container xs={12}>
				<Grid item container xs={7}>
				<InputText label={"Nome"} 
				type="text" name="nome" value={nome} 
				onChange={(e)=>setnome(e.target.value)} 
				required maxLength="150"/>
					</Grid>
				<Grid item container xs={5}>
				<InputText label={"Prezzo €"} 
				type="number" name="prezzo" value={prezzo} 
				onChange={(e)=>setprezzo(e.target.value)} 
				step="0.1" />
				</Grid>
				<Grid item container xs={12}>
					<InputTextMultiLine rows={5} label={"Descrizione"} name="descrizione" onChange={(e)=>setdescrizione(e.target.value)} value={descrizione} />
					
					</Grid>
					<Grid item container xs={6}>
						<LuogoSelect label="Luogo" setselected={(value) => {setplace(value)}} selected={place}/>
					</Grid>

					<Grid item container xs={6}>
								
								<SelectTipologiaCorso updateTipo={updateTipo} 
								id="ctipoc" 
								selected={props.tipo} 
								 />
							</Grid>
							<Grid item container xs={6}>
								
								<InputText id="cdurata" type="number" onChange={(e) => setdurata(e.target.value)} label="Durata" value={durata} required={true} />
								<label>Tutto il giorno <input type={"checkbox"} checked={duratagiornaliera} name={"duratagiornaliera"} onChange={(e) =>{e.target.checked?setduratagiornaliera(true):setduratagiornaliera(false)}}  /></label>
							</Grid>
							<Grid item container xs={6}>
								
								<InputText id="cmaxp" type="number" onChange={(e) => setutenti(e.target.value)} label="Max prenotazioni" value={utenti} required={true} />
							</Grid>
							<Grid item container xs={6}>
							<FormControl fullWidth>
  								<InputLabel>Solo abbonati</InputLabel>
								<Select label="Solo abbonati" variant="standard" value={abb&&(abb===true||abb==='true')} onChange={(e)=>{setabb(e.target.value)}}>
									<MenuItem value="false" >Aperto a tutti</MenuItem>
									<MenuItem value="true" >Solo abbonati</MenuItem>
								</Select>
								</FormControl>
							</Grid>
							<Grid item container xs={6}>
							<FormControl fullWidth>
  								<InputLabel>Mostra nel calendario pubblicamente</InputLabel>
								<Select variant="standard" value={calendariopubblico&&(calendariopubblico===true||calendariopubblico==='true')} onChange={(e)=>{setcalendariopubblico(e.target.value)}}>
									<MenuItem value="false" >Nascondi</MenuItem>
									<MenuItem value="true" >Mostra</MenuItem>
								</Select>
								</FormControl>
							</Grid>

							<Grid item container xs={12}>
							<FormControl>
  								<FormLabel >Disponibile</FormLabel>
								  <RadioGroup
										defaultValue={"disponibile"}
									>
										<FormControlLabel value="disponibile" control={<Radio />} label="Si" />
										<FormControlLabel value="nondisponibie" control={<Radio />} label="No" />
								</RadioGroup>
							</FormControl>
							</Grid>
							<Grid item container xs={6}>
								<InputText type="date" label="Disponibile dal" name="disponibileda" value={disponibileda} onChange={(e) => {setdisponibileda(e.target.value); setdisponibilea(e.target.value)}} />
							</Grid>
							<Grid item container xs={6}>
								<InputText type="date" label="fino a" name="disponibilea" value={disponibilea} onChange={(e) => setdisponibilea(e.target.value)} />
							</Grid>
							<Grid item container xs={12}>
							<Paper elevation={5} sx={{padding:2, margin:1}}>
							<Typography variant="h6">Immagine</Typography>
							<img src={foto1} style={{maxWidth:'100px'}} />
						
							<InputText label="link immagini" type="text" name="foto1" value={foto1}  onChange={(e) => setfoto1(e.target.value)} />
							
							<InputText label="Oppure seleziona una immagine" id="imgUpload" type="file" name="fotoTemp" ref={imgUpload} onChange={handleInputChange} />
							</Paper>
							</Grid>
							<Grid item container xs={12}>
							<FormControl fullWidth>
  								<InputLabel>Natura Fattura Elettronica</InputLabel>
								<Select variant="standard" name="fenatura" value={fenatura} onChange={(e) => setfenatura(e.target.value)} >
									<MenuItem value="">--Natura bene/servizio per Fattura Elettrovica</MenuItem>
									<MenuItem value="N1">N1 - escluse ex art. 15 del DPR 633/72</MenuItem>
									<MenuItem value="N2.1">N2.1 - non soggette ad IVA ai sensi degli artt. da 7 a 7-septies del DPR 633/72</MenuItem>	
									<MenuItem value="N2.2">N2.2 - non soggette - altri casi</MenuItem>
									<MenuItem value="N3.1">N3.1 - non imponibili - esportazioni</MenuItem>
									<MenuItem value="N3.2">N3.2 - non imponibili - cessioni intracomunitarie</MenuItem>
									<MenuItem value="N3.3">N3.3 - non imponibili - cessioni verso San Marino</MenuItem>
									<MenuItem value="N3.4">N3.4 - non imponibili - operazioni assimilate alle cessioni all'esportazione</MenuItem>
									<MenuItem value="N3.5">N3.5 - non imponibili - a seguito di dichiarazioni d'intento</MenuItem>			
									<MenuItem value="N3.6">N3.6 - non imponibili - altre operazioni che non concorrono alla formazione del plafond</MenuItem>
									<MenuItem value="N4">N4 - esenti</MenuItem>
									<MenuItem value="N5">N5 - regime del margine / IVA non esposta in fattura</MenuItem>
									<MenuItem value="N6.1">N6.1 - inversione contabile - cessione di rottami e altri materiali di recupero</MenuItem>
									<MenuItem value="N6.2">N6.2 - inversione contabile - cessione di oro e argento ai sensi della legge 7/2000 nonché di oreficeria usata ad OPO</MenuItem>
									<MenuItem value="N6.3">N6.3 - inversione contabile - subappalto nel settore edile</MenuItem>
									<MenuItem value="N6.4">N6.4 - inversione contabile - cessione di fabbricati</MenuItem>
									<MenuItem value="N6.5">N6.5 - inversione contabile - cessione di telefoni cellulari</MenuItem>
									<MenuItem value="N6.6">N6.6 - inversione contabile - cessione di prodotti elettronici</MenuItem>
									<MenuItem value="N6.7">N6.7 - inversione contabile - prestazioni comparto edile e settori connessi</MenuItem>
									<MenuItem value="N6.8">N6.8 - inversione contabile - operazioni settore energetico</MenuItem>
									<MenuItem value="N6.9">N6.9 - inversione contabile - altri casi</MenuItem>
									<MenuItem value="N7">N7 - IVA assolta in altro stato UE (prestazione di servizi di telecomunicazioni, tele-radiodiffusione ed elettronici ex art. 7-octies, comma 1 lett. a, b, art. 74-sexies DPR 633/72)</MenuItem>
								</Select>
								</FormControl>
							</Grid>
							<Grid item container xs={12}>
									<InputText label="Aliquota iva per fattura" type="number" step=".01" name="aliquotaiva" value={aliquotaiva} onChange={(e) => setaliquotaiva(e.target.value)} />
								
							</Grid>
								
			</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={salva}>
					<Save />
				</Button>
				<Button onClick={() => setvisible(false)}>
					Close
				</Button>
			</DialogActions>
			</Dialog>
            </Grid>
	    );
	}