import React, { useState } from "react";
import '../../util/config'
import { useSelector } from "react-redux";
import FileBase64 from 'react-file-base64';

import NomiCalendariSelect from "../corsi/NomiCalendariSelect";
import { aggiornaTipoProdotto, eliminaTipoProdotto } from "../../DAO/tipiprodottiDAO";
import SelectText from "../../util/UI/SelectText";
import InputText from "../../util/UI/InputText";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Paper, TextField, Typography } from "@mui/material";
import Delete from '@mui/icons-material/Delete'
import Save from '@mui/icons-material/Save'

export default function TipologiaInput(props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

		const [nome, setnome] = useState(props.selected.nome);
		const [descrizione, setdescrizione] = useState(props.selected.descrizione);
		const [nomecalendario, setnomecalendario] = useState(props.selected.nomecalendario);
		const [foto, setfoto] = useState(props.selected.foto);

		const [file, setfile] = useState(null);
		const [visible, setvisible] = useState(false);
		const [messaggio, setmessaggio] = useState(null);
		const [visibile, setvisibile] = useState(props.selected.visibile);
		const [ordine, setordine]= useState(props.selected.ordine);
		const [visualizzazione, setvisualizzazione]= useState(props.selected.visualizzazione);
		const listaTipoVisualizzazione=[{name:'calendario',value:'calendario'},{name:'lista',value:'menu'},{name:'menu composto',value:'menucomposto'}]

		const listaVisibileUtenti=[{name:'SI',value:1},{name:'NO',value:0}]
		
		const [salvataggio, setsalvataggio] = useState(false)

	function handleInputChange(file){
		setfile({
			base64:file.base64,
			name:file.name,
			type:file.type
		})
	}

	function salva(){
        async function salvaTipo(){
            const result = await aggiornaTipoProdotto(email, palestra.palestra.id,
                props.selected.id, nome, descrizione, nomecalendario,visibile, ordine, foto, file, visualizzazione);
            setmessaggio(result);
			setsalvataggio(false)
        }
		setsalvataggio(true)
		setmessaggio("Attendere prego...")
        salvaTipo();
    }

	function elimina() {
        async function eliminaTipo() {
            const message = await eliminaTipoProdotto(props.selected.id, email, palestra.palestra.id);
            alert(message);
            props.setkeytorefresh(new Date().getTime())
          }
        if (window.confirm("Sei sicuro di voler eliminare la tipologia "+nome+"?")){
            eliminaTipo();
		}
      }

	    return (
			<Grid item container xs={4} md={3}>
				<Grid item container xs={12} >
						<Button onClick={() => setvisible(true)} sx={{color:props.selected.visibile?"green":"orange", display:'flex', flexDirection:'column' }}>
							<Box component="img" margin={2} src={props.selected.foto} sx={{width:100}} />
							<Typography style={{fontWeight:"bold"}}>
								{props.selected.nome}
							</Typography>
						</Button>
				
				</Grid>
				<Dialog
				open={visible} sx={{width:'100%', backgroundColor:'white'}}
				onClose={() => setvisible(false)}>
					<DialogTitle>{nome}</DialogTitle>

					<DialogContent sx={{backgroundColor:'white'}}>
						<Typography>{messaggio}</Typography>
						<Grid item container xs={12}>
							<Grid item container xs={12} md={3}>
							<TextField fullWidth variant="standard" label="nome" 
						onChange={(e) => setnome(e.target.value)} maxLength="50" value={nome} required={true}
						placeholder="nome categoria" />
							</Grid>
							<Grid item container xs={12} md={5}>
							<TextField fullWidth variant="standard" label="Descrizione" 
							onChange={(e) => setdescrizione(e.target.value)} maxLength="200" name="descrizione" value={descrizione} required={true} 
							placeholder="Descrizione categoria"/>
							</Grid>
							<Grid item container xs={12} md={4}>
								<NomiCalendariSelect onChange={setnomecalendario} selected={nomecalendario} label={"Calendari"} nomi={props.nomicalendario} />
							</Grid>
						</Grid>
						<Grid item container xs={12}>
						<Grid item container xs={6} md={4}>
							<SelectText 
							label={"Visibile agli utenti"}
							placeholder={"Visibile agli utenti"}
							lista={listaVisibileUtenti}
							value={visibile}
							onChange={(e) => setvisibile(e.target.value)}/>
							</Grid>
							<Grid item container xs={6} md={4}>
							<InputText 
							label={"Ordine"}
							inputProps={{
								style: { textAlign: "right", width: 100 }
							}}
							onChange={(event) => {setordine(event.target.value)}}
							placeholder={"Ordine"}
							type={"number"} value={ordine} />
							</Grid>
							<Grid item container xs={6} md={4}>
							<SelectText
							label={"Tipo di visualizzazione prodotti"}
							placeholder={"Tipo di visualizzazione prodotti"}
							lista={listaTipoVisualizzazione}
							value={visualizzazione}
							onChange={(e) => setvisualizzazione(e.target.value)}/>
							</Grid>
							<Grid item container xs={12}>
							<Paper elevation={5} sx={{padding:2, margin:1}}>
							<Typography variant="h6">Immagine</Typography>
							<img src={foto} style={{maxWidth:'100px'}} />
							<TextField variant="standard" fullWidth label="link foto" onChange={(e) => setfoto(e.target.value)} value={foto}/>
							<FileBase64 multiple={ false } onDone={(file) => handleInputChange(file)} />
							</Paper>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={salva}>
							<Save />{messaggio}
						</Button>
						<Button color="error" onClick={elimina}>
							<Delete />
						</Button>
					</DialogActions>
					</Dialog>
					</Grid>
	    );
}