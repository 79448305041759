import React from "react";
import { useState } from "react";
import '../util/config'
import Popup from 'reactjs-popup';
import { Avatar, Button, Grid, List, ListItem, ListItemAvatar, ListItemButton } from "@mui/material";


export default function TipologieEserciziSelect(props) {
    const [immagine, setimmagine] = useState();
    const [tipi, settipi] = useState([]);
    const [openfiltro, setopenfiltro] = useState(false);

    function filtra(filtro) {
        //console.log(props.tipologie)
        let tipit = props.tipologie.filter(tipot => tipot.categoria.toUpperCase().indexOf(filtro.toUpperCase()) >= 0 || tipot.descrizione.toUpperCase().indexOf(filtro.toUpperCase()) >= 0)
        settipi(tipit)
        //console.log(tipi)
    }

    function update(name, value) {
        //console.log(name+" = "+value)
        props.update(name, value);
        for (var i = 0; i < props.tipologie.length; i++) {
            //console.log(props.tipologie[i].idesercizio+" - "+value)
            if (Number(props.tipologie[i].idesercizio) === Number(value)) {
                document.getElementById('img' + props.idschedaesercizi).src = props.tipologie[i].link
                return
            }
        }
    }

    return (
        <Grid item container xs={12}>
            {props.tipologie && props.tipologie.length > 0 ?
                <Grid item container xs={12}>
                    {props.tipologie.map(tipo => (
                        Number(tipo.idesercizio) === Number(props.selected) ?
                        <span>
                        <img id={"img" + tipo.idesercizio} src={tipo.link} style={{ width: 50 }} />
                            <span key={tipo.descrizione}>
                                {tipo.descrizione}<br />{tipo.categoria}
                            </span>
                            </span>
                            : null
                    ))}

                    

                    <Grid item container xs={12}>
                        <Button onClick={() => setopenfiltro(!openfiltro)}>Seleziona un esercizio</Button>

                        {openfiltro &&
                            <Grid item container xs={12}>
                                Seleziona un esercizio:
                                <input list="brow" name={props.name} onChange={(e) => filtra(e.target.value)} />
                                {tipi && Array.isArray(tipi) && tipi.length > 0 &&
                                    <List>

                                        {tipi.map(tipo => (
                                            <ListItem disablePadding>
                                                <ListItemAvatar>
                                                    <Avatar alt={tipo.descrizione} src={tipo.link} />
                                                </ListItemAvatar>
                                                <ListItemButton key={tipo.idesercizio} name={props.name} value={tipo.idesercizio}
                                                    onClick={() => { update(props.name, tipo.idesercizio); setopenfiltro(false) }}>
                                                    {tipo.idesercizio === props.selected ? true : false} {tipo.idesercizio} ({tipo.categoria}) {tipo.descrizione}
                                                </ListItemButton>
                                            </ListItem>
                                        ))}

                                    </List>}
                            </Grid>}
                    </Grid>
                </Grid>
                : null}
        </Grid>
    );
}