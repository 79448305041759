import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cercafatture, esportafatture } from "../DAO/fattureDAO";
import { navigate, A } from 'hookrouter'
import { setfatturericercate } from "../STORE/reducers/gestoreFattura";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSearch, faEnvelope, faPlusCircle, } from '@fortawesome/free-solid-svg-icons'
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import InputText from "../util/UI/InputText";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

var timeout = 3000
var fatturedaesportare = [];
export default function RicercaFatture(props) {
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const fatturericercate = useSelector(state => state.gestoreFatture.fatturericercate);
    const dispatch = useDispatch();

    const [nome, setnome] = useState('')
    const [cognome, setcognome] = useState('')
    const [codicefiscale, setcodicefiscale] = useState('')
    const [numero, setnumero] = useState('')
    const [datada, seTableCellatada] = useState(' ')
    const [dataa, seTableCellataa] = useState(' ')
    const [nomecognomecodfisc, setnomecognomecodfisc] = useState('')
    const [totfatture, settotfatture] = useState(0)
    const [fatture, setfatture] = useState([])
    const [advanced, setadvanced] = useState(false)

    function testalo(inputcodfisc) {
        let codfisc = inputcodfisc.substring(1, 17)
        console.log('codfisc ' + codfisc)
        setcodicefiscale(codfisc)

        setTimeout(() => {
            setnomecognomecodfisc('')
            setcodicefiscale(codfisc)

            ricercafatture(codfisc)
        }, 1000);
    }

    function spliTableCellati(value) {
        if (value.indexOf('%') === 0 && value.length >= 30) {
            clearTimeout(timeout);
            console.log(value)
            let inputcodfisc = value
            inputcodfisc = inputcodfisc.replace('. ', '')
            inputcodfisc = inputcodfisc.replace('\n', ' ')
            inputcodfisc = inputcodfisc.replace('\r', ' ')
            testalo(inputcodfisc)
            //timeout = setTimeout(testalo, 1000);
        }
    }

    function ricercafatture(codfisc) {
        console.log('ricercafatture ' + codfisc)
        async function cercafatt() {
            try {
                setfatture([])
                fatturedaesportare = []
                console.log('prefatture: ' + codicefiscale)
                const result = await cercafatture(codfisc ? '' : nomecognomecodfisc, codfisc ? '' : nome,
                    codfisc ? '' : cognome, codfisc ? codfisc : codicefiscale,
                    codfisc ? '' : numero, codfisc ? '' : datada.trim(),
                    codfisc ? '' : dataa.trim(), palestra.palestra.id,
                    email);
                console.log(result)
                setfatture(result)
                let totfatt = 0;
                result.map(fatt => totfatt += fatt.importoricevuta)
                settotfatture(totfatt)
                dispatch(setfatturericercate(result));
            }
            catch (error) {
                console.log(error)
            }
        }
        cercafatt();
    }

    function esportafattura(checked, value) {
        console.log(checked + ' ' + value)
        let fatturetemp = fatturedaesportare;
        if (checked) {
            if (!fatturetemp.includes(value)) {
                fatturetemp.push(value)
            }
        }
        else {
            fatturetemp = fatturetemp.filter(fatt => fatt !== value)
        }

        fatturedaesportare = fatturetemp;
    }

    function esportaemail(emailforexport) {
        async function exportfatt() {
            const result = await esportafatture(fatturedaesportare, email, palestra.palestra.id, "IT" + palestra.palestra.piva + "_" + new Date().getFullYear() + "" + (("0" + (new Date().getMonth() + 1)).slice(-2)) + "" + ("0" + new Date().getDate()).slice(-2) + '.zip', emailforexport);
        }
        if (fatturedaesportare && Array.isArray(fatturedaesportare) && fatturedaesportare.length > 0) {
            exportfatt();
        }
        else {
            alert('Selezionare almeno una fattura')
        }
    }

    function esporta() {
        async function exportfatt() {
            const result = await esportafatture(fatturedaesportare, email, palestra.palestra.id, "IT" + palestra.palestra.piva + "_" + new Date().getFullYear() + "" + (("0" + (new Date().getMonth() + 1)).slice(-2)) + "" + ("0" + new Date().getDate()).slice(-2) + '.zip', '');
            //alert(result);
        }
        if (fatturedaesportare && Array.isArray(fatturedaesportare) && fatturedaesportare.length > 0) {
            exportfatt();
        }
        else {
            alert('Selezionare almeno una fattura')
        }
    }

    function selectnotexport() {
        let fatturetemp = fatture && fatture.length > 0 ? fatture : fatturericercate;
        for (let i = 0; i < fatturetemp.length; i++) {
            if (fatturetemp[i].idfatt) {
                document.getElementById('export' + fatturetemp[i].idfatt).checked = false;
            }
        }
        fatturedaesportare = [];
        for (let i = 0; i < fatturetemp.length; i++) {
            if (fatturetemp[i].fedataexport && fatturetemp[i].fedataexport !== '') {
                if (fatturetemp[i].idfatt) {
                    document.getElementById('export' + fatturetemp[i].idfatt).checked = false;
                    esportafattura(false, fatturetemp[i].idfatt)
                }
            }
            else {
                if (fatturetemp[i].idfatt) {
                    document.getElementById('export' + fatturetemp[i].idfatt).checked = true;
                    esportafattura(true, fatturetemp[i].idfatt)
                }
            }

        }
    }

    function selectall() {
        let fatturetemp = fatture && fatture.length > 0 ? fatture : fatturericercate;
        console.log('fatturetemp')
        console.log(fatturetemp)
        for (let i = 0; i < fatturetemp.length; i++) {
            if (fatturetemp[i].idfatt) {
                document.getElementById('export' + fatturetemp[i].idfatt).checked = true;
                esportafattura(true, fatturetemp[i].idfatt)
            }
        }
    }

    function setmailforexport() {
        let emailexp = prompt("Confermare l'email per l'export delle fatture:", palestra.palestra.id === 112 ? 'annalisa.studioarganese@hotmail.it' : email);
        esportaemail(emailexp)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            ricercafatture();
        }
    }

    return (
        <Grid item container xs={12} padding={2}>
            <Grid item container xs={12}>
                <h4>Ricerca fatture</h4>
                <Button variant="outlined" onClick={() => navigate("/annotatio-ins-fatt/")}>
                    <AddCircleOutlineIcon /> <ReceiptLongIcon />
                </Button>
            </Grid>
            <Grid item container xs={12} marginTop={2}>
                <InputText label="Cerca per Nome Cognome / Codice Fiscale" type="text"
                    autoFocus={true}
                    placeholder="NOME COGNOME CODICE FISCALE"
                    variant="outlined"
                    name="nomecognomecodfisc"
                    value={nomecognomecodfisc}
                    handleSubmit={handleKeyDown}
                    onChange={(e) => { setnomecognomecodfisc(e.target.value); clearTimeout(timeout); timeout = setTimeout(spliTableCellati(e.target.value), 2000); }} />
            </Grid>
            <Button onClick={() => setadvanced(!advanced)} size="small" sx={{ fontSize: 12 }}>Ricerca avanzata</Button>
            {advanced && <Grid item container xs={12}>
                <InputText key="nome" label="Nome" type="text"
                    onKeyDowhandleSubmitn={handleKeyDown}
                    placeholder="NOME" name="nome" value={nome} onChange={(e) => setnome(e.target.value)} />

                <InputText key="cognome" label="Cognome" type="text"
                    handleSubmit={handleKeyDown}
                    placeholder="COGNOME" name="cognome" value={cognome} onChange={(e) => setcognome(e.target.value)} />

                <InputText key="numfatt" label="Numero fattura" type="text"
                    handleSubmit={handleKeyDown}
                    placeholder="NUMERO" name="numero" value={numero} onChange={(e) => setnumero(e.target.value)} />
                <Grid key="datadagr" item container xs={12}>
                    <Grid key="datadagrgr" item container xs={6}>
                        <InputText key="datada" label="Data emissione DA" type="date"
                            handleSubmit={handleKeyDown}
                            placeholder="EMISSIONE DA" name="datada" value={datada} onChange={(e) => seTableCellatada(e.target.value)} />
                    </Grid>
                    <Grid key="dataagrgr" item container xs={6}>
                        <InputText key="datada" label="Data emissione A" type="date"
                            handleSubmit={handleKeyDown}
                            placeholder="EMISSIONE A" name="dataa" value={dataa} onChange={(e) => seTableCellataa(e.target.value)} />
                    </Grid>
                </Grid>

            </Grid>}
            <Grid item container xs={12}>
                <Button onClick={() => ricercafatture('')} ><FontAwesomeIcon icon={faSearch} /> CERCA </Button>
            </Grid>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Numero/anno</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell></TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Cognome</TableCell>
                            <TableCell>Cod fisc</TableCell>
                            <TableCell>Data nascita</TableCell>
                            <TableCell>Importo</TableCell>
                            <TableCell>Pagamento</TableCell>
                            <TableCell>Data export</TableCell>
                            <TableCell>
                                <Button onClick={selectnotexport}>Seleziona non esportati</Button>
                                <Button onClick={selectall}>Seleziona tutti</Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(fatture && fatture.length > 0 ? fatture : fatturericercate).map((fattura, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ cursor: "pointer" }} onClick={() => fattura.idfatt ? navigate("/annotatio-dett-fatt/" + fattura.idfatt,) : alert("Nessuna fattura per l'anagrafica " + fattura.nome + " " + fattura.cognome)}>
                                    {fattura.numeroricevuta}/{fattura.annoricevuta}
                                </TableCell>
                                <TableCell style={{ cursor: "pointer" }} onClick={() => fattura.idfatt ? navigate("/annotatio-dett-fatt/" + fattura.idfatt) : alert("Nessuna fattura per l'anagrafica " + fattura.nome + " " + fattura.cognome)}>
                                    {fattura.dataricevuta}
                                </TableCell>
                                <TableCell>
                                    <A href={'/annotatio-ins-fatt/' + fattura.idanag} data-toggle="tooltip" title={"Inserisci nuova fattura per " + fattura.nome + " " + fattura.cognome}>
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </A>
                                </TableCell>
                                <TableCell style={{ cursor: "pointer" }} onClick={() => fattura.idfatt ? navigate("/annotatio-dett-fatt/" + fattura.idfatt) : alert("Nessuna fattura per l'anagrafica " + fattura.nome + " " + fattura.cognome)}>
                                    {fattura.nome}
                                </TableCell>
                                <TableCell style={{ cursor: "pointer" }} onClick={() => fattura.idfatt ? navigate("/annotatio-dett-fatt/" + fattura.idfatt) : alert("Nessuna fattura per l'anagrafica " + fattura.nome + " " + fattura.cognome)}>
                                    {fattura.cognome}
                                </TableCell>
                                <TableCell style={{ cursor: "pointer" }} onClick={() => fattura.idfatt ? navigate("/annotatio-dett-fatt/" + fattura.idfatt) : alert("Nessuna fattura per l'anagrafica " + fattura.nome + " " + fattura.cognome)}>
                                    {fattura.piva}{fattura.codicefiscale}
                                </TableCell>
                                <TableCell style={{ cursor: "pointer" }} onClick={() => fattura.idfatt ? navigate("/annotatio-dett-fatt/" + fattura.idfatt) : alert("Nessuna fattura per l'anagrafica " + fattura.nome + " " + fattura.cognome)}>
                                    {fattura.datanascita}
                                </TableCell>
                                <TableCell style={{ cursor: "pointer", textAlign: 'right' }} onClick={() => fattura.idfatt ? navigate("/annotatio-dett-fatt/" + fattura.idfatt) : alert("Nessuna fattura per l'anagrafica " + fattura.nome + " " + fattura.cognome)}>
                                    {fattura.importoricevuta}
                                </TableCell>
                                <TableCell style={{ cursor: "pointer", textAlign: 'left', backgroundColor: fattura.datapagamento ? '#ffffff' : 'orange' }} onClick={() => fattura.idfatt ? navigate("/annotatio-dett-fatt/" + fattura.idfatt, true) : alert("Nessuna fattura per l'anagrafica " + fattura.nome + " " + fattura.cognome)}>
                                    {fattura.datapagamento ? fattura.importopagamento + " (il " + fattura.datapagamento + " tramite " + fattura.tipopagamento + ")" : "Nessun pagamento registrato"}
                                </TableCell>
                                <TableCell style={{ cursor: "pointer" }} onClick={() => fattura.idfatt ? navigate("/annotatio-dett-fatt/" + fattura.idfatt) : alert("Nessuna fattura per l'anagrafica " + fattura.nome + " " + fattura.cognome)}>
                                    {fattura.fedataexport}
                                </TableCell>
                                <TableCell>
                                    {fattura.idfatt ?
                                        <input type="checkbox" name="export" id={"export" + fattura.idfatt} value={fattura.idfatt} onClick={(e) => esportafattura(e.target.checked, fattura.idfatt)} />
                                        : ""
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                        <tr><TableCell colSpan={7} style={{ textAlign: 'right' }}>Tot.</TableCell><TableCell style={{ textAlign: 'right' }}>{totfatture}</TableCell><TableCell colSpan={2}></TableCell></tr>
                    </TableBody>
                </Table>
            </TableContainer>
            {fatture.length > 0 &&
                <div>
                    <button type="button" className="btn btn-secondary" onClick={esporta}>
                        <FontAwesomeIcon icon={faDownload} /> Download XML fatture selezionate
                    </button>
                    &nbsp;
                    <button type="button" className="btn btn-secondary" onClick={setmailforexport}>
                        <FontAwesomeIcon icon={faEnvelope} /> Invia XML fatture selezionate per email
                    </button>
                </div>
            }
        </Grid>
    );
}