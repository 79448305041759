import React from "react";
import axios from 'axios';
import '../util/config'

import ClienteAssociatoScheda from "./ClienteAssociatoScheda";
import NuovoEsercizioScheda from "./NuovoEsercizioScheda";
import EsercizioScheda from "./EsercizioScheda";
import { connect } from 'react-redux'
import SwipeableViews from 'react-swipeable-views';
import { Button, Container, FormControlLabel, Grid, Paper, Switch, Tab, Tabs, Typography } from "@mui/material";
import InputText from "../util/UI/InputText";
import Save from "@mui/icons-material/Save";
import Swal from "sweetalert2";

class DettaglioScheda extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            key: new Date().getTime(),
            id: this.props.id,
            dettaglio: null,
            idschedapartenza: null,
            esercizi: [],
            giorni: [],
            clientiAssociati: [],
            eserciziPalestra: [],
            tabvalue: 0,
            validoDa: '',
            descrizione: ''
        }
        this.update = this.update.bind(this);
        this.salvaScheda = this.salvaScheda.bind(this);
        this.associa = this.associa.bind(this);
        this.disassocia = this.disassocia.bind(this);
        this.eliminaEsercizio = this.eliminaEsercizio.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email', this.props.email);
        formData.append('lic', '0001');
        formData.append('palestra', this.props.palestra.id);
        axios.post(global.config.server + "/getDettaglioSchedaSA/" + localStorage.getItem('idscheda'), formData)
            .then(response => {
                axios.post(global.config.server + "/getEserciziRest", formData)
                    .then(response4 => {
                        axios.post(global.config.server + "/getEserciziScheda/" + localStorage.getItem('idscheda'), formData)
                            .then(response2 => {
                                this.setState({ dettaglio: response.data });
                                this.setState({ descrizione: response.data.descrizione });
                                this.setState({ template: response.data.template });
                                this.setState({ validoDa: response.data.validoDa });
                                this.setState({ eserciziPalestra: response4.data });
                                this.setState({ esercizi: response2.data });
                                var giornitemp = new Set()
                                for (const eser of response2.data) {
                                    giornitemp.add(eser.numeroGiorno)
                                }
                                this.setState({ giorni: Array.from(giornitemp) })
                                console.log(response2.data)
                                console.log(this.state.giorni)
                                if (response.data
                                    && response.data.template
                                    && response.data.template === 1) {
                                    this.setState({ template: 1 })
                                }
                                else {
                                    this.setState({ template: 0 })
                                }

                                this.setState({ nuovoEsercizioVisible: false })
                            });
                    });


            });
    }

    salvaScheda(e) {
        let validita = this.state.validoDa;
        if (window.confirm('Vuoi aggiornare anche la data della scheda ad oggi?')) {
            this.setState({ validoDa: new Date().getFullYear() + "-" + (("0" + (new Date().getMonth() + 1)).slice(-2)) + "-" + ("0" + new Date().getDate()).slice(-2) });
            validita = new Date().getFullYear() + "-" + (("0" + (new Date().getMonth() + 1)).slice(-2)) + "-" + ("0" + new Date().getDate()).slice(-2);
        }
        e.preventDefault()
        const { id, descrizione, validoDa, template, clientiAssociati } = this.state;
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email', this.props.email);
        formData.append('lic', '0001');
        formData.append('palestra', this.props.palestra.id);
        formData.append('id', localStorage.getItem('idscheda'));
        formData.append('descrizione', descrizione);
        formData.append('schedaDataDa', validita);
        formData.append('template', template);
        clientiAssociati.forEach((item) => {
            formData.append('clientiAssociati', item.id);
        });

        axios.post(global.config.server + '/aggiornaSchedaSalaAttrezzi/' + localStorage.getItem('idscheda'), formData)
            .then((result) => {
                this.setState({ messaggio: result.data });
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                });
                Toast.fire({
                    type: 'success',
                    title: result.data
                })
                this.setState({ key: new Date().getTime() });
                this.refresh()
            });
    }

    refresh() {
        this.setState({ key: new Date().getTime() });
        this.componentDidMount()
    }

    update(e) {
        if (e.target.name === 'template') {
            if (e.target.checked) {
                this.setState({ [e.target.name]: 1 });
            }
            else {
                this.setState({ [e.target.name]: 0 });
            }
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }

    }

    associa(idselezionato) {
        if (idselezionato && idselezionato !== '') {
            const id = idselezionato.split('___')[0]
            const name = idselezionato.split('___')[1]
            const cliente = { id: id, name: name }
            if (!this.contains(this.state.clientiAssociati, cliente.id)) {
                this.setState({ clientiAssociati: [...this.state.clientiAssociati, cliente] })
            }
        }
    }

    disassocia(e) {
        const nuovoArray = this.remove(this.state.clientiAssociati, e.target.name)
        this.setState({ clientiAssociati: nuovoArray })
    }

    eliminaEsercizio(et) {
        if (window.confirm('Sei sicuro di voler cancellare l\'esercizio selezionato?')) {
            const formData2 = new FormData();
            formData2.append('website', global.config.domain);
            formData2.append('email', this.props.email);
            formData2.append('lic', '0001');
            formData2.append('palestra', this.props.palestra.id);
            axios.post(global.config.server + '/eliminaEsercizioScheda/' + et, formData2)
                .then((result) => {
                    this.setState({ messaggio: result.data });
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    });
                    Toast.fire({
                        type: 'success',
                        title: result.data
                    })
                    this.refresh()

                });

        }
    }

    contains(array, id) {
        var trovato = false;
        if (array && array.length > 0) {
            array.map(clienteTemp => {
                if (clienteTemp.id === id) {
                    trovato = true;
                }
            })
        }
        return trovato;
    }

    remove(array, id) {
        array = array.filter(cliente => cliente.id !== id)
        return array;
    }

    render() {
        return (

            <Grid container spacing={3} sx={{ marginTop: 2 }}>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                    Scheda Attività <u>{this.state.dettaglio && this.state.dettaglio.anagrafica ? `${this.state.dettaglio.anagrafica.nome} ${this.state.dettaglio.anagrafica.cognome}` : null}</u>
                </Typography>
            </Grid>
            <Container>
                <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <InputText 
                                label="Valida dal" 
                                type="date" 
                                name="validoDa" 
                                onChange={this.update} 
                                value={this.state.validoDa} 
                                fullWidth 
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <FormControlLabel 
                                control={
                                    <Switch
                                        checked={this.state.template === true || this.state.template === 1 || this.state.template === "1"}
                                        value={1}
                                        name="template"
                                        onChange={this.update}
                                    />
                                } 
                                label="Scheda predefinita" 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">Scheda di partenza</Typography>
                            <Typography variant="body1">
                                {this.state.dettaglio && this.state.dettaglio.idschedapartenza ? this.state.dettaglio.idschedapartenza : <span>Nessuna scheda di partenza</span>}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ marginY: 2 }}>
                            <InputText 
                                label="Descrizione" 
                                multiline 
                                required 
                                className="form-control" 
                                name="descrizione" 
                                onChange={this.update} 
                                value={this.state.descrizione} 
                                fullWidth 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ClienteAssociatoScheda associa={this.associa} />
                        </Grid>
                        <Grid item xs={12}>
                            {this.state.clientiAssociati && this.state.clientiAssociati.length > 0 ? (
                                <ul>
                                    {this.state.clientiAssociati.map(cliente => (
                                        <li key={cliente.id}>
                                            {cliente.id} {cliente.name}
                                            <Button 
                                                size="small" 
                                                color="secondary" 
                                                onClick={() => this.disassocia(cliente.id)}
                                                sx={{ marginLeft: 1 }}
                                            >
                                                Elimina
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            ) : null}
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" fullWidth onClick={this.salvaScheda} sx={{ marginTop: 2 }}>
                                <Save /> Salva scheda
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            <Grid item xs={12}>
                <NuovoEsercizioScheda 
                    tipologieEsercizi={this.state.eserciziPalestra} 
                    idscheda={localStorage.getItem('idscheda')} 
                    refresh={this.refresh} 
                />
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
                    <Tabs 
                        variant="scrollable" 
                        scrollButtons="auto" 
                        sx={{ color: 'black' }} 
                        value={this.state.tabvalue} 
                        onChange={(event, newValue) => this.setState({ tabvalue: newValue })}
                    >
                        {this.state.giorni && this.state.giorni.length > 0 &&this.state. giorni.map((gg, index) => (
                            <Tab key={index} value={index} label={gg} />
                        ))}
                    </Tabs>
                    <SwipeableViews 
                        index={this.state.tabvalue} 
                        onChangeIndex={(newValue) => this.setState({ tabvalue: newValue })} 
                    >
                        {this.state.giorni && this.state.giorni.length > 0 && this.state.giorni.map((gg, index) => (
                            <div key={index}>
                                {this.state.esercizi && this.state.esercizi.length > 0 ? (
                                    this.state.esercizi.map((esercizio, i) => (
                                        esercizio.numeroGiorno === gg && (
                                            <EsercizioScheda 
                                                key={i}
                                                esercizio={esercizio}
                                                idschedaesercizio_o={esercizio.idschedaesercizi}
                                                eserciziValoriSett_o={esercizio.eserciziValoriSett}
                                                esercizio2_o={esercizio.idesercizio2}
                                                esercizio3_o={esercizio.idesercizio3}
                                                esercizio_o={esercizio.esercizio1}
                                                note_o={esercizio.note}
                                                numeroGiorno_o={esercizio.numeroGiorno}
                                                ordine_o={esercizio.ordine}
                                                tipologieEsercizi={this.state.eserciziPalestra}
                                                eliminaEsercizio={this.eliminaEsercizio}
                                            />
                                        )
                                    ))
                                ) : (
                                    <Typography variant="body1" sx={{ padding: 2 }}>
                                        Nessun esercizio
                                    </Typography>
                                )}
                            </div>
                        ))}
                    </SwipeableViews>
                </Paper>
            </Grid>
        </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraassociata.palestra,
        email: state.gestoreUtente.emailloggato
    }
}

export default connect(mapStateToProps, null)(DettaglioScheda)