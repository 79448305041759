import { Button, FormControl, InputLabel, MenuItem, Select, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useState } from "react";
import InputText from "../util/UI/InputText";
import Delete from '@mui/icons-material/Delete'
export default function NuovaRiga(props) {
    const [val, setval] = useState('');
    const [descr, setdescr] = useState(props.riga.descrizione);

    return (
        <TableRow key={props.riga.idrigafattura}>

            <TableCell>
                {props.prodotti &&
                    <FormControl fullWidth variant="standard" sx={{width:400}}>
                        <InputLabel>Servizio/Prodotto</InputLabel>
                        <Select onChange={(e) => { setdescr(props.prodotti.filter(prod => prod.id === Number(e.target.value))[0].descrizione); props.aggiornarigadaprodotti(e.target.value, props.riga); setval(e.target.value) }} value={val}>

                            {props.prodotti.map(prodotto => (
                                <MenuItem key={prodotto.id} value={prodotto.id}>{prodotto.descrizione}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>
                }
                <InputText label="Descrizione" type="text" name="descrizione" onChange={e => { setdescr(e.target.value); props.updateRiga(e, props.riga.idrigafattura) }} value={descr} />
            </TableCell>
            <TableCell > <InputText
                inputProps={{
                    style: { textAlign: "right", width: 100 }
                }}
                label="Quantita"
                type="number" name="quantita" onChange={e => props.updateRiga(e, props.riga.idrigafattura)} value={props.riga.quantita} /></TableCell>
            <TableCell ><InputText
                label="Costo"
                inputProps={{
                    style: { textAlign: "right", width: 100 }
                }}
                type="number" name="costo" onChange={e => props.updateRiga(e, props.riga.idrigafattura)} value={props.riga.costo} /></TableCell>
            <TableCell align="right">
                {props.riga.quantita * props.riga.costo}
            </TableCell>
            <TableCell ><InputText inputProps={{
                style: { textAlign: "right", width: 100 }
            }} type="number" name="aliquotaiva" value={props.riga.aliquotaiva} onChange={e => props.updateRiga(e, props.riga.idrigafattura)} /></TableCell>
            <TableCell align="right">{(props.riga.quantita * props.riga.costo * props.riga.aliquotaiva / 100)}</TableCell>
            <TableCell ><InputText label="Natura Fatt. Elettr." inputProps={{
                style: { textAlign: "center", width: 100 }
            }} type="text" name="fenatura" value={props.riga.fenatura} /></TableCell>
            <TableCell >
                <Button onClick={e => { props.eliminaRiga(e, props.riga.idrigafattura) }}
                    color="error">
                    <Delete />
                </Button>
            </TableCell>
        </TableRow>

    );
}