import React from "react";
import '../util/config'
import { Button, TableCell, TableRow } from "@mui/material";

export default class RicercaCassaRiga extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
               scheda:null,
               rowcolor:'white',
               textAlign:'left',
               textColor:'black',
               idPagamento:0,
               stamparicevuta:0
	    }
        this.seleziona = this.seleziona.bind(this);
        this.selezionaPerCancellazione=this.selezionaPerCancellazione.bind(this);
        this.getStyle = this.getStyle.bind(this);
        this.update = this.update.bind(this);
	  }

      update(e) {
          
        if (e.target.name==='stamparicevuta'){
			this.setState({ [e.target.name]: e.target.checked?1:0});
            if (e.target.checked){
                this.props.addStampaList("add",e.target.value)
            }
            else{
                this.props.addStampaList("del",e.target.value)
            }
		}
        else if (e.target.name==='idPagamento'){
			this.setState({ [e.target.name]: e.target.checked?1:0});
            if (e.target.checked){
                this.props.addSalvaList("add",e.target.value)
            }
            else{
                this.props.addSalvaList("del",e.target.value)
            }
		}
		else{
            this.setState({ [e.target.name]: e.target.value, visible: true });
		}
	    
    }

    componentDidUpdate(propsPrecedenti) {
        if (this.props.selectAllPagamenti !== propsPrecedenti.selectAllPagamenti) {
          this.setState({idPagamento:this.props.selectAllPagamenti?1:0})
        }
        if (this.props.selectAllRicevute !== propsPrecedenti.selectAllRicevute) {
            this.setState({stamparicevuta:this.props.selectAllRicevute?1:0})
          }
      }

	componentDidMount(){
        if( this.props.movimento.entrate
            &&this.props.movimento.entrate>0
            &&(
            (this.props.movimento.tipoPagamento
            &&this.props.movimento.tipoPagamento!=='contanti')
            ||this.props.movimento.descrizione.indexOf('minorenne')>0)
           ){
            this.setState({rowcolor:'#cc9999',idPagamento:1})
            this.props.addSalvaList("add",this.props.movimento.key.id+'**'+this.props.movimento.dataOperazione+'**'+this.props.movimento.entrate+'**'+this.props.movimento.idAbbonamento)
           }
         if (this.props.movimento.descrizione.indexOf('TOTALE')>=0){
            this.setState({textAlign:'right'})
            if (this.props.movimento.descrizione.indexOf('GIORNALIERO')>=0){
                this.setState({rowcolor:'green', textColor: 'white'})
            }
        }
    }

    seleziona(e) {
        localStorage.setItem('idcassa',this.props.movimento.key.id.split('-')[0])
        if (this.props.link){
            window.location.href = "/"+this.props.link
        }
        else{
            this.props.setview('dettcassa')
        }
    }

    getStyle() {
       
    }

    selezionaPerCancellazione(e) {
        var dacancTemp=this.props.dacancellare;
        if (e.target.checked){
            dacancTemp.push(e.target.value)
        }
        else{
            var carIndex = dacancTemp.indexOf(e.target.value);
            dacancTemp.splice(carIndex, 1);
        }
    }

	render() {
	    return (
                    <TableRow style={{backgroundColor: this.state.rowcolor, textAlign: this.state.textAlign, color: this.state.textColor}}>
                        <TableCell>{this.props.movimento.key&&this.props.movimento.key.id.indexOf('cassa')>=0?
                        <Button onClick={this.seleziona}>{this.props.movimento.key.id.split('-')[0]}</Button>
                        :null}</TableCell>
                       <TableCell>{this.props.movimento.dataOperazione}</TableCell>
                        <TableCell>{this.props.movimento.descrizione}</TableCell>
                        <TableCell style={{textAlign:'right'}}>{this.props.movimento.entrate}</TableCell>
                        <TableCell style={{textAlign:'right'}}>{this.props.movimento.uscite}</TableCell>
                      <TableCell style={{textAlign:'center'}}>{this.props.movimento.tipoPagamento}</TableCell> 
                      <TableCell>
                          {(!this.props.movimento.ricevute||this.props.movimento.ricevute.length===0)
                          &&(this.props.movimento.entrate
                            &&this.props.movimento.entrate>0)
                          &&this.props.movimento.key
                          ?
                          <input type='checkbox' 
                          name='idPagamento' 
                        //   defaultChecked={(
                        //        this.props.movimento.entrate
                        //             &&this.props.movimento.entrate>0
                        //             &&(
                        //             (this.props.movimento.tipoPagamento
                        //             &&this.props.movimento.tipoPagamento!=='contanti')
                        //             ||this.props.movimento.descrizione.indexOf('minorenne')>0))
                        //         }
                                value={this.props.movimento.key.id+'**'+this.props.movimento.dataOperazione+'**'+this.props.movimento.entrate+'**'+this.props.movimento.idAbbonamento}
                                checked={this.state.idPagamento===1}
                                onChange={this.update} />
                            :null}
                    </TableCell> 
                      <TableCell>
                      {this.props.movimento.ricevute&&this.props.movimento.ricevute.length>0?
                      this.props.movimento.ricevute.map(ricevuta=>(
                          <div key={ricevuta.key}>
                              <label>
                              Ricevuta del {ricevuta.data} ({ricevuta.numero}/{ricevuta.anno}), importo {ricevuta.importo}
                              <input
                            type="checkbox"
                            name="stamparicevuta"
                            checked={this.state.stamparicevuta===1}
                            value={ricevuta.id}
                            onChange={this.update}
                            />
                              </label>
                            </div>
                      )

                      )
                      :null}
                          </TableCell> 
                    </TableRow>
	    );
	  }
	}