import React, { useState } from "react";
import '../../util/config'
import { useSelector } from "react-redux";
import { aggiornaTipoProdotto } from "../../DAO/tipiprodottiDAO";
import NomiCalendariSelect from "../corsi/NomiCalendariSelect";
import SelectText from "../../util/UI/SelectText";
import { Box, Button, Grid, Modal, Paper, TextField, Typography } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InputText from "../../util/UI/InputText";
import SaveIcon from '@mui/icons-material/Save';

export default function NuovaTipologiaInput() {
	
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

    const [nome, setnome] = useState('');
    const [descrizione, setdescrizione] = useState('');
    const [nomecalendario, setnomecalendario] = useState('');
    const [visibile, setvisibile] = useState('');
    const [ordine, setordine] = useState('1');
    const [foto, setfoto] = useState('');
    const [fotoTemp, setfotoTemp] = useState(null);
	const [filename, setfilename] = useState(null);
	const [filetype, setfiletype] = useState(null);
	const [messaggio, setmessaggio] = useState('');
	const [visualizzazione, setvisualizzazione]= useState('');
	const listaTipoVisualizzazione=[{name:'calendario',value:'calendario'},{name:'lista',value:'menu'},{name:'menu composto',value:'menucomposto'}]
	const listaVisibileUtenti=[{name:'SI',value:1},{name:'NO',value:0}]

    function salva(){
        async function salvaTipo(){
          
            const result = await aggiornaTipoProdotto(email, palestra.palestra.id,
                0, nome, descrizione, nomecalendario,visibile, ordine, foto, fotoTemp,visualizzazione);
            setmessaggio(result)
        }
		if (!nome){
			alert('Nome obbligatorio')
			return
		}
		if (!descrizione){
			alert('Descrizione obbligatorio')
			return
		}
		if (!nomecalendario){
			alert('Nome calendario obbligatorio')
			return
		}
        salvaTipo();
    }

	function handleInputChange(event) {
        if (event.target.files && event.target.files[0]) {
			console.log(event.target.files[0])
			let img = event.target.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(img);
			reader.onload = (e) => {
				setfotoTemp(e.target.result)
				setfilename(img.name)
				setfiletype(img.type)
			}
		}
    }

		return (
			<Box>
				<Button onClick={()=>{setvisibile(true)}}>
					<AddCircleOutlineIcon />
				</Button>
				<Modal open={visibile} onClose={()=>setvisibile(false)} sx={{width:'100%'}}>
					<Box sx={{maxWidth:'100%'}}>
					<Grid xs={12} item container  padding={2} spacing={1} sx={{backgroundColor:'white',  display:'flex' }}>
					<Grid item  xs={12}>
						<h4>Nuova categoria</h4>
						<Typography>{messaggio}</Typography>
						</Grid>
					
					<Grid item  xs={12} md={4}>
						<TextField fullWidth variant="standard" label="nome" 
						onChange={(e) => setnome(e.target.value)} maxLength="50" value={nome} required={true} />

					</Grid>
					<Grid item  xs={12} md={4}>
						<TextField fullWidth variant="standard" label="Descrizione" 
						onChange={(e) => setdescrizione(e.target.value)} maxLength="200" name="descrizione" value={descrizione} required={true} />
					</Grid>
					<Grid item  xs={12} md={4}>
						<NomiCalendariSelect onChange={setnomecalendario} selected={nomecalendario} label={"Calendari"} />
					</Grid>
					<Grid item  xs={6} md={4}>
					<SelectText 
							label={"Visibile agli utenti"}
							
							lista={listaVisibileUtenti}
							value={visibile}
							onChange={(e) => setvisibile(e.target.value)}/>
					</Grid>
					<Grid item xs={6} md={4}>
					<SelectText
							label={"Tipo di visualizzazione prodotti"}
							
							lista={listaTipoVisualizzazione}
							value={visualizzazione}
							onChange={(e) => setvisualizzazione(e.target.value)}/>
					</Grid>
					<Grid item xs={6} md={4}>
					<InputText 
					 label={"Ordine"}
					 onChange={(event) => {setordine(event.target.value)}}
					 placeholder={"Ordine"}
					 type={"number"} value={ordine} />
					 </Grid>
					 <Grid item xs={12}>
						<Paper elevation={5} sx={{padding:2, margin:1}}>
							<Typography variant="h6">Immagine</Typography>
					{foto&&<img src={foto} />}
						<br />
					<TextField variant="standard" fullWidth label="link foto" onChange={(e) => setfoto(e.target.value)} value={foto}/>
						<br />
						<TextField variant="standard" type="file" name="fotoTemp" label="Oppure Seleziona immagine"  onChange={handleInputChange} />
							</Paper>
							</Grid>
					
					<Grid item xs={12}>
						<Button onClick={salva}>
							<SaveIcon />{messaggio}
						</Button>
					<Button onClick={()=> setvisibile(false)}>Close</Button>
					</Grid>
					</Grid>
					</Box>
				</Modal>
			
			</Box>
	);
}