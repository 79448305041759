import React, { useState } from "react";
import { useSelector } from "react-redux";
import InputText from "../../util/UI/InputText";
import { aggiornaCorsoCateg } from "../../DAO/corsiDAO";

export default function ConfigIngrediente({categ, prodottopadre}){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
    
    const [max, setmax] = useState(categ&&categ.max?categ.max:1)
    const [compresiinprezzo, setcompresiinprezzo] = useState(categ&&categ.compresiinprezzo?categ.compresiinprezzo:0)

    function setquanticompresiinprezzo(numcompresi){
        async function aggiornacateg(){
            const nuovacateg={categoria: categ.categoria, compresiinprezzo: numcompresi, }
            await aggiornaCorsoCateg(emailloggato, palestra.palestra.id, prodottopadre.id, JSON.stringify(nuovacateg) );
        }
        aggiornacateg();
    }

    

    function setvaloremassimo(massimo){
        async function aggiornacateg(){
            const nuovacateg={categoria: categ.categoria, max: massimo}
            console.log(nuovacateg)
            await aggiornaCorsoCateg(emailloggato, palestra.palestra.id, prodottopadre.id, JSON.stringify(nuovacateg)  );
        }
        aggiornacateg();
    }

    return(
        <div className="row">
            <InputText label={"Max ingredienti per la categoria "+categ.categoria} width={"50%"} type={"number"} value={max} onChange={(e) => {setmax(e.target.value); setvaloremassimo(e.target.value)}} />
            &nbsp;&nbsp;
            <InputText label="Num prodotti compresi nel prezzo base" type="number" width={"50%"} name="compresiinprezzo" value={compresiinprezzo} onChange={(e) => {setcompresiinprezzo(e.target.value); setquanticompresiinprezzo(e.target.value)}} />
        </div>
    )
}