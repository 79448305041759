import React, { useEffect, useState } from "react";
import '../util/config'
import { useSelector } from "react-redux";
import { getMessaggi, inviaMessaggio, inviaMessaggioWA, inviaMessaggioWALibero } from "../DAO/messaggiDAO";
import { Button, Divider, Grid, Paper, TextField, Typography, Box, Avatar } from "@mui/material";
import { Chat, WhatsApp } from "@mui/icons-material";

export default function Messaggi(props) {
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
  const email = useSelector(state => state.gestoreUtente.emailloggato);
  const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata)

  const [messaggi, setmessaggi] = useState([]);
  const [messaggio, setmessaggio] = useState('');
  const [ultimom, setultimom] = useState(25);

  function caricamessaggi() {
    async function carica() {
      const result = await getMessaggi(email, palestra.id, idanagselezionata);
      setmessaggi(result);
      if (result && result.length > 0 && result[0].idwa) {
        const diff = ((new Date()).getTime() - new Date(result[0].data)) / 1000 / (60 * 60);
        setultimom(diff);
      }
    }
    carica();
  }

  useEffect(() => {
    caricamessaggi();
  }, [idanagselezionata]);

  function inviaMsgApp() {
    if (messaggio.trim() === '') {
      alert("Messaggio obbligatorio");
      return;
    }
    async function invia() {
      const result = await inviaMessaggio(email, palestra.id, idanagselezionata, messaggio, 0);
      alert(result);
      caricamessaggi();
      setmessaggio('');
    }
    invia();
  }

  function inviaMsgWA() {
    if (messaggio.trim() === '') {
      alert("Messaggio obbligatorio");
      return;
    }
    async function invia() {
      const result = await inviaMessaggioWA(email, palestra.id, idanagselezionata, messaggio, 0);
      alert(result);
      caricamessaggi();
      setmessaggio('');
    }
    invia();
  }

  function inviaMsgWALibero() {
    if (messaggio.trim() === '') {
      alert("Messaggio obbligatorio");
      return;
    }
    async function invia() {
      const result = await inviaMessaggioWALibero(email, palestra.id, idanagselezionata, messaggio, 0);
      alert(result);
      caricamessaggi();
      setmessaggio('');
    }
    invia();
  }

  return (
    <Grid container spacing={2} padding={3}>
      <Grid item xs={12}>
        {messaggi && Array.isArray(messaggi) && messaggi.length > 0 ? (
          messaggi.map((msg, index) => (
            <Paper key={index} elevation={2} style={{ padding: '16px', marginBottom: '16px' }}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={2}>
                  <Avatar>{msg.messaggio.charAt(0)}</Avatar>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{msg.messaggio}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2" color="textSecondary">{new Date(msg.data).toLocaleString()}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2">{msg.letto ? "Letto" : "Non letto"}</Typography>
                </Grid>
                <Grid item xs={1}>
                  {msg.idwa ? <WhatsApp color="success" /> : <Chat color="primary" />}
                </Grid>
              </Grid>
            </Paper>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">Nessun messaggio ricevuto o inviato</Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Typography variant="h6" gutterBottom>
            Nuovo messaggio
          </Typography>
          <TextField
            fullWidth
            name="messaggio"
            id="Messaggio"
            label="Scrivi un messaggio"
            value={messaggio}
            onChange={(e) => setmessaggio(e.target.value)}
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
          />
          <Box display="flex" justifyContent="space-between" marginTop={2}>
            <Button variant="contained" color="primary" onClick={inviaMsgApp}>
              Invia messaggio APP
            </Button>
            {ultimom && ultimom < 24 ? (
              <Button variant="contained" color="secondary" onClick={inviaMsgWALibero}>
                Invia messaggio WhatsApp Libero
              </Button>
            ) : (
              <Button variant="contained" color="secondary" onClick={inviaMsgWA}>
                Invia messaggio WhatsApp Informativo
              </Button>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}