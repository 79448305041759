import React, { useEffect, useState } from "react";
import CorsoInput from "./CorsoInput";
import NuovoCorsoInput from "./NuovoCorsoInput";
import { useDispatch, useSelector } from "react-redux";
import { getcorsi } from "../../DAO/corsiDAO";
import { getTipi } from "../../DAO/tipiprodottiDAO";
import { settipiprodotto } from "../../STORE/reducers/gestoreTipiProdotto";
import Typography from '@mui/material/Typography';
import { Box, Button, Grid, } from "@mui/material";
import InputText from "../../util/UI/InputText";

export default function ListaCorsi(props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
  	const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
	const tipi = useSelector(state => state.gestoreTipiProdotto.tipi);

	const dispatch=useDispatch();

  	const [tipo, settipo] = useState(null);//route.params.idtipo.id)
  	const [prodotti, setprodotti] = useState([]);

  	const [keytorefresh, setkeytorefresh] = useState(new Date().getTime());

	const [filtro, setfiltro] = useState('')

	  function caricaTipiProdotto() {
		
	
		async function caricaTipi() {
			const tipidb = await getTipi(
			  palestra.palestra.id,
			  palestra.idtipicorso&&palestra.idtipicorso!==''?palestra.idtipicorso:'',
			  '',100
			);
			console.log("caricaTipiProdotto ",tipidb)
			if (tipo){
				caricaProdotti(tipo)
			}
			dispatch(settipiprodotto(tipidb));
			
		}

		if (palestra&&palestra.palestra&&palestra.palestra.id){
		  caricaTipi();
		}
	  }

	function caricaProdotti(tipotemp) {
		setfiltro("")
		console.log('caricaProdotti')
		async function caricaProd() {
			const corsi = await getcorsi(
				emailloggato,
			  palestra.palestra.id,
			  tipotemp.id
			);
			console.log("corsi ",corsi)
			settipo(tipotemp)
			setprodotti(corsi)
			//setkeytorefresh(new Date().getTime())
		}
		caricaProd();
	  }
	
	  useEffect(() => {
		console.log('useEffect ListaCorsi')
		caricaTipiProdotto();
	  }, [palestra, keytorefresh]);


	  return (
			  <Grid item container xs={12} key={keytorefresh}>
				<Grid item container xs={12}>
					{
					tipi.map(tipotemp =>(
						<Grid item container xs={3} md={2}>
						<Button key={tipotemp.nome} onClick={() => caricaProdotti(tipotemp)} style={{fontWeight:'bold', color:tipotemp.visibile?"green":"orange",display:'flex', flexDirection:'column' }}>
							<Box component="img"  src={tipotemp.foto} sx={{height:30}} />
							<Typography variant="subtitle2" style={{fontWeight:"bold"}}>
								{tipotemp.nome}
							</Typography>
						</Button>
						</Grid>
					))
					}
				</Grid>
				  <NuovoCorsoInput setkeytorefresh={setkeytorefresh} tipiCorso={tipi} tipo={tipo} />
			  
					  {tipo && <h3 className="text-center">{tipo.nome}</h3>}
					  <InputText label="cerca prodotti" value={filtro} placeholder="filtra prodotti" onChange={(e) => setfiltro(e.target.value)} />
						  {
						  prodotti&&prodotti.map(prodottotemp =>(
							prodottotemp.corsi&&prodottotemp.corsi.map(corso =>(
								corso.nome.toLowerCase().indexOf(filtro.toLowerCase())>=0 &&
								
										<CorsoInput corso={corso} 
										setkeytorefresh={setkeytorefresh} 
										tipiCorso={tipi} 
										noncino={props.noncino}
										email={emailloggato}
										/>

						  )
							
						  )
						  ))
					  }
			  </Grid>
	  );
  }