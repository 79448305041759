import React from "react";
import axios from 'axios';

import BancheSelect from "../banche/BancheSelect";
import MovimentiSelect from "./MocimentiSelect";
import { connect } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, MenuItem, Select, InputLabel, Snackbar, SnackbarContent, Alert } from "@mui/material";
import Euro from '@mui/icons-material/Euro';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InputText from "../util/UI/InputText";

class NuovoPagamento extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dataPagamento: ' ',
			importo: this.props.importo,
			tipo: '',
			idabbonamento: this.props.idabbonamento,
			idprenotazione: this.props.idprenotazione,
			ricevuta: this.props.ricevuta,
			fattura: this.props.fattura,
			visible: false,
			totRicevute: 0,
			messaggio: null
		}

		this.update = this.update.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.associaMovimento = this.associaMovimento.bind(this);
	}

	update(e) {
		this.setState({ [e.target.name]: e.target.value, });
	}

	associaMovimento(event) {
		event.preventDefault();
		const { idmovimento, idabbonamento, tipo, fattura, ricevuta } = this.state;
		const formData = new FormData();
		formData.append('idmovimento', idmovimento);
		formData.append('idabbonamento', idabbonamento);
		formData.append('tipo', tipo);
		formData.append('idfattura', fattura ? fattura.id : '');
		formData.append('idricevuta', ricevuta ? ricevuta.id : '');
		formData.append('website', global.config.domain);
		formData.append('email', this.props.email);
		formData.append('lic', '000');
		formData.append('palestra', this.props.palestra.id);
		axios.post(global.config.server + '/insPagamentoDaMovimento', formData)
			.then((result) => {
				this.setState({ messaggio: result.data });
				alert(result.data)
			}).catch(err => {
				if (err.response) {
					alert(err.response.data.message);
				}
				else {
					alert(err)
				}
			});
	}

	handleSubmit(event) {
		event.preventDefault();
		if (this.state.dataPagamento.trim() === '') {
			this.setState({ message: "Data pagamento obbligatoria", visible: true })
			return;
		}
		if (this.state.importo.trim() === '') {
			this.setState({ message: "Importo pagamento obbligatoria", visible: true })
			return;
		}
		if (this.state.tipo.trim() === '') {
			this.setState({ message: "Tipo pagamento obbligatoria", visible: true })
			return;
		}
		const { idabbonamento, dataPagamento, importo, tipo, fattura, ricevuta, banca } = this.state;

		const formData = new FormData();
		formData.append('idabbonamento', idabbonamento);
		formData.append('datapagamento', dataPagamento.trim() ? dataPagamento.trim() : "");
		formData.append('importo', importo);
		formData.append('tipo', tipo);
		formData.append('idfattura', fattura ? fattura.id : '');
		formData.append('idricevuta', ricevuta ? ricevuta.id : '');
		formData.append('banca', banca ? banca : '');
		formData.append('website', global.config.domain);
		formData.append('email', this.props.email);
		formData.append('lic', '000');
		formData.append('palestra', this.props.palestra.id);
		axios.post(global.config.server + '/insPagamento', formData)
			.then((result) => {
				this.setState({ messaggio: result.data });
				alert(result.data)
				this.props.refresh();
			}).catch(err => {
				if (err.response) {
					alert(err.response.data.message);
				}
				else {
					alert(err)
				}
			});
	}

	componentDidMount() {
		this.state = {
			dataPagamento: '',
			importo: this.props.importo,
			tipo: '',
			idabbonamento: this.props.idabbonamento,
			idprenotazione: this.props.idprenotazione,
			ricevuta: this.props.ricevuta,
			fattura: this.props.fattura,
			visible: false,
			totRicevute: 0,
			messaggio: null
		}
	}

	render() {
		return (
			<Grid item container xs={12}>
				<Snackbar
				anchorOrigin={{ vertical: 'top',
				horizontal: 'center',}}
					color="error"
					open={this.state.visible}
					autoHideDuration={6000}
					onClose={() => this.setState({ visible: false, message: "" })}
					>
					<Alert
						onClose={() => this.setState({ visible: false, message: "" })}
						severity="error"
						variant="filled"
						sx={{ width: '100%' }}
					>
						{this.state.message}
					</Alert>
				</Snackbar>
					
				<Button variant="contained" onClick={() => this.setState({ show: true })}>
					<Euro />
					<AddCircleIcon />
				</Button>

				<Dialog open={this.state.show} onClose={() => this.setState({ show: false })}>
					<DialogTitle>
						Nuovo Pagamento
					</DialogTitle>
					<DialogContent>

						<MovimentiSelect email={this.props.email} update={this.update} associaMovimento={this.associaMovimento} />

						<Divider />
						Oppure

						<Grid item container xs={12}>


							<InputText label="Data" type="date" placeholder="Enter email"
								name="dataPagamento" value={this.state.dataPagamento}
								required={true} onChange={this.update} />


							<InputText label="Importo" type="number" placeholder="Importo"
								name="importo" value={this.state.importo} required={true} onChange={this.update} />

							<FormControl required variant="standard" fullWidth>
								<InputLabel>Tipo</InputLabel>
								<Select
									placeholder="Tipologia pagamento"
									value={this.state.tipo}
									name="tipo"
									
									onChange={this.update}
								>
									<MenuItem value="contanti">Contanti</MenuItem>
									<MenuItem value="carta">Bancomat/Carta</MenuItem>
									<MenuItem value="bonifico">Bonifico</MenuItem>
									<MenuItem value="assegno">Assegno</MenuItem>
									<MenuItem value="paypal">Paypal</MenuItem>
									<MenuItem value="alma">Alma</MenuItem>
								</Select>
							</FormControl>
							<BancheSelect update={this.update} email={this.props.email} />
						</Grid>

					</DialogContent>
					<DialogActions>
						<Button variant="primary" onClick={this.handleSubmit}>
							Salva
						</Button>
						<Button variant="secondary" onClick={() => this.setState({ show: false })}>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		);
	}
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
	}
}

export default connect(mapStateToProps, null)(NuovoPagamento)