import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MenuItem from './MenuItem';
import { useSelector } from "react-redux";
import { getMenuByPalestra } from '../DAO/menuDAO';
import { Container, TextField, Button, Select, MenuItem as MuiMenuItem, FormControl, InputLabel, Typography, Grid, Box } from '@mui/material';
import serverInfo from '../util/globals';

export default function MenuList() {
    const token = useSelector(state => state.gestoreUtente.token);
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime());

    const [filteredItems, setFilteredItems] = useState([]);
    const [filterCode, setFilterCode] = useState('');
    const [showAddNewItem, setShowAddNewItem] = useState(false);
    const [menuItems, setMenuItems] = useState([]);

    const [newItem, setNewItem] = useState({
      idpalestra:palestra.idpalestra,
      code: '',
      faicon: '',
      nome: '',
      ordine: 0,
      profili: '',
      viewname: '',
      fontcolor: '',
      iconcolor: '',
      link: '',
      faiconclass: '',
      enabled: 0,
    });
  
    const faiconOptions = [
      'fa-home', 'calendar-alt', 'fa-map-marker', 'list', 'dumbbell', 'envelope',
      'utensils', 'clipboard-list', 'search', 'weight', 'cogs', 'file', 
      'file-invoice', 'tachometer-alt', 'euro-sign', 'dungeon', 'people-carry',
      'id-card-alt', 'ticket-alt', 'plus-square', 'store'
    ];
  
    /** questi devono essere gli stessi del file routes */
    const linkOptions = [
      '/', '/annotatioc-prods/', '/annotatioc-anag/', '/annotatioc-preno/', '/annotatioc-schede/', 
      '/annotatioc-msg/', '/annotatioc-ordini/', '/annotatio-prods/', '/annotatio-anag/', 
      '/anntt-adm-msg/', '/annotatio-cc/', '/annotatio-sa/', '/annotatio-arch/', '/annotatio-ordini/',
      '/annotatioc-docs/', '/annotatioc-fatture/', '/annotatio-fatt/', '/annotatio-dashboard/', 
      '/annotatio-cassa/', '/annotatio-accessi/', '/annotatio-collab/', '/anntt-adm-tess/', 
      '/annotatioc-abbs/', '/annotatioc-newabb/', '/annotatio-dett-pal/'
    ];
  
    const faiconclassOptions = [
      'fa fa-calendar-alt', 'fa fa-dumbbell', 'fas fa-calendar-alt', 'fas fa-clipboard-list', 
      'fas fa-cogs', 'fas fa-dumbbell', 'fas fa-dungeon', 'fas fa-envelope', 'fas fa-euro-sign', 
      'fas fa-file', 'fas fa-file-invoice', 'fas fa-home', 'fas fa-id-card-alt', 'fas fa-list', 
      'fas fa-map-marker', 'fas fa-people-carry', 'fas fa-plus-square', 'fas fa-shopping-cart', 
      'fas fa-store', 'fas fa-tachometer-alt', 'fas fa-ticket-alt', 'fas fa-user-cog', 
      'fas fa-utensils', 'fas fa-weight'
    ];

    function caricaMenu(){
        async function carica() {
            const result = await getMenuByPalestra(palestra.palestra.id, token);
            setMenuItems(result);
            setFilteredItems(result); // Inizialmente mostra tutti gli items
            setkeytorefresh(new Date().getTime())
          }
          if (palestra.palestra)
          carica()
    }

  useEffect(() => {
    caricaMenu()
  }, [palestra]);

  const handleCodeChange = (id, newCode) => {
    const updatedItems = menuItems.map(item =>
      item.id === id ? { ...item, code: newCode } : item
    );
    setMenuItems(updatedItems);
    applyFilter(filterCode, updatedItems); // Riapplica il filtro sugli items aggiornati
  };

  const handleEnabledChange = (id, newEnabled) => {
    const updatedItems = menuItems.map(item =>
      item.id === id ? { ...item, enabled: newEnabled } : item
    );
    setMenuItems(updatedItems);
    applyFilter(filterCode, updatedItems); // Riapplica il filtro sugli items aggiornati
  };

  const handleSave = (id) => {
    const itemToUpdate = menuItems.find(item => item.id === id);
    axios.post(serverInfo.namesec+`/menu/updatemenuappannotatio/${id}`, itemToUpdate,{
    headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => console.log("Item updated successfully!", response))
      .catch(error => console.error("There was an error updating the item!", error));
  };

  const handleFilterChange = (event) => {
    const selectedCode = event.target.value;
    setFilterCode(selectedCode);
    applyFilter(selectedCode, menuItems);
  };

  const applyFilter = (code, items) => {
    if (code === '') {
      setFilteredItems(items);
    } else {
      const filtered = items.filter(item => item.code === code);
      setFilteredItems(filtered);
    }
  };

  const handleDelete = (id) => {
    if(window.confirm('Sei sicuro di voler cancellare questo menu?')){
      axios.post(serverInfo.namesec+`/menu/delmenuappannotatio/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        const updatedItems = menuItems.filter(item => item.id !== id);
        setMenuItems(updatedItems);
        applyFilter(filterCode, updatedItems); // Riapplica il filtro sugli items aggiornati
      })
      .catch(error => console.error("There was an error deleting the item!", error));
  }
  };

  const handleFieldChange = (id, fieldName, value) => {
    const updatedItems = menuItems.map(item =>
      item.id === id ? { ...item, [fieldName]: value } : item
    );
    setMenuItems(updatedItems);
    applyFilter(filterCode, updatedItems); // Riapplica il filtro sugli items aggiornati
  };

  const handleNewItemChange = (event) => {
    const { name, value } = event.target;
    setNewItem({ ...newItem, [name]: value });
  };


  const handleAddNewItem = () => {
    const newItemToAdd = { ...newItem, palestra };
    axios.post(serverInfo.namesec+'/menu/newmenuappannotatio', newItemToAdd, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      const updatedItems = [...menuItems, response.data];
      setMenuItems(updatedItems);
      applyFilter(filterCode, updatedItems); // Riapplica il filtro sugli items aggiornati
      setNewItem({
        code: '',
        faicon: '',
        nome: '',
        ordine: 0,
        profili: '',
        viewname: '',
        fontcolor: '',
        iconcolor: '',
        link: '',
        faiconclass: '',
        enabled: 0,
      });
      setShowAddNewItem(false); // Nascondi il modulo dopo l'aggiunta
    })
    .catch(error => console.error("There was an error adding the item!", error));
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Menu Items
      </Typography>

      <Box mb={4}>
        <FormControl fullWidth>
          <InputLabel>Filter by Code</InputLabel>
          <Select value={filterCode} onChange={handleFilterChange}>
            <MuiMenuItem value="">All</MuiMenuItem>
            <MuiMenuItem value="admin">admin</MuiMenuItem>
            <MuiMenuItem value="adminlaterale">adminlaterale</MuiMenuItem>
            <MuiMenuItem value="collaboratore">collaboratore</MuiMenuItem>
            <MuiMenuItem value="main">main</MuiMenuItem>
            <MuiMenuItem value="mainlaterale">mainlaterale</MuiMenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box mb={4}>
        <Button variant="contained" color="primary" onClick={() => setShowAddNewItem(!showAddNewItem)}>
          {showAddNewItem ? 'Cancel' : 'Add New Menu Item'}
        </Button>
        {showAddNewItem && (
          <Box mt={2}>
            <Typography variant="h6" component="h2">
              Add New Menu Item
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Nome"
                  name="nome"
                  value={newItem.nome}
                  onChange={handleNewItemChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Code</InputLabel>
                  <Select
                    name="code"
                    value={newItem.code}
                    onChange={handleNewItemChange}
                  >
                    <MuiMenuItem value="">Select Code</MuiMenuItem>
                    <MuiMenuItem value="admin">admin</MuiMenuItem>
                    <MuiMenuItem value="adminlaterale">adminlaterale</MuiMenuItem>
                    <MuiMenuItem value="collaboratore">collaboratore</MuiMenuItem>
                    <MuiMenuItem value="main">main</MuiMenuItem>
                    <MuiMenuItem value="mainlaterale">mainlaterale</MuiMenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Faicon</InputLabel>
                  <Select
                    native
                    value={newItem.faicon}
                    onChange={handleNewItemChange}
                    inputProps={{
                      name: 'faicon',
                    }}
                  >
                    <option value="">Select Faicon</option>
                    {faiconOptions.map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                    <option value="custom">Custom</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Ordine"
                  type="number"
                  name="ordine"
                  value={newItem.ordine}
                  onChange={handleNewItemChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Viewname"
                  name="viewname"
                  value={newItem.viewname}
                  onChange={handleNewItemChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Font Color"
                  name="fontcolor"
                  value={newItem.fontcolor}
                  onChange={handleNewItemChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Icon Color"
                  name="iconcolor"
                  value={newItem.iconcolor}
                  onChange={handleNewItemChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Link</InputLabel>
                  <Select
                    native
                    value={newItem.link}
                    onChange={handleNewItemChange}
                    inputProps={{
                      name: 'link',
                    }}
                  >
                    <option value="">Select Link</option>
                    {linkOptions.map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                    <option value="custom">Custom</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Faicon Class</InputLabel>
                  <Select
                    native
                    value={newItem.faiconclass}
                    onChange={handleNewItemChange}
                    inputProps={{
                      name: 'faiconclass',
                    }}
                  >
                    <option value="">Select Faicon Class</option>
                    {faiconclassOptions.map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                    <option value="custom">Custom</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Enabled</InputLabel>
                  <Select
                    native
                    name="enabled"
                    value={newItem.enabled}
                    onChange={handleNewItemChange}
                  >
                    <option value={0}>Disabled</option>
                    <option value={1}>Enabled</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleAddNewItem}>
                  Save New Item
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>

      {filteredItems.map(item => (
        <MenuItem
          key={item.id}
          item={item}
          onCodeChange={handleCodeChange}
          onEnabledChange={handleEnabledChange}
          onSave={handleSave}
          onDelete={handleDelete}
          onFieldChange={handleFieldChange}
        />
      ))}
    </Container>
  );
}
