import React from "react";
import axios from 'axios';
import ListinoSelect from "../archivi/listino/ListinoSelect";
import ScontiSelect from "../archivi/sconti/ScontiSelect";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InputText from "../util/UI/InputText";

class NuovoAbbonamento extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sconto: null,
			listino: '',
			buttonVisible: false,
			visible: false,
			validoDa: ''
		}

		this.updateSconto = this.updateSconto.bind(this);
		this.updateListino = this.updateListino.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.update = this.update.bind(this);
		this.showNuovoAbbonamento = this.showNuovoAbbonamento.bind(this);
	}

	update(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	handleSubmit(event) {
		event.preventDefault();
		const { validoDa, listino, sconto } = this.state;

		const formData = new FormData();
		formData.append('validoDa', validoDa);
		formData.append('listino', listino);
		formData.append('sconto', sconto ? sconto : "");
		formData.append('website', global.config.domain);
		formData.append('email', this.props.email);
		formData.append('lic', '000');
		formData.append('palestra', this.props.palestra.id);
		formData.append('idanagrafica', this.props.idanagselezionata);
		axios.post(global.config.server + '/nuovoAbbonamentoRest', formData)
			.then((result) => {
				const Toast = Swal.mixin({
					toast: true,
					position: 'top-end',
					showConfirmButton: false,
					timer: 3000
				});
				Toast.fire({
					type: 'success',
					title: "Nuovo abbonamento creato con successo"
				})
				this.props.refresh(new Date().getTime())
				this.setState({ buttonVisible: false });
			}).catch(err => {
				if (err.response) {
					alert(err.response.data.message);
				}
				else {
					alert(err)
				}
			});
	}

	updateSconto(scontoSelezionato) {
		var sconto = this.state.sconto
		sconto = scontoSelezionato;
		this.setState({ sconto, buttonVisible: true })
	}

	updateListino(e) {
		var listino = this.state.listino
		listino = e;
		this.setState({ listino, buttonVisible: true })
		console.log('listino: ' + listino.id)
	}

	showNuovoAbbonamento() {
		this.setState({ visible: !this.state.visible });
	}


	render() {
		return (
			<Grid item container xs={12}>
				<Button variant="contained" onClick={() => this.setState({ show: true })}>
					<CardMembershipIcon />
					<AddCircleIcon />
				</Button>

				<Dialog open={this.state.show} onClose={this.props.togglePopup}>
					<DialogTitle>
						Nuovo Abbonamento
					</DialogTitle>
					<form onSubmit={this.handleSubmit}>
						<DialogContent>


							<ListinoSelect email={this.props.email}
								idpalestra={this.props.idpalestra}
								selected={this.state.listino ? this.state.listino : ""}
								listini={this.props.listini}
								required={true}
								onChange={this.update} />


							<InputText label="Data inizio abbonamento" type="date" placeholder="Data inizio abbonamento"
								name="validoDa" value={this.state.validoDa} required={true} onChange={this.update} />


							<ScontiSelect email={this.props.email} selected={this.state.sconto ? this.state.sconto : ""} onChange={this.update} />

						</DialogContent>
						<DialogActions>
							<Button variant="contained" type="submit" >Inserisci nuovo abbonamento</Button>
							<Button onClick={() => this.setState({ show: false })}>Close</Button>
						</DialogActions>
					</form>
				</Dialog>
			</Grid>
		);
	}
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
	}
}

export default connect(mapStateToProps, null)(NuovoAbbonamento)