import React, { useState, useEffect } from 'react';
import { dettfatturaricevuta, fatturericevute } from '../../DAO/fattureDAO';
import { useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Modal, Paper, Table, TableContainer, TableHead, TableRow } from '@mui/material';
import { Close } from '@mui/icons-material';

const FattureRicevute = () => {
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const [fatture, setFatture] = useState([]);
    const [selectedFattura, setSelectedFattura] = useState(null);
    const [dettfatturasezionata, setdettfatturasezionata] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [searchIdCodiceCessionario, setSearchIdCodiceCessionario] = useState('');

    useEffect(() => {
        fetchAllFatture();
    }, []);

    const fetchAllFatture = async () => {
        try {
            const response = await fatturericevute(email, palestra.idpalestra)
            console.log(response)
            setFatture(response);
        } catch (error) {
            console.error('Errore nel recupero delle fatture', error);
        }
    };

    const getdettfattura = async (idfattura) => {
        try {
            const response = await dettfatturaricevuta(email, palestra.idpalestra, idfattura)
            setdettfatturasezionata(response);
        } catch (error) {
            console.error('Errore nella ricerca delle fatture', error);
        }
    };

    const openModal = async (fattura) => {
        const dettagliofattura=await getdettfattura(fattura.id)
        //setdettfatturasezionata(dettagliofattura)
        setSelectedFattura(fattura);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <Grid container xs={12}>
            <h1>Elenco Fatture Elettroniche</h1>
            <TableContainer component={Paper}>
            <Table className="dettagli-linee">
            <TableHead>
            <TableRow>
                        <th>ID</th>
                        <th>Codice prestatore</th>
                        <th>Denominazione prestatore</th>
                        <th>Data</th>
                        <th>Numero</th>
                        <th>Importo Totale</th>
                        <th>Azioni</th>
                   </TableRow>
                   </TableHead>
                <tbody>
                    {fatture&&Array.isArray(fatture)&&fatture.map((fattura) => (
                        <tr key={fattura.header.datiTrasmissione.progressivoInvio}>
                            <td>{fattura.header.datiTrasmissione.progressivoInvio}</td>
                            <td>{fattura.header.cedentePrestatore.datiAnagrafici.idFiscaleIVA.idCodice}</td>
                            <td>{fattura.header.cedentePrestatore.datiAnagrafici.anagrafica.denominazione}</td>
                            <td>{fattura.body.datiGenerali.datiGeneraliDocumento.data}</td>
                            <td>{fattura.body.datiGenerali.datiGeneraliDocumento.numero}</td>
                            <td>{fattura.body.datiGenerali.datiGeneraliDocumento.importoTotaleDocumento}</td>
                            <td>
                                <button onClick={() => openModal(fattura)}>Visualizza Dettagli</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            </TableContainer>

                <Dialog
                    open={modalIsOpen}
                    onClose={closeModal}
                    fullScreen    
                >
<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
Dettaglio Fattura
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
            <DialogContent dividers>
            {selectedFattura && selectedFattura.header && (
        <>
            <h2>Dettaglio Fattura</h2>

            {/* Header */}
            <h3>Header</h3>

            <h4>Dati Trasmissione</h4>
            <p><strong>Id Paese Trasmittente:</strong> {selectedFattura.header.datiTrasmissione.idTrasmittente.idPaese}</p>
            <p><strong>Id Codice Trasmittente:</strong> {selectedFattura.header.datiTrasmissione.idTrasmittente.idCodice}</p>
            <p><strong>Progressivo Invio:</strong> {selectedFattura.header.datiTrasmissione.progressivoInvio}</p>
            <p><strong>Formato Trasmissione:</strong> {selectedFattura.header.datiTrasmissione.formatoTrasmissione}</p>
            <p><strong>Codice Destinatario:</strong> {selectedFattura.header.datiTrasmissione.codiceDestinatario}</p>
            <p><strong>PEC Destinatario:</strong> {selectedFattura.header.datiTrasmissione.pecDestinatario}</p>
            <Grid container sx={{padding:2}}>
            <Grid item xs={12} md={6}>
            <h4>Cedente Prestatore</h4>
            <p><strong>Denominazione:</strong> {selectedFattura.header.cedentePrestatore.datiAnagrafici.anagrafica.denominazione}</p>
            <p><strong>Codice Fiscale:</strong> {selectedFattura.header.cedentePrestatore.datiAnagrafici.codiceFiscale}</p>
            <p><strong>Regime Fiscale:</strong> {selectedFattura.header.cedentePrestatore.datiAnagrafici.regimeFiscale}</p>
            <p><strong>Indirizzo:</strong> 
                {`${selectedFattura.header.cedentePrestatore.sede.indirizzo}, ${selectedFattura.header.cedentePrestatore.sede.cap}, ${selectedFattura.header.cedentePrestatore.sede.comune}, ${selectedFattura.header.cedentePrestatore.sede.provincia}, ${selectedFattura.header.cedentePrestatore.sede.nazione}`}
            </p>
            </Grid>
            <Grid item xs={12} md={6}>
            <h4>Cessionario Committente</h4>
            <p><strong>Denominazione:</strong> {selectedFattura.header.cessionarioCommittente.datiAnagrafici.anagrafica.denominazione}</p>
            <p><strong>Codice Fiscale:</strong> {selectedFattura.header.cessionarioCommittente.datiAnagrafici.codiceFiscale}</p>
            <p><strong>Indirizzo:</strong> 
                {`${selectedFattura.header.cessionarioCommittente.sede.indirizzo}, ${selectedFattura.header.cessionarioCommittente.sede.cap}, ${selectedFattura.header.cessionarioCommittente.sede.comune}, ${selectedFattura.header.cessionarioCommittente.sede.provincia}, ${selectedFattura.header.cessionarioCommittente.sede.nazione}`}
            </p>
            </Grid>
            </Grid>
        </>
    )}
                {selectedFattura && selectedFattura.body && (
        <>
            {/* Body */}
            <h3>Body</h3>

            <h4>Dati Generali</h4>
            <p><strong>Tipo Documento:</strong> {selectedFattura.body.datiGenerali.datiGeneraliDocumento.tipoDocumento}</p>
            <p><strong>Divisa:</strong> {selectedFattura.body.datiGenerali.datiGeneraliDocumento.divisa}</p>
            <p><strong>Data:</strong> {selectedFattura.body.datiGenerali.datiGeneraliDocumento.data}</p>
            <p><strong>Numero:</strong> {selectedFattura.body.datiGenerali.datiGeneraliDocumento.numero}</p>
            <p><strong>Importo Totale Documento:</strong> {selectedFattura.body.datiGenerali.datiGeneraliDocumento.importoTotaleDocumento}</p>

            <h4>Dati Beni e Servizi</h4>
            <TableContainer component={Paper}>
            <Table className="dettagli-linee">
                <thead>
                    <tr>
                        <th>Numero Linea</th>
                        <th>Descrizione</th>
                        <th>Quantità</th>
                        <th>Prezzo Unitario</th>
                        <th>Prezzo Totale</th>
                        <th>Aliquota IVA</th>
                    </tr>
                </thead>
                <tbody>
                    {dettfatturasezionata&&Array.isArray(dettfatturasezionata)&&dettfatturasezionata.map((linea, index) => (
                        <tr key={index}>
                            <td align="center">{linea.numeroLinea}</td>
                            <td>{linea.descrizione}</td>
                            <td align="right">{linea.quantita}</td>
                            <td align="right">{linea.prezzoUnitario}</td>
                            <td align="right">{linea.prezzoTotale}</td>
                            <td align="right">{linea.aliquotaIVA}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            </TableContainer>
            <h4>Dati Pagamento</h4>
            {selectedFattura.body.datiPagamento && (
                <>
                    <p><strong>Condizioni Pagamento:</strong> {selectedFattura.body.datiPagamento.condizioniPagamento}</p>
                    <p><strong>Modalità Pagamento:</strong> {selectedFattura.body.datiPagamento.dettaglioPagamento.modalitaPagamento}</p>
                    <p><strong>Data Riferimento Termini Pagamento:</strong> {selectedFattura.body.datiPagamento.dettaglioPagamento.dataRiferimentoTerminiPagamento}</p>
                    <p><strong>Giorni Termini Pagamento:</strong> {selectedFattura.body.datiPagamento.dettaglioPagamento.giorniTerminiPagamento}</p>
                    <p><strong>Importo Pagamento:</strong> {selectedFattura.body.datiPagamento.dettaglioPagamento.importoPagamento}</p>
                    <p><strong>IBAN:</strong> {selectedFattura.body.datiPagamento.dettaglioPagamento.iban}</p>
                </>
            )}
        </>
    )}
                    
                    
                    </DialogContent>
                    <DialogActions><Button onClick={closeModal}>Chiudi</Button></DialogActions>
                </Dialog>
           
        </Grid>
    );
};

export default FattureRicevute;
