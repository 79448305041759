import React from "react";
import '../../util/config'
import axios from 'axios';
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import InputText from "../../util/UI/InputText";
import Save from "@mui/icons-material/Save";
import Delete from "@mui/icons-material/Delete";

export default function ScontoInput(props) {

	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [id, setid] = useState(props.selected.id);
	const [descrizione, setdescrizione] = useState(props.selected.descrizione);
	const [valore, setvalore] = useState(props.selected.valoreSconto);
	const [visible, setvisible] = useState(false);
	const [messaggio, setmessaggio] = useState();
	const [numutenti, setnumutenti] = useState(props.selected.numutenti);
	const [validoda, setvalidoda] = useState(props.selected.validoda);
	const [validoa, setvalidoa] = useState(props.selected.validoa);

	 function handleSubmit(event) {
		event.preventDefault();

	    const formData = new FormData();
		formData.append('id', id);
		formData.append('descrizione', descrizione);
		formData.append('valoreSconto', valore);
		formData.append('numutenti', numutenti);
		formData.append('validoda', validoda);
		formData.append('validoa', validoa);
		formData.append('website', global.config.domain);
        formData.append('email',email);
          formData.append('lic','000');
		  formData.append('palestra',palestra.id);
		  axios.post(global.config.server+'/aggiornaScontoRest/'+id,formData)
	      .then((result) => {
		        setmessaggio(result.data);
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          props.refresh(new Date().getTime());
		          setvisible(false);
	      });
	  }

	function elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare lo sconto selezionato?')){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',email);
          formData.append('lic','000');
		  formData.append('palestra',palestra.id);
		  axios.post(global.config.server+'/eliminaScontoRest/'+id, formData)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})
						props.refresh(new Date().getTime());
			         setvisible(false);
			        });
			}
	  }

	    return (
			<Grid item container xs={12}>
				<Button onClick={() => setvisible(true)}>
					{descrizione}
				</Button>
			<Dialog open={visible} onClose={() => setvisible(false)}>
				<DialogTitle>{descrizione}</DialogTitle>
				<DialogContent>
	    		<Grid item container xs={12}>
						<span>{messaggio}</span>
						<Grid item container xs={12}>
							<InputText label="Descrizione" type="text" onChange={(e)=>setdescrizione(e.target.value)} 
							name="descrizione" value={descrizione} required="required" />
						</Grid>
						<Grid item container xs={6}>
							
							 <InputText label="% sconto" type="number" onChange={(e)=> setvalore(e.target.value) } name="valore" value={valore} required="required" />
						</Grid>
						<Grid item container xs={6}>
							 <InputText label="Num max volte utilizzabile" type="number" onChange={(e)=> setnumutenti(e.target.value) } 
							 name="numutenti" value={numutenti} required="required" />
						</Grid>
						<Grid item container xs={6}>
							 <InputText label="Valido dal" type="date" onChange={(e)=> setvalidoda(e.target.value) } 
							 name="validoda" value={validoda} required="required" />
						</Grid>
						<Grid item container xs={6}>
							 <InputText label="Valido al" type="date" onChange={(e)=> setvalidoa(e.target.value) } 
							 name="validoa" value={validoa} required="required" />
						</Grid>
		  		</Grid>
				  </DialogContent>
				  <DialogActions>
					<Button onClick={handleSubmit}>
						<Save />
					</Button>
					<Button onClick={elimina} color="error">
						<Delete />
					</Button>
					<Button onClick={() => setvisible(false)}>
						Close
					</Button>
				  </DialogActions>
			</Dialog>
			</Grid>
	    );
	}