import React from "react";
import axios from 'axios';
import '../../util/config'
import {  Form} from 'react-bootstrap';
import { connect } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

class ListinoSelect extends React.Component {
	 constructor(props) {
		    super(props);
		    this.state = {
		    		listino:[]
					};
		  }
	 
	 componentDidMount(){

			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
			formData.append('lic','000');
			formData.append('palestra',this.props.idpalestra);
			axios.post(global.config.server+"/listiniRest",formData)
			.then(response=>{
				this.setState({listino: response.data});
				//console.log(response);
			}).catch(err => {
				if (err.response){
				alert("Listini: "+err.response.data.message);
				}
				else{
				alert("Listini: "+err.response);
				}
		});

	  }
	
	render() {
	    return (
			<FormControl fullWidth={true} variant="standard">
        <InputLabel>Listino</InputLabel>
				<Select variant="standard"
				name="listino"
				value={this.props.selected} 
				required={this.props.required}
				onChange={this.props.onChange}>
				{
					this.state.listino&&Array.isArray(this.state.listino)&&this.state.listino.length>0?
					this.state.listino.map(item =>(
					<MenuItem key={item.id}  value={item.id}>{item.tipoAbbonamento.descrizione} {item.periodo.descrizione} - {item.prezzo}</MenuItem>
					)
					)
					:null
				}
				
			</Select>
			</FormControl>

	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato,
			idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	}
	
	export default connect(mapStateToProps, null)(ListinoSelect)