import React from "react";
import axios from 'axios';
import { connect } from "react-redux";
import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import InputText from "../util/UI/InputText";
import SearchIcon from '@mui/icons-material/Search';

class MovimentiSelect extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        movimenti:[]
      };
      this.update = this.update.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

  update(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(){
        const formData = new FormData();
		formData.append('website', global.config.domain);
    formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
        formData.append('descrizioneMovimento',this.state.descrizioneMovimento);
		axios.post(global.config.serversec+'/conto/VisualizzaMovimenti',formData)
      .then(response=>{
        this.setState({movimenti: response.data});
        console.log(response);
      });
    }
  
    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.log(error, info);
    }
  
    render() {
      return (
          <Grid item container xs={12}>
          <InputText label="Cerca movimenti" 
          type="text" 
          name="descrizioneMovimento" 
          value={this.state.descrizioneMovimento} onChange={this.update}
          InputProps={{
            endAdornment: <InputAdornment position="end"><Button onClick={this.handleSubmit}>
            <SearchIcon />
          </Button></InputAdornment>,
                }}
           />
          
          {this.state.movimenti&&this.state.movimenti.length>0&&
          <FormControl variant="standard" fullWidth>
            <InputLabel>Seleziona un movimento</InputLabel>
            <Select name="idmovimento" onChange={this.props.update}>
                {this.state.movimenti.map(movimento =>(
                  <MenuItem key={movimento.id} value={movimento.id} selected={this.props.selezionato===movimento.id}>
                      {movimento.dataOperazione + " " + movimento.nomeBanca+", importo:" +(movimento.entrata>0?movimento.entrata:movimento.uscita)+" "+movimento.descrizione}
                  </MenuItem>
                
                    )
                  
                  )}
              </Select>
              </FormControl>
              
            }
              {this.state.movimenti&&this.state.movimenti.length>0&&<Button onClick={this.props.associaMovimento}>Importa</Button>}
            </Grid>
      );
    }
  }

  const mapStateToProps = state => {
		return {
		  palestra: state.gestoreUtente.palestraassociata.palestra,
		  email: state.gestoreUtente.emailloggato,
		  idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	  }
	  
	  export default connect(mapStateToProps, null)(MovimentiSelect)