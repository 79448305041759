import React, { useEffect, useState } from "react";
import SelectTipologiaCorso from "../tipologiaCorso/SelectTipologiaCorso";
import LuogoSelect from "./LuogoSelect";
import { useSelector } from "react-redux";
import { aggiornaCorso, eliminaCorso } from "../../DAO/corsiDAO";
import Ingredienti from "./Ingredienti";
import Allergeni from "./Allergeni";
import InputText from "../../util/UI/InputText";

import MyInput from "../../util/UI/MyInput";
import CorsiFigli from "./CorsiFigli";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Typography } from "@mui/material";
import Save from "@mui/icons-material/Save";
import Delete from "@mui/icons-material/Delete";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

export default function CorsoInput (props){
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
    
    const [nome, setnome] =useState(props.corso.nome)
    const [descrizione, ] =useState(props.corso.descrizione)
    const [place, setplace] = useState(props.corso.luogo)
    const [idtipo, setidtipo] = useState(props.corso.tipoCorso)
    const [duratagiornaliera, setduratagiornaliera] = useState(false)
    const [durata, setdurata] = useState(props.corso.durata)
    const [utenti, setutenti] = useState(props.corso.utenti)
    const [abb, setabb] = useState(props.corso.abb)
    const [calendariopubblico, setcalendariopubblico] = useState(props.corso.calendariopubblico)
    const [prezzo, setprezzo] = useState(props.corso.prezzo)
    const [foto1, setfoto1] = useState(props.corso.foto1)
	const [file, setfile] = useState(null);
	const [, setkeytorefresh] = useState(new Date().getTime())
	const [allergeni, setallergeni] = useState(props.corso.allergeni)
	const [html, sethtml] = useState(props.corso.descrizionehtml?props.corso.descrizionehtml:props.corso.descrizione?props.corso.descrizione:'')
    const [disponibileda, setdisponibileda] = useState(props.corso.disponibileda)
    const [disponibilea, setdisponibilea] = useState(props.corso.disponibilea)
    const [disponibile, ] = useState(props.corso.disponibilita)
	const [ordine, setordine] = useState(props.corso.ordine)
	const [imgUpload, ] = useState(null);
	const [fenatura, setfenatura] = useState(props.corso.fenatura)
	const [aliquotaiva, setaliquotaiva] = useState(props.corso.aliquotaiva)

	const [messaggio, setmessaggio] = useState('');
	const [visible, setvisible] = useState(false);
	const [tabselected, settabselected] = useState(0)

	function handleInputChange(file){
		setfile({
			base64:file.base64,
			name:file.name,
			type:file.type
		})
	}
	  function salva(){
        async function salvaprod(){
            const result=await aggiornaCorso(props.corso.id,nome, descrizione?descrizione:nome, html?html:'', idtipo.id, utenti, durata, duratagiornaliera,
                place, abb, calendariopubblico, prezzo, disponibileda, disponibilea, disponibile,
                foto1, file, fenatura, aliquotaiva, emailloggato, palestra.palestra.id);
				setmessaggio(result)
            props.setkeytorefresh(new Date().getTime());
			setvisible(true)
			setkeytorefresh(new Date().getTime())
        }
		if (!nome){
			alert('Nome obbligatorio')
			return;
		}
		if (!idtipo){
			alert('Tipologia obbligatoria')
			return;
		}
		if (!place){
			alert('Luogo obbligatorio')
			return;
		}
		setvisible(false)
		setmessaggio("Attenere prego")
        salvaprod();
    }
	  
	function updateTipo(tipoSelezionato) {
		setidtipo(tipoSelezionato)
		setvisible(true)
	}

	function elimina() {
		async function eliminacor() {
            const message = await eliminaCorso(
                emailloggato, palestra.palestra.id,
                props.corso.id
            );
            alert(message);

            props.setkeytorefresh(new Date().getTime())
          }
		  if (window.confirm('Sei sicuro di voler cancellare '+nome+'?')){
			eliminacor()
			}
	  }

	  useEffect(() => {
		console.log("UseEffect CorsoInput "+props.corso.nome)
	  },[setkeytorefresh])

	    return (
			<Grid item container xs={6} md={3} sx={{padding:0, margin:0}}>
				<Grid item container xs={12} >
						<Button onClick={() => setvisible(true)} sx={{display:'flex', flexDirection:'column' }}>
							{props.corso.foto1&&<Box component="img" margin={2} src={props.corso.foto1} sx={{width:100}} />}
							<Typography style={{fontWeight:"bold"}}>
								{props.corso.nome}
							</Typography>
						</Button>
				
				</Grid>
				<Dialog
				open={visible} sx={{width:'100%', backgroundColor:'white',padding:0, margin:0}}
				onClose={() => setvisible(false)}>
					<DialogTitle>{nome}</DialogTitle>
					<DialogContent sx={{padding:0, margin:0}}>
	    	<Grid item container xs={12} sx={{padding:1, margin:0}}>

			<Grid item container xs={7}>
				<InputText label={"Nome"} 
				type="text" name="nome" value={nome} 
				onChange={(e)=>setnome(e.target.value)} 
				required maxLength="150"/>
					</Grid>
				<Grid item container xs={5}>
				<InputText label={"Prezzo €"} 
				type="number" name="prezzo" value={prezzo} 
				onChange={(e)=>setprezzo(e.target.value)} 
				step="0.1" />
				</Grid>

				<Grid item container xs={12}>
					
						<label>Descrizione
					
					
                <MyInput sethtml={sethtml} defaultcontent={html} />
            
					</label>
					
					</Grid>
					<Grid item container xs={12} md={6}>
						
						<LuogoSelect setselected={(value) => {setplace(value)}} selected={place}/>
					</Grid>

					<Grid item container xs={12} md={6}>
								
								<SelectTipologiaCorso updateTipo={updateTipo} 
								id="ctipoc" 
								selected={idtipo} 
								 />
					</Grid>
					<Grid item container xs={12} md={6}>
						<InputText label="Durata" id="cdurata" type="number" onChange={(e) => setdurata(e.target.value)} name="durata" value={durata} required="required" />
						<label>Tutto il giorno <input type={"checkbox"} checked={duratagiornaliera} name={"duratagiornaliera"} onChange={(e) =>{e.target.checked?setduratagiornaliera(true):setduratagiornaliera(false)}}  /></label>
					</Grid>
					<Grid item container xs={12} md={6}>
						
						<InputText label="Max prenotazioni" id="cmaxp" type="number" onChange={(e) => setutenti(e.target.value)} name="utenti" value={utenti} required="required" />
					</Grid>
					<Grid item container xs={12} md={6}>
							<InputText bootstrapsize={"col-md-2"}
							label={"Ordine"}
							onChange={(e) => setordine(e.target.value)}
							placeholder={"Numero d'ordine"}
							type={"number"}
							value={ordine} />
					</Grid>
					<Grid item container xs={6} md={3}>
							<FormControl variant="standard" fullWidth>
  								<InputLabel>Solo abbonati</InputLabel>
								<Select label="Solo abbonati" variant="standard" value={abb&&(abb===true||abb==='true')} onChange={(e)=>{setabb(e.target.value)}}>
									<MenuItem value="false" >Aperto a tutti</MenuItem>
									<MenuItem value="true" >Solo abbonati</MenuItem>
								</Select>
								</FormControl>
							</Grid>
							<Grid item container xs={6} md={3}>
							<FormControl variant="standard" fullWidth>
  								<InputLabel>Mostra nel calendario pubblicamente</InputLabel>
								<Select variant="standard" value={calendariopubblico&&(calendariopubblico===true||calendariopubblico==='true')} onChange={(e)=>{setcalendariopubblico(e.target.value)}}>
									<MenuItem value="false" >Nascondi</MenuItem>
									<MenuItem value="true" >Mostra</MenuItem>
								</Select>
								</FormControl>
							</Grid>

			
			<Grid item container xs={12} sx={{padding:0, margin:0}}>
			<TabContext fullWidth value={tabselected} sx={{padding:0, margin:0}}>
				<TabList variant="scrollable"sx={{padding:0, margin:0}}
  scrollButtons="auto" allowScrollButtonsMobile onChange={(event, newvalue)=> settabselected(newvalue)}>
					<Tab label="Disponibilità" value={0} />
					<Tab label="Allergeni" value={1} />
					<Tab label="Immagine" value={2} />
					<Tab label="Fattura Elettronica" value={3} />
					<Tab label="Ingredienti personalizzati" value={4} />
					<Tab label="Gerarchia" value={5} />
				</TabList>
				<TabPanel value={0} sx={{padding:1, margin:0}}>
				
							<h5>Disponibilità</h5>
							<FormControl>
  								<FormLabel >Disponibile</FormLabel>
								  <RadioGroup
										defaultValue={"disponibile"}
									>
										<FormControlLabel value="disponibile" control={<Radio />} label="Si" />
										<FormControlLabel value="nondisponibie" control={<Radio />} label="No" />
								</RadioGroup>
							</FormControl>
							<Grid item container xs={12}>
							<Grid item container xs={6} md={6}>
								<InputText type="date" label="Disponibile dal" name="disponibileda" value={disponibileda} onChange={(e) => {setdisponibileda(e.target.value); setdisponibilea(e.target.value)}} />
							</Grid>
							<Grid item container xs={6} md={6}>
								<InputText type="date" label="fino a" name="disponibilea" value={disponibilea} onChange={(e) => setdisponibilea(e.target.value)} />
							</Grid>
							</Grid>
				</TabPanel>
				<TabPanel value={1}>
				{
							(palestra.palestra&&palestra.palestra.descrizioneattivita&&(palestra.palestra.descrizioneattivita.toUpperCase().indexOf('RISTO')>=0||palestra.palestra.descrizioneattivita.toUpperCase().indexOf('ALIMENT')>=0))&&
								<>
								<h5>Allergeni</h5>
								<Allergeni allergeni={allergeni?allergeni.split(','):[]} setallergeni={setallergeni} idcorso={props.corso.id} />
								</>
							}
				</TabPanel>
				<TabPanel value={2}>
				<Grid item container xs={12}>
							<Paper elevation={5} sx={{padding:2, margin:0}}>
							<Typography variant="h6">Immagine</Typography>
							<img src={foto1} style={{maxWidth:'100px'}} />
						
							<InputText label="link immagini" type="text" name="foto1" value={foto1}  onChange={(e) => setfoto1(e.target.value)} />
							
							<InputText label="Oppure seleziona una immagine" id="imgUpload" type="file" name="fotoTemp" ref={imgUpload} onChange={handleInputChange} />
							</Paper>
							</Grid>
				</TabPanel>
				<TabPanel value={3}>
				<Grid item container xs={12}>
							<FormControl fullWidth>
  								<InputLabel>Natura Fattura Elettronica</InputLabel>
								<Select fullWidth variant="standard" name="fenatura" value={fenatura} onChange={(e) => setfenatura(e.target.value)} >
									<MenuItem value="">--Natura bene/servizio per Fattura Elettrovica</MenuItem>
									<MenuItem value="N1">N1 - escluse ex art. 15 del DPR 633/72</MenuItem>
									<MenuItem value="N2.1">N2.1 - non soggette ad IVA ai sensi degli artt. da 7 a 7-septies del DPR 633/72</MenuItem>	
									<MenuItem value="N2.2">N2.2 - non soggette - altri casi</MenuItem>
									<MenuItem value="N3.1">N3.1 - non imponibili - esportazioni</MenuItem>
									<MenuItem value="N3.2">N3.2 - non imponibili - cessioni intracomunitarie</MenuItem>
									<MenuItem value="N3.3">N3.3 - non imponibili - cessioni verso San Marino</MenuItem>
									<MenuItem value="N3.4">N3.4 - non imponibili - operazioni assimilate alle cessioni all'esportazione</MenuItem>
									<MenuItem value="N3.5">N3.5 - non imponibili - a seguito di dichiarazioni d'intento</MenuItem>			
									<MenuItem value="N3.6">N3.6 - non imponibili - altre operazioni che non concorrono alla formazione del plafond</MenuItem>
									<MenuItem value="N4">N4 - esenti</MenuItem>
									<MenuItem value="N5">N5 - regime del margine / IVA non esposta in fattura</MenuItem>
									<MenuItem value="N6.1">N6.1 - inversione contabile - cessione di rottami e altri materiali di recupero</MenuItem>
									<MenuItem value="N6.2">N6.2 - inversione contabile - cessione di oro e argento ai sensi della legge 7/2000 nonché di oreficeria usata ad OPO</MenuItem>
									<MenuItem value="N6.3">N6.3 - inversione contabile - subappalto nel settore edile</MenuItem>
									<MenuItem value="N6.4">N6.4 - inversione contabile - cessione di fabbricati</MenuItem>
									<MenuItem value="N6.5">N6.5 - inversione contabile - cessione di telefoni cellulari</MenuItem>
									<MenuItem value="N6.6">N6.6 - inversione contabile - cessione di prodotti elettronici</MenuItem>
									<MenuItem value="N6.7">N6.7 - inversione contabile - prestazioni comparto edile e settori connessi</MenuItem>
									<MenuItem value="N6.8">N6.8 - inversione contabile - operazioni settore energetico</MenuItem>
									<MenuItem value="N6.9">N6.9 - inversione contabile - altri casi</MenuItem>
									<MenuItem value="N7">N7 - IVA assolta in altro stato UE (prestazione di servizi di telecomunicazioni, tele-radiodiffusione ed elettronici ex art. 7-octies, comma 1 lett. a, b, art. 74-sexies DPR 633/72)</MenuItem>
								</Select>
								</FormControl>
							</Grid>
							<Grid item container xs={12}>
									<InputText fullWidth label="Aliquota iva per fattura" type="number" step=".01" name="aliquotaiva" value={aliquotaiva} onChange={(e) => setaliquotaiva(e.target.value)} />
								
							</Grid>
				
				</TabPanel>
				<TabPanel value={4}>
				<Ingredienti prodottopadre={props.corso} />
				</TabPanel>
				<TabPanel value={5}>
				<CorsiFigli corsopadre={props.corso} />
				</TabPanel>
				</TabContext>
				</Grid>
				
				</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={salva}>
					{messaggio}<Save />
					</Button>
					<Button color="error" onClick={elimina}>
						<Delete />
					</Button>
					<Button onClick={() => setvisible(false)}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
			</Grid>
	    );
	}